import React, { useEffect, useState } from "react";
import { Row, Col, Badge, Form, Button, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate, getStatusBadgeColor } from "../../components/payments/PaymentUtils";


const TransactionEdit = ({ transaction, handleTransactionChange }) => {

    const { t } = useTranslation();
    // const [transaction, setTransaction] = useState({});
    useEffect(() => {
        // Fetch event stats
        // setTransaction(trans.transaction?trans.transaction:trans);
    }, [transaction]);

    return (
        <Form>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.type')}</Form.Label>
                        <Form.Select
                            value={transaction.type || ''}
                            onChange={(e) => handleTransactionChange('type', e.target.value)}
                        >
                            <option value="paygold">Paygold</option>
                            <option value="bizum">Bizum</option>
                            <option value="transfer">Transfer</option>
                            <option value="paypal">PayPal</option>
                            <option value="cash">Cash</option>
                            <option value="other">Other</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.reason')}</Form.Label>
                        <Form.Select
                            value={transaction.reason || ''}
                            onChange={(e) => handleTransactionChange('reason', e.target.value)}
                        >
                            <option value="showcase">Showcase</option>
                            <option value="entrada">Entrada</option>
                            <option value="invitado">Invitado</option>
                            <option value="otro">Otro</option>
                            <option value="showcase-socio">Showcase Socio</option>
                            <option value="entrada-junior">Entrada Junior</option>
                            <option value="entrada-puerta">Entrada Puerta</option>
                            <option value="showcase-junior">Showcase Junior</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.description')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={transaction.description || ''}
                            onChange={(e) => handleTransactionChange('description', e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.issued_at')}</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            defaultValue={transaction.issued_at ?
                                new Date(transaction.issued_at).toLocaleString('sv-SE', { hour12: false }).replace(' ', 'T').replace(/\//g, '-').replace(',', '') : ''}
                            onChange={(e) => {
                                const localDate = new Date(e.target.value);
                                console.debug('localDate:', localDate);
                                console.debug("issuedOrig:", transaction.issued_at);
                                console.debug("New issued_at:", localDate.toISOString());
                                handleTransactionChange( "issued_at",localDate.toISOString() );
                            }}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.status')}</Form.Label>
                        <Form.Select
                            value={transaction.transaction_status || ''}
                            onChange={(e) => handleTransactionChange('transaction_status', e.target.value)}
                        >
                            <option value="PENDING">Pending</option>
                            <option value="COMPLETED">Completed</option>
                            <option value="FAILED">Failed</option>
                            <option value="CANCELLED">Cancelled</option>
                            <option value="RETURN">Return</option>
                            <option value="BIZUM">Bizum</option>
                            <option value="TRANSFER">Transfer</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.contact')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={transaction.contact || ''}
                            onChange={(e) => handleTransactionChange('contact', e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.amount')}</Form.Label>
                        <Form.Control
                            type="number"
                            defaultValue={transaction.amount}
                            onChange={(e) => handleTransactionChange("amount", parseFloat(e.target.value) )}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.fee')}</Form.Label>
                        <Form.Control
                            type="number" step={0.1}
                            defaultValue={transaction.fee}
                            onChange={(e) => handleTransactionChange("fee", parseFloat(e.target.value) )}
                        />
                    </Form.Group>

                </Col>
            </Row >
            {/* Technical Parameters Section */}
            < h5 className="mt-4" > {t('transactions.technical_params')}</h5 >
            <Row>
                <Col md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.merchant_params_req')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={
                                typeof transaction.merchant_params_req === 'object'
                                    ? JSON.stringify(transaction.merchant_params_req, null, 2)
                                    : transaction.merchant_params_req || ''
                            }
                            onChange={(e) => {
                                try {
                                    // Try to parse as JSON if possible
                                    const value = e.target.value.trim() ? JSON.parse(e.target.value) : '';
                                    handleTransactionChange('merchant_params_req', value);
                                } catch (err) {
                                    // If not valid JSON, store as string
                                    handleTransactionChange('merchant_params_req', e.target.value);
                                }
                            }}
                        />
                        <Form.Text className="text-muted">
                            {t('transactions.json_format_hint')}
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('transactions.merchant_params_res')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={
                                typeof transaction.merchant_params_res === 'object'
                                    ? JSON.stringify(transaction.merchant_params_res, null, 2)
                                    : transaction.merchant_params_res || ''
                            }
                            onChange={(e) => {
                                try {
                                    // Try to parse as JSON if possible
                                    const value = e.target.value.trim() ? JSON.parse(e.target.value) : '';
                                    handleTransactionChange('merchant_params_res', value);
                                } catch (err) {
                                    // If not valid JSON, store as string
                                    handleTransactionChange('merchant_params_res', e.target.value);
                                }
                            }}
                        />
                        <Form.Text className="text-muted">
                            {t('transactions.json_format_hint')}
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
        </Form >
    );
};

export default TransactionEdit;