// src/store/userSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,  // default state, can be updated when the user logs in
  authenticated:false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    setAuthenticated: (state,action)=>{
      state.authenticated=action.payload;
    },
    isAuthenticated: (state)=>{
      return state.authenticated;
    }
  },
});

export const { setUser, clearUser,setAuthenticated,isAuthenticated } = userSlice.actions;
export default userSlice.reducer;
