import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../utilities/axios_instance";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from "recharts";
import { Container, Spinner, Alert } from "react-bootstrap";

const EventStats = () => {
  const { t } = useTranslation();
  const [eventStats, setEventStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const apiRatios = process.env.REACT_APP_RATIOS_API || "http://localhost:8000/ratios/";

  useEffect(() => {
    const fetchEventStats = async () => {
      try {
        const response = await axiosInstance.get(apiRatios + "/event_stats");
        setEventStats(response.data);
      } catch (err) {
        setError(t('stats.error'));
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEventStats();
  }, [apiRatios, t]);

  return (
    <Container className="mt-5">
      <h2>{t('stats.title')}</h2>

      {loading && <Spinner animation="border" />}
      {error && <Alert variant="danger">{error}</Alert>}

      {!loading && !error && eventStats && (
        <div className="row">
          <div className="col-md-6 mb-4">
            <h4>{t('stats.avgAttendees.title')}</h4>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={eventStats.avgAttendees}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total_with_guests" fill="#007bff" name={t('stats.avgAttendees.totalWithGuests')} />
                <Bar dataKey="total_attendees" fill="#28a745" name={t('stats.avgAttendees.confirmedAttendees')} />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="col-md-6 mb-4">
            <h4>{t('stats.newMembers.monthlyTitle')}</h4>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={eventStats.newMembersPerMonth}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="new_members" stroke="#dc3545" name={t('stats.newMembers.count')} />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="col-md-6 mb-4">
            <h4>{t('stats.newMembers.quarterTitle')}</h4>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={eventStats.newMembersPerQuarter}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="quadrimester" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="new_members" stroke="#ff9800" name={t('stats.newMembers.count')} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </Container>
  );
};

export default EventStats;