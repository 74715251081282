import React, { useState, useEffect } from 'react';
import { Table, Alert, Card, Row, Col, Badge, Form, Button, InputGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faExclamationTriangle, faSearch, faSortUp, faSortDown, faSort, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { handleAxiosError } from '../../utilities/error_handler';
import PaymentDetailsModal from '../../components/payments/PaymentsDetailsModal';

const PaymentIssuesReport = () => {
  const { eventId: urlEventId } = useParams(); 
  const [paymentIssues, setPaymentIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [summary, setSummary] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventId, setEventId] = useState('');
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  // Add state for identity issues
  const [identityIssues, setIdentityIssues] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'event_payment_id', direction: 'asc' });
  
  // Add state for the modal
  const [showModal, setShowModal] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  
  const { t } = useTranslation();
  const paymentsIssuesAPI = process.env.REACT_APP_PAYMENTS_ISSUES_API;
  const eventsAPI = process.env.REACT_APP_EVENTS_API;

  // Fetch events for the dropdown
  useEffect(() => {
    fetchEvents();
  }, [eventsAPI]);

  const fetchEvents = async () => {
    try {
      setLoading(true);
        const response = await axiosInstance.get(`${eventsAPI}/events?ordered=true`);
        setEvents(response.data.events);
        setLoading(false);
    } catch (err) {
        handleAxiosError(err, setError, 'events', 'load');
    }
    finally {
      setLoading(false);
    }
  };

  // Fetch payment issues
  const fetchPaymentIssues = async (selectedEventId = '') => {
    setLoading(true);
    try {
      const url = selectedEventId 
        ? `${paymentsIssuesAPI}/event-payment-issues/${selectedEventId}`
        : `${paymentsIssuesAPI}/event-payment-issues`;
      
      const response = await axiosInstance.get(url);
      setPaymentIssues(response.data.payment_issues || []);
      setFilteredIssues(response.data.payment_issues || []);
      setIdentityIssues(response.data.identity_issues || []);
      setSummary(response.data.summary || {});
      setError(null);
    } catch (err) {
      handleAxiosError(err, setError, 'payment issues', 'retrieve');
      setPaymentIssues([]);
      setFilteredIssues([]);
      setIdentityIssues([]);
      setSummary({});
    } finally {
      setLoading(false);
    }
  };

  // Add function to handle opening the modal
  const handleOpenModal = (paymentId, eventId) => {
    setSelectedPaymentId(paymentId);
    setSelectedEventId(eventId);
    setShowModal(true);
  };

  useEffect(() => {
    if (urlEventId) {
      setEventId(urlEventId);
      fetchPaymentIssues(urlEventId);
    } else {
      fetchPaymentIssues();
    }
  }, [paymentsIssuesAPI, urlEventId]);

  // Handle event selection - modified to automatically fetch issues
  const handleEventChange = (e) => {
    const selectedEventId = e.target.value;
    setEventId(selectedEventId);
    fetchPaymentIssues(selectedEventId);
  };

  // Get the selected event name for the title
  const getSelectedEventName = () => {
    if (!eventId) return null;
    const selectedEvent = events.find(event => event.id.toString() === eventId.toString());
    return selectedEvent ? selectedEvent.name : null;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchPaymentIssues(eventId);
  };

  // Handle search
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    
    if (term.trim() === '') {
      setFilteredIssues(paymentIssues);
    } else {
      const filtered = paymentIssues.filter(issue => 
        issue.event_name?.toLowerCase().includes(term) ||
        issue.attendee_name?.toLowerCase().includes(term) ||
        issue.event_payment_id?.toString().includes(term)
      );
      setFilteredIssues(filtered);
    }
  };

  // Handle sorting
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Apply sorting
  useEffect(() => {
    let sortableIssues = [...filteredIssues];
    if (sortConfig.key) {
      sortableIssues.sort((a, b) => {
        // Handle numeric values
        if (typeof a[sortConfig.key] === 'number' || 
            !isNaN(parseFloat(a[sortConfig.key]))) {
          const aValue = parseFloat(a[sortConfig.key]) || 0;
          const bValue = parseFloat(b[sortConfig.key]) || 0;
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }
        
        // Handle string values
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    setFilteredIssues(sortableIssues);
  }, [sortConfig, paymentIssues]);

  // Get sort icon for column
  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <FontAwesomeIcon icon={faSort} className="ms-1 text-muted" />;
    }
    return sortConfig.direction === 'asc' 
      ? <FontAwesomeIcon icon={faSortUp} className="ms-1" />
      : <FontAwesomeIcon icon={faSortDown} className="ms-1" />;
  };

  return (
    <div className="payment-issues-report">
      <h1>
        {eventId && getSelectedEventName() 
          ? `${t('payment_issues.title')} - ${getSelectedEventName()}`
          : t('payment_issues.title')}
           <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning me-2" />
      </h1>
      
      <Card className="mb-4">
        <Card.Body>
          <Form>
            <Row className="align-items-end mt-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t('payment_issues.filter_by_event')}</Form.Label>
                  <Form.Select 
                    value={eventId} 
                    onChange={handleEventChange}
                    disabled={loading}
                  >
                    <option value="">{t('payment_issues.all_events')}</option>
                    {events?.map(evt => (
                      <option key={evt.id} value={evt.id}>
                        {evt.name} - {new Date(evt.date).toLocaleDateString()}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Button 
                  variant="outline-secondary" 
                  onClick={() => {
                    setEventId('');
                    fetchPaymentIssues();
                  }}
                  disabled={loading}
                >
                  {t('reset')}
                </Button>
                {loading && (
                  <span className="ms-3">
                    <FontAwesomeIcon icon={faSync} spin className="me-2" />
                    {t('loading')}
                  </span>
                )}
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {/* Display error message */}
      {error && <Alert variant="danger" dismissible={true}>{error}</Alert>}

      {/* Display summary with updated information */}
      {!loading && summary && (
        <Card className="mb-4">
          <Card.Body>
            <Row className='mt-4'>
              <Col md={3}>
                <div className="text-center">
                  <h5>{t('payment_issues.total_issues')}</h5>
                  <h2>
                    <Badge bg="danger">{summary.total_issues || 0}</Badge>
                  </h2>
                </div>
              </Col>
              <Col md={3}>
                <div className="text-center">
                  <h5>{t('payment_issues.payment_issues_count')}</h5>
                  <h2>
                    <Badge bg="warning">{summary.payment_issues_count || 0}</Badge>
                  </h2>
                </div>
              </Col>
              <Col md={3}>
                <div className="text-center">
                  <h5>{t('payment_issues.identity_issues_count')}</h5>
                  <h2>
                    <Badge bg="info">{summary.identity_issues_count || 0}</Badge>
                  </h2>
                </div>
              </Col>
              <Col md={3}>
                <div className="text-center">
                  <h5>{t('payment_issues.total_difference')}</h5>
                  <h2>
                    <Badge bg="danger">€{summary.total_difference || '0.00'}</Badge>
                  </h2>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      {/* Search bar */}
      {!loading && paymentIssues.length > 0 && (
        <Card className="mb-2 mt-2">
          <Card.Body  className="mb-2 mt-2">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                placeholder={t('payment_issues.search_placeholder')}
                value={searchTerm}
                onChange={handleSearch}
              />
            </InputGroup>
            <div className="mt-2 text-muted small">
              {t('payment_issues.showing')} {filteredIssues.length} {t('payment_issues.of')} {paymentIssues.length} {t('payment_issues.issues')}
            </div>
          </Card.Body>
        </Card>
      )}

      {/* Display table of payment issues */}
      {!loading && filteredIssues.length === 0 ? (
        <Alert variant="success" dismissible={true}>{t('payment_issues.no_issues_found')}</Alert>
      ) : (
        <Card>
          <Card.Body>
            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
              <Table responsive striped bordered hover>
                <thead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
                  <tr>
                    <th onClick={() => requestSort('event_payment_id')} className="cursor-pointer">
                      {t('payment_issues.id')} {getSortIcon('event_payment_id')}
                    </th>
                    <th onClick={() => requestSort('event_name')} className="cursor-pointer">
                      {t('payment_issues.event')} {getSortIcon('event_name')}
                    </th>
                    <th onClick={() => requestSort('attendee_name')} className="cursor-pointer">
                      {t('payment_issues.attendee')} {getSortIcon('attendee_name')}
                    </th>
                    <th onClick={() => requestSort('total_amount')} className="cursor-pointer">
                      {t('payment_issues.total_amount')} {getSortIcon('total_amount')}
                    </th>
                    <th onClick={() => requestSort('stored_amount_paid')} className="cursor-pointer">
                      {t('payment_issues.stored_paid')} {getSortIcon('stored_amount_paid')}
                    </th>
                    <th onClick={() => requestSort('calculated_amount_paid')} className="cursor-pointer">
                      {t('payment_issues.calculated_paid')} {getSortIcon('calculated_amount_paid')}
                    </th>
                    <th onClick={() => requestSort('difference')} className="cursor-pointer">
                      {t('payment_issues.difference')} {getSortIcon('difference')}
                    </th>
                    <th onClick={() => requestSort('verification_status')} className="cursor-pointer">
                      {t('payment_issues.status')} {getSortIcon('verification_status')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredIssues.map((issue) => (
                    <tr key={issue.event_payment_id}>
                      <td>
                        <span 
                          className="text-primary cursor-pointer"
                          onClick={() => handleOpenModal(issue.event_payment_id, issue.event_id)}
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {issue.event_payment_id}
                        </span>
                      </td>
                      <td>
                        <Link to={`/dashboard/event_payments/${issue.event_id}`} className="text-primary">
                          {issue.event_id}
                        </Link>
                      </td>
                      <td>{issue.attendee_name}</td>
                      <td>€{issue.total_amount}</td>
                      <td>€{issue.stored_amount_paid}</td>
                      <td>€{issue.calculated_amount_paid}</td>
                      <td className="text-danger fw-bold">€{issue.difference}</td>
                      <td>
                        <Badge bg="danger">{t(`payment_issues.${issue.verification_status}`)}</Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      )}

      {/* Identity issues table */}
      {!loading && identityIssues && identityIssues.length > 0 && (
        <>
          <h3 className="mt-4">{t('payment_issues.identity_issues_title')}</h3>
          <Card>
            <Card.Body>
              <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <Table responsive striped bordered hover>
                  <thead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
                    <tr>
                      <th>{t('payment_issues.id')}</th>
                      <th>{t('payment_issues.event')}</th>
                      <th>{t('payment_issues.attendee')}</th>
                      <th>{t('payment_issues.payment_member_id')}</th>
                      <th>{t('payment_issues.payment_meetup_id')}</th>
                      <th>{t('payment_issues.transaction_member_id')}</th>
                      <th>{t('payment_issues.transaction_contact')}</th>
                      <th>{t('payment_issues.member_contact')}</th>
                      <th>{t('payment_issues.status')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {identityIssues.map((issue) => (
                      <tr key={`identity-${issue.event_payment_id}`}>
                        <td>
                          <span 
                            className="text-primary cursor-pointer"
                            onClick={() => handleOpenModal(issue.event_payment_id, issue.event_id)}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {issue.event_payment_id}
                          </span>
                        </td>
                        <td>
                          <Link to={`/dashboard/event_payments/${issue.event_id}`} className="text-primary">
                            {issue.event_id}
                          </Link>
                        </td>
                        <td>{issue.attendee_name}</td>
                        <td>{issue.payment_member_id}</td>
                        <td>{issue.payment_meetup_id}</td>
                        <td>{issue.transaction_member_id}</td>
                        <td>{issue.transaction_contact}</td>
                        <td>
                          {issue.member_phone && (
                            <div><small>📱 {issue.member_phone}</small></div>
                          )}
                          {issue.member_email && (
                            <div><small>✉️ {issue.member_email}</small></div>
                          )}
                        </td>
                        <td>
                          <Badge bg="danger">{t('payment_issues.identity_mismatch')}</Badge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </>
      )}

      {/* Payment Details Modal */}
      <PaymentDetailsModal 
        show={showModal}
        onHide={() => setShowModal(false)}
        paymentId={selectedPaymentId}
        eventId={selectedEventId}
      />
    </div>
  );
};

export default PaymentIssuesReport;
