import React, { useEffect,useState } from "react";
import { Table, Alert, Card, Row, Col, Badge, Form, Button, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PaymentUtils } from "../../components/payments/PaymentUtils";

const TransactionDetail = (trans) => {
  const { t } = useTranslation();
  const {formatDate,getTransactionStatusBadge} = PaymentUtils();
  const [transaction, setTransaction] = useState({});
  
  useEffect(() => {
    // Fetch event stats
    setTransaction(trans.transaction?trans.transaction:trans);
  },[transaction]);

  return (
    <>
        <div>
          <Row className="mb-3">
            <Col md={6}>
              <h5>{t('transactions.basic_info')}</h5>
              <Table bordered>
                <tbody>
                  <tr>
                    <td className="fw-bold">{t('transactions.id')}</td>
                    <td>{transaction.id}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.order_id')}</td>
                    <td>{transaction.order_id || '-'}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.description')}</td>
                    <td>{transaction.description}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.amount')}</td>
                    <td className="fw-bold">{parseFloat(transaction.amount).toFixed(2)}€ {(transaction.fee && transaction.fee > 0) ? 
                        <span className="fw-normal" style={{color:'red'}}> -{parseFloat(transaction.fee).toFixed(2)}€ {t('transactions.fee')}</span> 
                        : ""}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.date')}</td>
                    <td>{formatDate(transaction.issued_at)}</td>
                  </tr>

                </tbody>
              </Table>
            </Col>
            <Col md={6}>
              <h5>{t('transactions.payment_info')}</h5>
              <Table bordered>
                <tbody>
                  <tr>
                    <td className="fw-bold">{t('transactions.status')}</td>
                    <td>
                      {getTransactionStatusBadge(transaction.transaction_status)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.type')}</td>
                    <td>
                      <Badge bg="secondary">{transaction.type}</Badge>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.reason')}</td>
                    <td>
                      <Badge bg="info">{transaction.reason}</Badge>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.contact')}</td>
                    <td>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {transaction.member_name || ''} {transaction.member_lastname || ''}
                          </Tooltip>
                        }
                      >
                        {transaction.memberId ? (
                          <Link to={`/dashboard/members/${transaction.memberId}/payments`}>
                            {transaction.member_alias} ({transaction.contact || transaction.memberId})
                          </Link>
                        ) : (<span>{transaction.contact || '-'}</span>)}
                      </OverlayTrigger>

                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.event')}</td>
                    <td>
                      {transaction.eventId ? (
                        <Link to={`/dashboard/event_payments/${transaction.eventId}`}>
                          {transaction.event_name || transaction.eventId}
                        </Link >
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          {/* Technical Details Section */}
          <Row className="mb-3">
            <Col md={12}>
              <h5>{t('transactions.technical_details')}</h5>
              <Table bordered>
                <tbody>
                  <tr>
                    <td className="fw-bold">{t('transactions.created_at')}</td>
                    <td>{formatDate(transaction.created_at)}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.updated_at')}</td>
                    <td>{formatDate(transaction.updated_at)}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">{t('transactions.issuer')}</td>
                    <td>{transaction.issuer_name || '-'} ({transaction.issuer || '-'})</td>
                  </tr>
                  {transaction.error_message && (
                    <tr>
                      <td className="fw-bold text-danger">{t('transactions.error_message')}</td>
                      <td className="text-danger">{transaction.error_message}</td>
                    </tr>
                  )}
                  {transaction.merchant_params_req && (
                    <tr>
                      <td className="fw-bold">{t('transactions.merchant_params_req')}</td>
                      <td>
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip>
                              <pre style={{ maxHeight: '300px', overflow: 'auto', whiteSpace: 'pre-wrap', fontSize: '0.8rem' }}>
                                {typeof transaction.merchant_params_req === 'object'
                                  ? JSON.stringify(transaction.merchant_params_req, null, 2)
                                  : transaction.merchant_params_req}
                              </pre>
                            </Tooltip>
                          }
                        >
                          <Button variant="outline-secondary" size="sm">
                            {t('transactions.view_request_params')}
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  )}
                  {transaction.merchant_params_res && (
                    <tr>
                      <td className="fw-bold">{t('transactions.merchant_params_res')}</td>
                      <td>
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip>
                              <pre style={{ maxHeight: '300px', overflow: 'auto', whiteSpace: 'pre-wrap', fontSize: '0.8rem' }}>
                                {typeof transaction.merchant_params_res === 'object'
                                  ? JSON.stringify(transaction.merchant_params_res, null, 2)
                                  : transaction.merchant_params_res}
                              </pre>
                            </Tooltip>
                          }
                        >
                          <Button variant="outline-secondary" size="sm">
                            {t('transactions.view_response_params')}
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>

          {transaction.notes && (
            <div className="mb-3">
              <h5>{t('transactions.notes')}</h5>
              <div className="p-3 border rounded bg-light">
                {transaction.notes}
              </div>
            </div>
          )}

          {transaction.metadata && (
            <div>
              <h5>{t('transactions.additional_info')}</h5>
              <div className="p-3 border rounded bg-light">
                <pre>{JSON.stringify(transaction.metadata, null, 2)}</pre>
              </div>
            </div>
          )}
        </div>
    </>

  )
};
export default TransactionDetail;