import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Spinner, Alert, Container } from "react-bootstrap";
import axiosInstance from "../../utilities/axios_instance";

const MembershipStats = () => {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
  
    const apiRatios = process.env.REACT_APP_RATIOS_API || "http://localhost:8000/ratios/";
  
    useEffect(() => {
      const fetchEventStats = async () => {
        try {
          const response = await axiosInstance.get(apiRatios + "/membership_stats");
          setChartData(response.data.reverse()); 
        } catch (err) {
            setError(t('membershipStats.error'));
            console.error(err);
          } finally {
            setLoading(false);
          }
        };
      fetchEventStats();
    }, [apiRatios, t]);
  
    return (
      <Container className="mt-5">
        <h2>{t('membershipStats.title')}</h2>

        {loading && <Spinner animation="border" />}
        {error && <Alert variant="danger">{error}</Alert>}

        {!loading && !error && (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="new_members" stroke="#28a745" name={t('membershipStats.metrics.newMembers')} />
              <Line type="monotone" dataKey="lost_members" stroke="#dc3545" name={t('membershipStats.metrics.lostMembers')} />
              <Line type="monotone" dataKey="net_change" stroke="#007bff" name={t('membershipStats.metrics.netChange')} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Container>
    );
};

export default MembershipStats;