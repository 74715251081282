// Logout.js

import React from 'react';

import { useNavigate } from 'react-router-dom'; // Import useHistory hook
import { setAuthenticated } from './store/setting/userSlice';

const Logout = () => {
    const navigate = useNavigate();

  const handleLogout = () => {
    // Perform logout actions here
    setAuthenticated(false); // Example: Clear authentication state
    window.localStorage.setItem("auth state", JSON.stringify(false)); // <-- stringify
    window.localStorage.removeItem("auth user");;
    window.localStorage.clear();
    navigate('/');
};

  return (
    <div>
      <h2>Logout</h2>
      <p>Are you sure you want to logout?</p>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Logout;
