import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { useSubheader } from './SubheaderContext.js';
import { useTranslation } from 'react-i18next';

const Subheader = () => {
  const { links } = useSubheader();
  const { t } = useTranslation();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const subheaderRef = useRef(null);

  useEffect(() => {
    const updateArrows = () => {
      if (!subheaderRef.current) return;

      const { scrollLeft, scrollWidth, clientWidth } = subheaderRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    };

    const submenuScrollspy = () => {
      const submenulinks = document.querySelectorAll('.subheader a');
      submenulinks.forEach(navmenulink => {
        if (!navmenulink.hash) return;
        let section = document.querySelector(navmenulink.hash);
        if (!section) return;
        let position = window.scrollY + 200;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          document.querySelectorAll('.subheader a.active').forEach(link => link.classList.remove('active'));
          navmenulink.classList.add('active');
        } else {
          navmenulink.classList.remove('active');
        }
      });
    };

    window.addEventListener('load', submenuScrollspy);
    document.addEventListener('scroll', submenuScrollspy);
    document.addEventListener('scroll', updateArrows);
    window.addEventListener('resize', updateArrows);

    updateArrows(); // Initial check

    return () => {
      window.removeEventListener('resize', updateArrows);
      document.removeEventListener('scroll', submenuScrollspy);
      document.removeEventListener('scroll', updateArrows);
    };
  }, []);

  const scrollLeft = () => {
    if (subheaderRef.current) {
      subheaderRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (subheaderRef.current) {
      subheaderRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const handleScroll = useCallback(() => {
    if (!subheaderRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = subheaderRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
  }, []);

  const handleScrollAsign = useCallback(() => {
    const currentRef = subheaderRef.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      handleScroll();
    } else {
      setTimeout(handleScrollAsign, 1000);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useLayoutEffect(() => {
    if (subheaderRef.current) {
      subheaderRef.current.addEventListener('scroll', handleScroll);
      handleScroll();
    } else {
      setTimeout(handleScrollAsign, 1000);
    }

    return () => {
      if (subheaderRef.current != null) {
        subheaderRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, handleScrollAsign]);

  if (links.length === 0) return null;

  return (
    <div className="subheader-container">
      {showLeftArrow && <div className="scroll-arrow left" onClick={scrollLeft}>←</div>}
      <div className="subheader" ref={subheaderRef}>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.href}>{t(link.i18n)}</a>
            </li>
          ))}
        </ul>
      </div>
      {showRightArrow && <div className="scroll-arrow right" onClick={scrollRight}>→</div>}
    </div>
  );
};

export default Subheader;
