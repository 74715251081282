import { configureStore } from '@reduxjs/toolkit';
import userReducer from './setting/userSlice';
import tokenReducer from './setting/tokenSlice';
import loadingReducer from './setting/loadingSlice';

// ✅ Load persisted Redux state correctly
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) return undefined;
    const parsedState = JSON.parse(serializedState);

    return {
      auth: parsedState.auth || {},
      tokens: {
        accessToken: parsedState?.tokens?.accessToken || null,
        refreshToken: parsedState?.tokens?.refreshToken || null
      }
    };
  } catch (e) {
    console.warn("Failed to load state:", e);
    return undefined;
  }
}

// ✅ Save Redux state correctly
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify({
      auth: state.auth,
      tokens: {
        accessToken: state.tokens.accessToken,
        refreshToken: state.tokens.refreshToken
      },
      loading: state.loading,
    });
    localStorage.setItem('reduxState', serializedState);
  } catch (e) {
    console.warn("Failed to save state:", e);
  }
}

// ✅ Initialize Redux store
const persistedState = loadFromLocalStorage();

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    auth: userReducer,
    tokens: tokenReducer
  },
  preloadedState: persistedState
});

// ✅ Keep Redux state synchronized with localStorage
store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;
