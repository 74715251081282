import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {Chart as ChartJS} from 'chart.js/auto';

import { 
  CategoryScale,  // Ensure CategoryScale is imported
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js'; 
import annotationPlugin from 'chartjs-plugin-annotation';
import axiosInstance from '../../utilities/axios_instance';

// Register Chart.js components individually to ensure proper registration
ChartJS.register(
  CategoryScale,  // Register CategoryScale here
  LinearScale,
  BarElement, 
  Title, 
  Tooltip, 
  Legend,
  annotationPlugin
);

const EventAttendance2025 = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [attendanceData, setAttendanceData] = useState(null);
  const [error, setError] = useState(null);
  const apiRatios = process.env.REACT_APP_RATIOS_API || "http://localhost:8000/ratios";
 
  const ATTENDANCE_OBJECTIVE = 55; // 2025 attendance objective

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        setLoading(true);
        // Filter for 2025 events only
        const response = await axiosInstance.get(`${apiRatios}/attendance?year=2025`);
        
        // Parse the data to ensure numeric values
        const parsedData = { 
          ...response.data,
          averageAttendance: 15, // Hardcoded for now since the server value is incorrect
          events: response.data.events.map(event => ({
            ...event,
            maleCount: parseInt(event.maleCount) || 0,
            femaleCount: parseInt(event.femaleCount) || 0,
            guestCount: parseInt(event.guestCount) || 0,
            memberCount: parseInt(event.memberCount) || 0,
            totalAttendance: parseInt(event.totalAttendance) || 0
          }))
        };
        
        setAttendanceData(parsedData);
        setLoading(false);
        console.log("Chart Labels:", parsedData.events.map(event => event.name));

      } catch (err) {
        console.error('Error fetching 2025 attendance data:', err);
        setError('Failed to load attendance data');
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, [apiRatios]);

  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" variant="primary" />
        <p className="mt-2">{t('loading')}</p>
      </div>
    );
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  if (!attendanceData || !attendanceData.events || attendanceData.events.length === 0) {
    return <div>{t('stats.no_data_available')}</div>;
  }

  // Prepare data for the chart
  const chartData = {
    labels: attendanceData.events.map(event => event.name),
    datasets: [
      {
        label: t('stats.male_attendees', 'Male Attendees'),
        data: attendanceData.events.map(event => event.maleCount),
        backgroundColor: 'rgba(54, 162, 235, 0.7)',
        stack: 'Stack 0',
      },
      {
        label: t('stats.female_attendees', 'Female Attendees'),
        data: attendanceData.events.map(event => event.femaleCount),
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
        stack: 'Stack 0',
      },
      {
        label: t('stats.guests', 'Guests'),
        data: attendanceData.events.map(event => event.guestCount),
        backgroundColor: 'rgba(75, 192, 192, 0.7)',
        stack: 'Stack 0',
      },
      {
        label: t('stats.total_attendance', 'Total Attendance'),
        data: attendanceData.events.map(event => 
          parseInt(event.memberCount) + parseInt(event.guestCount)
        ),
        type: 'bar',
        backgroundColor: 'rgba(153, 102, 255, 0.7)',
        stack: 'Stack 1',
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category', // ✅ Explicitly set category scale
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: Math.max(
          ATTENDANCE_OBJECTIVE + 10,
          ...attendanceData.events.map(event => 
            parseInt(event.memberCount) + parseInt(event.guestCount)
          )
        ),
        grid: {
          drawOnChartArea: true
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          afterTitle: function(context) {
            const eventIndex = context[0].dataIndex;
            const event = attendanceData.events[eventIndex];
            return `Date: ${new Date(event.date).toLocaleDateString()}`;
          }
        }
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: ATTENDANCE_OBJECTIVE,
            yMax: ATTENDANCE_OBJECTIVE,
            borderColor: 'rgb(255, 0, 0)',
            borderWidth: 2,
            label: {
              content: `2025 Objective: ${ATTENDANCE_OBJECTIVE}`,
              enabled: true,
              position: 'end'
            }
          }
        }
      }
    }
  };
  
  return (
    <Row>
      <Col>
        <div style={{ height: '400px' }}>
          <Bar data={chartData} options={options} />
        </div>
        <div className="text-center mt-3">
          <p>
            <strong>{t('stats.average_attendance', 'Average Attendance')}:</strong> {attendanceData.averageAttendance.toFixed(1)} {t('stats.per_event', 'per event')}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default EventAttendance2025;