import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form, Modal, Alert, Row, Col, Container } from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';

const GrantMemberBadge = () => {
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_MEMBERS_API;
  const { t } = useTranslation();
  
  const badges = {
    bookmark: 1,
    scorebook: 2,
    concede: 5,
    block: 29
  };

  const [formData, setFormData] = useState({
    id: '',
    numSocio: '',
    eventId:'',
    badge: 1
  });
  const [memberDetails, setMemberDetails] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mid = params.get('mid');
    if (mid) {
      setFormData(prev => ({
        ...prev,
        id: mid
      }));
    }
  }, [location]);

  const handleSearchMember = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    try {
      const response = await axiosInstance.post(`${apiUrl}/search_member`, {
        id: formData.id
      });

      if (response.data.success) {
        setMemberDetails(response.data.member);
        setShowModal(true);
      } else {
        setError(t('grant_badge.member_not_found'));
      }
    } catch (error) {
      setError(t('grant_badge.error_searching_member'));
    }
  };

  const handleUpdateBadge = async () => {
    setError(null);
    setMessage(null);
    const formDataToSend = new FormData();
    formDataToSend.append('id', formData.id);
    formDataToSend.append('badge', formData.badge);
    formDataToSend.append('eventId', formData.eventId);

    try {
      const response = await axiosInstance.post(`${apiUrl}/grant_badge`, 
        {
          id: formData.id,
          badge:formData.badge,
          eventId:formData.eventId
        }
      );
      
      if (response.data.success) {
        setMessage(t('grant_badge.success'));
      } else {
        setError(t('grant_badge.error'));
      }
    } catch (error) {
      setError(t('grant_badge.generic_error'));
    } finally {
      setShowModal(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h1>{t('grant_badge.title')}</h1>

      <Form inline="true" onSubmit={handleSearchMember} className="mb-4">
        <Row className="align-items-center">
          <Col className="col-sm-2">
            <Form.Group controlId="formId">
              <Form.Label>{t('grant_badge.member_id')}</Form.Label>
              <Form.Control type="text" name="id" value={formData.id} onChange={handleChange} />
            </Form.Group>
          </Col>
         
          <Col className="col-sm-2">
            <Form.Group controlId="eventId">
              <Form.Label>{t('grant_badge.eventId')}</Form.Label>
              <Form.Control type="text" name="eventId" value={formData.eventId} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col className="col-sm-2">
            <Form.Group controlId="badge">
              <Form.Label>{t('grant_badge.choose_badge')}</Form.Label>
              <select 
                className="form-select" 
                id="badge" 
                name="badge" 
                value={formData.badge}
                onChange={handleChange}
                required
              >
                <option value="1">{t('badge.bookmark')}</option>
                <option value="2">{t('badge.scorebook')}</option>
                <option value="5">{t('badge.concede')}</option>
                <option value="29">{t('badge.block')}</option>
            </select>
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-start ">
            <Form.Group>
              <Form.Label> &nbsp; </Form.Label>
              <Button variant="primary" type="submit" className="mr-2 form-control">
                {t('upload.submit')}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success" onClose={() => setMessage()} dismissible>{message}</Alert>}

      {/* Modal to confirm image upload */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('grant_badge.confirm_grant')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {memberDetails && (
            <Container>
            <Row>
            <div className="col-5">
              <p>{t('upload.firstname')}: {memberDetails.firstname}</p>
              <p>{t('upload.lastname')}: {memberDetails.lastname}</p>
              <p>{t('upload.num_socio')}: {memberDetails.numSocio}</p>
              <p>{t('upload.member_id')}: {memberDetails.id}</p>
              <p>{t('upload.alias')}: {memberDetails.alias}</p>
              
            </div>
            <div className='col-5'>
              <img src={"https://pianomeetups.com/members/photos/"+('000'+memberDetails.numSocio).substr(-3)+".jpg"} style={{width:'100%'}} alt="Members face"/>
            </div>
            </Row>
            <Row>
              <h4 className='text-center strong'>
                {t('grant_badge.warning_grant', {
                  badge: t(`badge.${Object.keys(badges)[formData.badge - 1]}`)
                })}
              </h4>
            </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" onClick={handleUpdateBadge}>
            {t('grant_badge.grant')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GrantMemberBadge;
