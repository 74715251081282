import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error404 = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="gradient d-flex align-items-center justify-content-center min-vh-100">
                <Container className="text-center">
                    <h2 className="mb-5 mt-4 text-white">{t('error404.title')}</h2>
                    <Image src="/images/404.jpg" className="img-fluid mb-4 w-50" alt="404 Error" />
                    <p className="mt-2 text-white">{t('error404.description')}</p>
                    <Link className="btn bg-white text-primary d-inline-flex align-items-center" to="/dashboard">{t('error404.backToHome')}</Link>
                </Container>
                <div className="box">
                    <div className="c xl-circle">
                        <div className="c lg-circle">
                          <div className="c md-circle">
                                <div className="c sm-circle">
                                    <div className="c xs-circle">                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </>
    );
}

export default Error404;
