import React, { useState, useEffect } from 'react';
import { Table, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axiosInstance from '../../utilities/axios_instance';

const MembersInconsistencyReport = () => {
  const [inconsistentMembers, setInconsistentMembers] = useState([]);
  const [error, setError] = useState(null);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const apiUrl = process.env.REACT_APP_DUE_MEMBERSHIPS_API;

  // Fetch inconsistent members when component mounts
  useEffect(() => {
    const fetchInconsistentMembers = async () => {
      try {
        const response = await axiosInstance.get(`${apiUrl}/inconsistent-members`);
        setInconsistentMembers(response.data.inconsistentMembers);
      } catch (err) {
        console.error('Error fetching inconsistent members:', err);
        setError('Failed to load the report');
      }
    };

    fetchInconsistentMembers();
  }, [apiUrl]);

  return (
    <div>
      <h1>Inconsistent Members Report</h1>

      {/* Display error message */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Display table of inconsistent members */}
      {!isLoading && inconsistentMembers.length === 0 ? (
        <Alert variant="success">All members have active memberships!</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Alias</th>
              <th>Num Socio</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Category</th>
              <th>Membership Status</th>
            </tr>
          </thead>
          <tbody>
            {inconsistentMembers.map((member) => (
              <tr key={member.id}>
                <td>{member.id}</td>
                <td>{member.alias}</td>
                <td>{member.numSocio}</td>
                <td>{member.firstname}</td>
                <td>{member.lastname}</td>
                <td>{member.email}</td>
                <td>{member.category}</td>
                <td>{member.endMembership ? 'Active' : 'Inactive'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default MembersInconsistencyReport;
