import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faExchangeAlt, faSync } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Form, ListGroup, Table } from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import '../../i18n';
import { useUser } from '../../store/hooks';
import axiosInstance from '../../utilities/axios_instance';

const MembersList = () => {
  const transientAPI = process.env.REACT_APP_TRANSIENT_MEMBERS_API;
  const membersAPI = process.env.REACT_APP_MEMBERS_API;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'danger'
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [existingMembers, setExistingMembers] = useState([]);
  const [selectedExistingMember, setSelectedExistingMember] = useState(null);
  const [existingMemberDetails, setExistingMemberDetails] = useState(null);
  const [transientMemberDetails, setTransientMemberDetails] = useState(null);
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});

  
  const transientSignatureRef = useRef(null);
  const existingSignatureRef = useRef(null);
  const user = useUser(); // Get user information from context

  // First useEffect for fetching members
  useEffect(() => {
    axiosInstance.get(`${transientAPI}/get_members`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (response.data.success) {
        setMembers(response.data.members);
      } else {
        setMessage(t('transient.no_members_found'));
        setMessageType('danger');
      }
      setLoading(false);
    })
    .catch(error => {
      setMessage(t('transient.error_fetching_members'));
      setMessageType('danger');
      setLoading(false);
    });
  }, [transientAPI, t]);

  // Second useEffect for handling signatures
  useEffect(() => {
    // Display signatures when member details are loaded
    if (transientMemberDetails && transientMemberDetails.signature && transientSignatureRef.current) {
      transientSignatureRef.current.fromDataURL(transientMemberDetails.signature);
    }
    
    if (existingMemberDetails && existingMemberDetails.signature && existingSignatureRef.current) {
      existingSignatureRef.current.fromDataURL(existingMemberDetails.signature);
    }
  }, [transientMemberDetails, existingMemberDetails]);


  const handleDelete = (id) => {
    axiosInstance.delete(`${transientAPI}/delete_member`, { data: { id },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
    .then(response => {
      if (response.data.success) {
        setMembers(members.filter(member => member.id !== id));
        setMessage(t('transient.member_deleted_successfully'));
        setMessageType('success');
      } else {
        setMessage(t('transient.error_deleting_member'));
        setMessageType('danger');
      }
    })
    .catch(error => {
      setMessage(t('transient.error_deleting_member') + ": " + error.message);
      setMessageType('danger');
    });
    setShowModal(false);
  };

  const confirmDelete = (id) => {
    setModalType("delete");
    setSelectedMemberId(id);
    setShowModal(true);
  };

  const confirmTransfer = (id) => {
    setModalType("transfer");
    setSelectedMemberId(id);
    setShowModal(true);
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/edit_member/${id}`);
  };

  const handleTransfer = (id) => {
    axiosInstance.post(`${transientAPI}/transfer_member`, { memberId: id })
    .then(response => {
      if (response.data.success) {
        setMembers(members.filter(member => member.id !== id));
        setMessage(t('transient.member_transferred_successfully'));
        setMessageType('success');
      } else {
        setMessage(t('transient.error_transferring_member'));
        setMessageType('danger');
      }
    })
    .catch(error => {
      setMessage(t('transient.error_transferring_member') + ": " + error.response.data.message);
      setMessageType('danger');
    });
    setShowModal(false);
  };


  const handleUpdateCheck = (field, isChecked) => {
    setFieldsToUpdate(prev => ({
      ...prev,
      [field]: isChecked
    }));
  };
  const handleSync = (id) => {
    setSelectedMemberId(id);
    setShowSyncModal(true);
    
    

    // Fetch complete transient member details
    axiosInstance.get(`${transientAPI}/get_member`, {
      params: { id },
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (response.data.success) {
        setTransientMemberDetails(response.data.member);
      } else {
        setMessage(t('transient.error_fetching_member_details'));
        setMessageType('danger');
      }
    })
    .catch(error => {
      setMessage(t('transient.error_fetching_member_details') + ": " + error.message);
      setMessageType('danger');
    });
  };

  const handleExistingMemberSelect = (memberId) => {
    const selectedMember = existingMembers.find(member => member.id === memberId);
    setExistingMemberDetails(selectedMember);
  };

  const handleSyncConfirm = () => {
    debugger;
    // Check if we have both members and at least one field to update
    if (!existingMemberDetails || !transientMemberDetails || Object.keys(fieldsToUpdate).length === 0) {
      setMessage(t('transient.no_fields_selected'));
      setMessageType('warning');
      setShowSyncModal(false);
      return;
    }
  
    // Prepare the data to update
    const updateData = {};
  
    // Add selected fields to update
    Object.keys(fieldsToUpdate).forEach(field => {
      if (fieldsToUpdate[field]) {
        // Handle signature separately
        if (field === 'signature' && transientMemberDetails.signature) {
          updateData[field] = transientMemberDetails.signature;
        } else {
          // Map transient field to member field
          const transientField = Object.keys(transientMemberDetails).find(
            key => field === key || 
            (field === 'meetupID' && key === 'meetupId') ||
            (field === 'birthDate' && key === 'birthdate') ||
            (field === 'DNI' && key === 'dni') ||
            (field === 'telefono' && key === 'phone') ||
            (field === 'postalcode' && key === 'postalCode')
          );
          
          if (transientField && transientMemberDetails[transientField]) {
            updateData[field] = transientMemberDetails[transientField];
          }
        }
      }
    });
  
    // Call API to update the member using PUT
    axiosInstance.put(`${membersAPI}/members/${existingMemberDetails.id}`, updateData, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (response.data.success) {
        setMessage(t('transient.member_updated_successfully'));
        setMessageType('success');
        // Reset fields
        setFieldsToUpdate({});
        setExistingMemberDetails(null);
        setTransientMemberDetails(null);
        setFormData({ member: '' });
      } else {
        setMessage(t('transient.error_updating_member'));
        setMessageType('danger');
      }
    })
    .catch(error => {
      setMessage(t('transient.error_updating_member') + ": " + (error.response?.data?.message || error.message));
      setMessageType('danger');
    });
    
    setShowSyncModal(false);
  };

  const closeAlert = () => {
    setMessage('');
    setMessageType('');
  };

  const [formData, setFormData] = useState({ member: '' });
  const [memberSearchResults, setMemberSearchResults] = useState([]);
  const [showMemberResults, setShowMemberResults] = useState(false);

  const handleMemberChange = async (e) => {
    const { value } = e.target;
    setFormData(prev => ({ ...prev, member: value }));
    
    if (value.length >= 2) {
      try {
        const response = await axiosInstance.get(`${membersAPI}/search`, {
          params: { query: value, limit: 5 }
        });
        setMemberSearchResults(response.data);
        setShowMemberResults(true);
      } catch (error) {
        console.error('Error searching members:', error);
      }
    } else {
      setMemberSearchResults([]);
      setShowMemberResults(false);
    }
  };

  const handleMemberSelect = (member) => {
    setSelectedExistingMember(member.id);
    setFormData(prev => ({ ...prev, member: `${member.firstname} ${member.lastname}` }));
    setShowMemberResults(false);
    
    // Fetch complete existing member details
    axiosInstance.get(`${membersAPI}/members/${member.id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (response.data.success) {
        setExistingMemberDetails(response.data.member);
      } else {
        setMessage(t('transient.error_fetching_existing_member_details'));
        setMessageType('danger');
      }
    })
    .catch(error => {
      setMessage(t('transient.error_fetching_existing_member_details') + ": " + error.message);
      setMessageType('danger');
    });
  };

  
  return user && ['Super', 'Manager'].includes(user.role) ? (
    <Fragment>
      <div className="pagetitle">
        <h1>{t("transient.list")}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">{t("transient.home")}</a></li>
            <li className="breadcrumb-item"><a href="/dashboard">{t("transient.dashboard")}</a></li>
            <li className="breadcrumb-item active">{t("transient.list")}</li>
          </ol>
        </nav>
      </div>
      <div className='container mt-5'>
        {loading && <p>{t('transient.loading')}</p>}
        {message && (
          <div className={`alert alert-${messageType} alert-dismissible fade show`} role="alert">
            {message}
            <button type="button" className="btn-close" onClick={closeAlert} aria-label="Close">
              &nbsp;
            </button>
          </div>
        )}
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{t('transient.alias')}</th>
              <th>{t('transient.first_name')}</th>
              <th>{t('transient.last_name')}</th>
              <th>{t('transient.email')}</th>
              <th>{t('transient.phone')}</th>
              <th>{t('transient.category')}</th>
              <th>{t('transient.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {members.map(member => (
              <tr key={member.id}>
                <td>{member.alias}</td>
                <td>{member.firstname}</td>
                <td>{member.lastname}</td>
                <td>{member.email}</td>
                <td>{member.phone}</td>
                <td>{member.category}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => confirmDelete(member.id)}>
                    <FontAwesomeIcon icon={faTrash} size="xs"/>
                  </button>
                  <button className="btn btn-primary ms-1 btn-sm" onClick={() => handleEdit(member.id)}>
                    <FontAwesomeIcon icon={faEdit} size="xs"/>
                  </button>
                  <button className="btn btn-secondary ms-1 btn-sm" onClick={() => confirmTransfer(member.id)}>
                    <FontAwesomeIcon icon={faExchangeAlt} size="xs"/>
                  </button>
                  <button className="btn btn-info ms-1 btn-sm" onClick={() => handleSync(member.id)}>
                    <FontAwesomeIcon icon={faSync} size="xs"/>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        
        {/* Button Legend */}
        <div className="mt-3 mb-4">
          <h5>{t('transient.button_legend')}</h5>
          <div className="d-flex flex-wrap gap-3">
            <div className="d-flex align-items-center">
              <button className="btn btn-danger btn-sm"  disabled>
                <FontAwesomeIcon icon={faTrash} size="xs"/>
              </button>
              <span className="ms-1">{t('transient.delete_button_desc')}</span>
            </div>
            <div className="d-flex align-items-center">
              <button className="btn btn-primary btn-sm"  disabled >
                <FontAwesomeIcon icon={faEdit}   size="xs"/>
              </button>
              <span className="ms-1">{t('transient.edit_button_desc')}</span>
            </div>
            <div className="d-flex align-items-center">
              <button className="btn btn-secondary btn-sm"  disabled>
                <FontAwesomeIcon icon={faExchangeAlt} size="xs" />
              </button>
              <span className="ms-1">{t('transient.transfer_button_desc')}</span>
            </div>
            <div className="d-flex align-items-center">
              <button className="btn btn-info btn-sm" disabled>
                <FontAwesomeIcon icon={faSync} size="xs"/>
              </button>
              <span className="ms-1">{t('transient.sync_button_desc')}</span>
            </div>
          </div>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`transient.${modalType}.title`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t(`transient.${modalType}.body`)}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              {t('transient.cancel')}
            </Button>
            <Button variant="danger" onClick={() => modalType === 'delete' ? handleDelete(selectedMemberId) : handleTransfer(selectedMemberId)}>
              {t(`transient.${modalType}.action`)}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSyncModal} onHide={() => setShowSyncModal(false)} size={"xl"}>
          <Modal.Header closeButton>
            <Modal.Title>{t('transient.sync_member')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3 col-md-6 position-relative">
              <Form.Label>{t('payments.search_member_label')}</Form.Label>
              <Form.Control
                type="text"
                name="member"
                value={formData.member}
                onChange={handleMemberChange}
                placeholder={t('payments.search_member_placeholder')}
                autoComplete="off"
              />
              {showMemberResults && memberSearchResults.length > 0 && (
                <ListGroup 
                  className="position-absolute w-100 z-1000" 
                  style={{ maxHeight: '200px', overflowY: 'auto' }}
                >
                  {memberSearchResults.map(member => (
                    <ListGroup.Item 
                      key={member.id}
                      action
                      onClick={() => handleMemberSelect(member)}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <span>{member.firstname} {member.lastname}</span>
                      <small className="text-muted">
                        {member.alias ? `@${member.alias}` : ''}
                      </small>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Form.Group>
            {existingMemberDetails && transientMemberDetails && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>{t('transient.field_name')}</th>
                    <th>{t('transient.transient_value')}</th>
                    <th>{t('transient.current_member_value')}</th>
                    <th>{t('transient.update')}</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    {transient : 'id', member: 'id'},
                    {transient : 'numSocio', member: 'numSocio'},
                    { transient: 'alias', member: 'alias' },
                    { transient: 'meetupId', member: 'meetupID' },
                    { transient: 'firstname', member: 'firstname' },
                    { transient: 'lastname', member: 'lastname' },
                    { transient: 'gender', member: 'gender' },
                    { transient: 'birthdate', member: 'birthDate' },
                    { transient: 'dni', member: 'DNI' },
                    { transient: 'email', member: 'email' },
                    { transient: 'phone', member: 'telefono' },
                    { transient: 'category', member: 'category' },
                    { transient: 'address', member: 'address' },
                    { transient: 'postalCode', member: 'postalcode' },
                    { transient: 'city', member: 'city' },
                    { transient: 'province', member: 'province' }
                  ].map((field) => (
                    <tr key={field.transient}>
                      <td>{t(`transient.${field.transient}`)}</td>
                      <td>{transientMemberDetails[field.transient]}</td>
                      <td>{existingMemberDetails[field.member] || '-'}</td>
                      <td>
                        <Form.Check 
                          type="checkbox" 
                          onChange={(e) => handleUpdateCheck(field.member, e.target.checked)} 
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>{t('transient.signature')}</td>
                    <td>
                      <div style={{ width: '100%',maxWidth: '250px', height: '100px', border: '1px solid #ccc' }}>
                        <SignaturePad ref={transientSignatureRef} canvasProps={{ className: 'signature-pad', width: '100%', height: '150px' }} />
                      </div>
                    </td>
                    <td>
                      <div style={{ width: '100%',maxWidth: '250px', height: '100px', border: '1px solid #ccc' }}>
                        <SignaturePad ref={existingSignatureRef} canvasProps={{ className: 'signature-pad', width: '100%', height: '150px' }} />
                      </div>
                    </td>
                    <td>
                      <Form.Check 
                        type="checkbox" 
                        onChange={(e) => handleUpdateCheck('signature', e.target.checked)} 
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSyncModal(false)}>
              {t('transient.cancel')}
            </Button>
            <Button variant="primary" onClick={handleSyncConfirm}>
              {t('transient.sync')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  ) : (
    <div>
      <h1>{t('transient.access_denied')}</h1>
      <p>{t('transient.no_permission')}</p>
    </div>
  );
};

export default MembersList;
