import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Create a separate component that uses the translation hook
const PaymentUtils = () => {
  const { t } = useTranslation();
  
  /**
   * Creates a Badge component for payment status
   * @param {string} status - The payment status
   * @returns {JSX.Element} Badge component with appropriate color
   */
  const getPaymentStatusBadge = (status) => {
    const variants = {
      'PENDING': 'warning',
      'PARTIALLY_PAID': 'info',
      'PAID': 'success',
      'CANCELLED': 'danger'
    };
    return <Badge bg={variants[status] || 'secondary'}>{t(`event_payments.statuses.${status}`)}</Badge>;
  };

  /**
   * Creates a Badge component for transaction status
   * @param {string} status - The transaction status
   * @returns {JSX.Element} Badge component with appropriate color
   */
  const getTransactionStatusBadge = (status) => {
    return <Badge bg={getStatusBadgeColor(status)}>{t(`transactions.statuses.${status}`)}</Badge>;
  };
  

/**
 * Formats a date string for display in Spanish locale format
 * @param {string} dateString - The date string to format
 * @returns {string} Formatted date string in Spanish locale
 */
const formatDateForDisplay = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('es-ES', { hour12: false });
};

/**
 * Formats a date string to locale string
 * @param {string} dateString - The date string to format
 * @returns {string} Formatted date string or empty string if dateString is falsy
 */
const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleString();
};

/**
 * Gets the appropriate Bootstrap color for a transaction status
 * @param {string} status - The transaction status
 * @returns {string} Bootstrap color variant name
 */
const getStatusBadgeColor = (status) => {
  if (!status) return 'light';
  switch (status.toUpperCase()) {
    case 'COMPLETED':
      return 'success';
    case 'PENDING':
      return 'warning';
    case 'FAILED':
      return 'danger';
    case 'CANCELLED':
      return 'danger';
    case 'RETURN':
      return 'info';
    case 'BIZUM':
      return 'primary';
    case 'TRANSFER':
      return 'primary';
    default:
      return 'light';
  }
};

  return { getPaymentStatusBadge, getTransactionStatusBadge,formatDate,formatDateForDisplay };
};


export {

  PaymentUtils
};