import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import 'react-circular-progressbar/dist/styles.css';
import axiosInstance from '../../utilities/axios_instance';

const YearlyObjectives = () => {
    const { t } = useTranslation();
    const [nextEvent, setNextEvent] = useState(null);
    const [yearStats, setYearStats] = useState(null);
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [nextEventResponse, yearStatsResponse] = await Promise.all([
                    axiosInstance.get(`${process.env.REACT_APP_EVENTS_API}metrics/next`),
                    axiosInstance.get(`${process.env.REACT_APP_EVENTS_API}metrics/year-stats/${currentYear}`)
                ]);
                
                setNextEvent(nextEventResponse.data);
                setYearStats(yearStatsResponse.data);
            } catch (error) {
                console.error('Error fetching objectives data:', error);
            }
        };
        
        fetchData();
    }, []);

    const getAttendanceProgress = () => {
        if (!nextEvent) return { value: 0, color: '#ddd' };
        const total = nextEvent.total_attendees || 0;
        const progress = (total / 55) * 100;
        return {
            value: Math.min(progress, 100),
            color: progress >= 100 ? '#28a745' : progress >= 70 ? '#ffc107' : '#dc3545'
        };
    };

    const getNewAttendeesProgress = () => {
        if (!nextEvent) return { value: 0, color: '#ddd' };
        const newAttendees = nextEvent.new_attendees || 0;
        const progress = (newAttendees / 3) * 100;
        return {
            value: Math.min(progress, 100),
            color: progress >= 100 ? '#28a745' : progress >= 70 ? '#ffc107' : '#dc3545'
        };
    };

    const getYearlyEventsProgress = () => {
        if (!yearStats) return { value: 0, color: '#ddd' };
        const { total_events, months_left, avg_events_per_month } = yearStats;
        const projected = total_events + (months_left * avg_events_per_month);
        const progress = (total_events / 18) * 100;
        const projectedProgress = (projected / 18) * 100;
        
        return {
            value: Math.min(progress, 100),
            projected: Math.min(projectedProgress, 100),
            color: projectedProgress >= 100 ? '#28a745' : projectedProgress >= 70 ? '#ffc107' : '#dc3545'
        };
    };

    return (
        <Row className="text-center">
            <Col md={4}>
                <h5>{t('stats.objectives.eventAttendance')}</h5>
                <h6>{nextEvent?.name || t('stats.objectives.noScheduledEvent')}</h6>
                <div style={{ width: '200px', margin: 'auto' }}>
                    <CircularProgressbar
                        value={getAttendanceProgress().value}
                        text={`${nextEvent?.total_attendees || 0}/55`}
                        styles={buildStyles({
                            pathColor: getAttendanceProgress().color,
                            textColor: getAttendanceProgress().color
                        })}
                    />
                </div>
                <div className="mt-2">
                    <small>{t('stats.objectives.new')}: {nextEvent?.new_attendees || 0}/3</small><br/>
                    <small>{t('stats.objectives.nonMembers')}: {nextEvent?.non_member_attendees || 0}/15</small>
                </div>
            </Col>
            
            <Col md={4}>
                <h5>{t('stats.objectives.newAttendees')}</h5>
                <h6>{nextEvent?.name || t('stats.objectives.noScheduledEvent')}</h6>
                <div style={{ width: '200px', margin: 'auto' }}>
                    <CircularProgressbar
                        value={getNewAttendeesProgress().value}
                        text={`${nextEvent?.new_attendees || 0}/3`}
                        styles={buildStyles({
                            pathColor: getNewAttendeesProgress().color,
                            textColor: getNewAttendeesProgress().color
                        })}
                    />
                </div>
            </Col>
            
            <Col md={4}>
                <h5>{t('stats.objectives.yearlyEvents')}</h5>
                <h6>{currentYear}</h6>
                <div style={{ width: '200px', margin: 'auto' }}>
                    <CircularProgressbar
                        value={getYearlyEventsProgress().value}
                        text={`${yearStats?.total_events || 0}/18`}
                        styles={buildStyles({
                            pathColor: getYearlyEventsProgress().color,
                            textColor: getYearlyEventsProgress().color,
                            trailColor: '#eee'
                        })}
                    />
                </div>
                <div className="mt-2">
                    <small>{t('stats.objectives.projected')}: {Math.round(getYearlyEventsProgress().projected)}%</small>
                </div>
            </Col>
        </Row>
    );
};

export default YearlyObjectives;