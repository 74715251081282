import React, { Fragment, useEffect, useState } from "react";
import { Spinner, Alert, Container } from "react-bootstrap";
import MembershipStats from "../components/stats/MembershipStats";
import Card from "../components/Card";
import EventStats from "../components/stats/EventStats";
import YearlyObjectives from "../components/stats/YearlyObjectives";
import EventAttendance2025 from "../components/stats/EventAttendance2025";

const LandingDashboard = () => {
  return (
    <Fragment>
       <Card title="2025 Objectives">
        <YearlyObjectives />
       </Card>
       <Card title="2025 Event Attendance">
        <EventAttendance2025 />
       </Card>
       <Card title="Membership Stats">
        <MembershipStats />
      </Card>
      <Card title="Event Stats">
        <EventStats />
      </Card>
    </Fragment>
  );
};

export default LandingDashboard;