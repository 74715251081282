import React, { useState, useEffect } from 'react';
import { Container, Form, Spinner, Alert } from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import EventPayments from './EventPayments';
import { useTranslation } from 'react-i18next';

const PaymentsManager = () => {
    const { t } = useTranslation();
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [selectedReason, setSelectedReason] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const reasons = [
        'showcase',
        'entrada',
        'invitado',
        'otro',
        'showcase-socio',
        'entrada-junior',
        'entrada-puerta',
        'showcase-junior'
    ];

    const eventsAPIUrl = process.env.REACT_APP_EVENTS_API;

    useEffect(() => {
      const fetchEvents = async () => {
        try {
          const response = await axiosInstance.get(`${eventsAPIUrl}/events`, { params: { ordered: 'true' } });
          if (Array.isArray(response.data.events)) {
            setEvents(response.data.events);
          } else {
            console.error("Unexpected API response format:", response.data);
          }
        } catch (error) {
          console.error(t('prepare_event.error_fetching_events'), error.message);
        }
      };
      fetchEvents();
    }, [eventsAPIUrl,t]);
  

    return (
        <Container>
            <h2 className="mb-4">Event Payments Management</h2>
            
            <Form.Group className="mb-4">
                <Form.Label>Payment Reason</Form.Label>
                <Form.Select 
                    value={selectedReason} 
                    onChange={(e) => setSelectedReason(e.target.value)}
                    className="mb-3"
                >
                    <option value="">Select a reason...</option>
                    {reasons.map(reason => (
                        <option key={reason} value={reason}>
                            {reason}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>

            {(selectedReason !== 'otro') && (
                <Form.Group className="mb-4">
                    <Form.Label>Select Event</Form.Label>
                    <Form.Select 
                        value={selectedEvent} 
                        onChange={(e) => setSelectedEvent(e.target.value)}
                    >
                        <option value="">Select an event...</option>
                        {events.map(event => (
                            <option key={event.id} value={event.id}>
                                {event.name} - {new Date(event.date).toLocaleDateString()}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            )}

            {selectedEvent && <EventPayments eventId={selectedEvent} reason={selectedReason} />}
        </Container>
    );
};

export default PaymentsManager;