import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner, Alert, Table, Badge, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utilities/axios_instance';
import { handleAxiosError } from '../../utilities/error_handler';
import TransactionDetail from './TransactionDetail';
import { PaymentUtils } from './PaymentUtils';


const PaymentDetailsModal = ({ show, onHide, paymentId, eventId }) => {
  const { t } = useTranslation();
  const {getPaymentStatusBadge} = PaymentUtils();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const paymentsAPI = process.env.REACT_APP_PAYMENTS_API;
  const transactionsAPI = process.env.REACT_APP_TRANSACTIONS_API; 

  useEffect(() => {
    if (show && paymentId) {
      fetchPaymentDetails();
    }
  }, [show, paymentId]);

  const fetchPaymentDetails = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Fetch payment details
      const paymentResponse = await axiosInstance.get(`${paymentsAPI}/event-payment/${paymentId}`);
      setPaymentDetails(paymentResponse.data);
      
      // Fetch associated transactions
      const transactionsResponse = await axiosInstance.get(`${transactionsAPI}/payment/${paymentId}`);
      setTransactions(transactionsResponse.data.transactions || []);
      
      setLoading(false);
    } catch (err) {
      handleAxiosError(err, setError, 'payment details', 'retrieve');
      setLoading(false);
    }
  };


  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('payment_issues.payment_details')} #{paymentId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center p-4">
            <Spinner animation="border" />
            <p className="mt-2">{t('loading')}</p>
          </div>
        ) : error ? (
          <>
            <Alert variant="danger" dismissible>{error}</Alert>
            <Card.Body >{error}</Card.Body>
          </>
        ) : paymentDetails ? (
          <>
            <h4>{t('payment_issues.payment_info')}</h4>
            <Table bordered striped>
              <tbody>
                <tr>
                  <th>{t('payment_issues.id')}</th>
                  <td>{paymentDetails.id}</td>
                </tr>
                <tr>
                  <th>{t('payment_issues.event')}</th>
                  <td>{paymentDetails.event_id}</td>
                </tr>
                <tr>
                  <th>{t('payment_issues.attendee')}</th>
                  <td>{paymentDetails.attendee_name}</td>
                </tr>
                <tr>
                  <th>{t('payment_issues.member_id')}</th>
                  <td>{paymentDetails.member_id || t('payment_issues.not_available')}</td>
                </tr>
                <tr>
                  <th>{t('payment_issues.meetup_id')}</th>
                  <td>{paymentDetails.meetup_id || t('payment_issues.not_available')}</td>
                </tr>
                <tr>
                  <th>{t('payment_issues.total_amount')}</th>
                  <td>€{parseFloat(paymentDetails.total_amount).toFixed(2)}</td>
                </tr>
                <tr>
                  <th>{t('payment_issues.amount_paid')}</th>
                  <td>€{parseFloat(paymentDetails.amount_paid).toFixed(2)}</td>
                </tr>
                <tr>
                  <th>{t('payment_issues.amount_pending')}</th>
                  <td>€{parseFloat(paymentDetails.amount_pending).toFixed(2)}</td>
                </tr>
                <tr>
                  <th>{t('payment_issues.status')}</th>
                  <td>{getPaymentStatusBadge(paymentDetails.payment_status)}</td>
                </tr>
              </tbody>
            </Table>

            <h4 className="mt-4">{t('payment_issues.associated_transactions')}</h4>
            {transactions.length === 0 ? (
              <Card.Body >{t('payment_issues.no_transactions')}</Card.Body>
            ) : (
              <div className="transaction-list">
                {transactions.map(transaction => (
                  <TransactionDetail 
                    key={transaction.id} 
                    transaction={transaction} 
                    showActions={false}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <Alert variant="warning">{t('payment_issues.no_details_found')}</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentDetailsModal;