import React, { useState, useEffect, Fragment } from "react";
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Alert, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { FaMoneyCheck, FaSync } from "react-icons/fa";

const PrepareEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { eventId } = useParams(); // Get eventId from URL parameters
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [attendanceFile, setAttendanceFile] = useState(null);
  const [updateReport, setUpdateReport]= useState(false);
  const [attendees, setAttendees] = useState([]);
  const [resultMessage, setResultMessage] = useState('');
  const [resultSeverity, setResultSeverity] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sortColumn, setSortColumn] = useState(null); 
  const [sortOrder, setSortOrder] = useState('asc'); 
  const [selectedColumn] = useState(null);
  const [syncingMeetup, setSyncingMeetup] = useState(false);
  const meetupApiUrl = process.env.REACT_APP_MEETUP_API;

  const eventsAPIUrl = process.env.REACT_APP_EVENTS_API;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get(`${eventsAPIUrl}/events`, { params: { ordered: 'true' } });
        if (Array.isArray(response.data.events)) {
          setEvents(response.data.events);
        } else {
          setResultMessage(t('prepare_event.error_fetching_events'));
          setResultSeverity("danger");
          console.error("Unexpected API response format:", response.data);
        }
      } catch (error) {
        setResultMessage(t('prepare_event.error_fetching_events'));
          setResultSeverity("danger");
        console.error(t('prepare_event.error_fetching_events'), error.message);
      }
    };
    fetchEvents();
  }, [eventsAPIUrl,t]);

  // New useEffect to handle auto-loading when eventId is provided in URL
  useEffect(() => {
    if (eventId && events.length > 0) {
      // Set the selected event from URL parameter
      setSelectedEvent(eventId);
      // Automatically load the event attendance data
      const loadEventAttendance = async () => {
        const formData = new FormData();
        formData.append('eventId', eventId);
        try {
          const response = await axiosInstance.post(`${eventsAPIUrl}/next-event-attendance`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          if (response.data.success) {
            setAttendees(response.data.attendees);
            setResultMessage(t('prepare_event.auto_loaded_message'));
            setResultSeverity("success");
          } else {
            setResultMessage(t('prepare_event.error_processing') + response.data.message);
            setResultSeverity("danger");
          }
        } catch (error) {
          console.error(t('prepare_event.error_loading'), error.message);
          setResultMessage(t('prepare_event.error_loading') + error.message);
          setResultSeverity("danger");
        }
      };
      
      loadEventAttendance();
    }
  }, [eventId, events, eventsAPIUrl, t]);

  const handleFileChange = (e) => {
    setAttendanceFile(e.target.files[0]);
  };
  const handleRecoverEvent =async (e) =>{
    if(e!==null&&e.preventDefault) 
      {
        e.preventDefault();
      }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('eventId', selectedEvent);
    try {
      const response = await axiosInstance.post(`${eventsAPIUrl}/next-event-attendance`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.success) {
        setAttendees(response.data.attendees);
        setResultMessage(t('prepare_event.success_message'));
        setResultSeverity("success");
      } else {
        setResultMessage(t('prepare_event.error_processing') + response.data.message);
        setResultSeverity("danger");
      }
    } catch (error) {
      console.error(t('prepare_event.error_submitting'), error.message);
      setResultMessage(t('prepare_event.error_submitting') + error.message);
      setResultSeverity("danger");

    } finally {
      setIsSubmitting(false);
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!selectedEvent || !attendanceFile) {
      setResultMessage(t('prepare_event.missing_fields'));
      setResultSeverity("danger");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append('eventId', selectedEvent);
    formData.append('attendance', attendanceFile);
    formData.append('updateReport',updateReport);

    try {
      const response = await axiosInstance.post(`${eventsAPIUrl}/next-event-attendance`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.success) {
        setAttendees(response.data.attendees);
        setResultMessage(t('prepare_event.success_message'));
        setResultSeverity("success");
      } else {
        setResultMessage(t('prepare_event.error_processing') + response.data.message);
        setResultSeverity("danger");
      }
    } catch (error) {
      console.error(t('prepare_event.error_submitting'), error.message);
      setResultMessage(t('prepare_event.error_submitting') + error.message);
      setResultSeverity("danger");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSort = (column) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    debugger;
    setSortColumn(column);
    setSortOrder(newOrder);

    const sortedAttendees = [...attendees].sort((a, b) => {
      // Handle numeric values (like amount)
      if (column === 'amount') {
        const aValue = parseFloat(a[column]) || 0;
        const bValue = parseFloat(b[column]) || 0;
        return newOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }
      
      // Handle yes/no values (like paid)
      if (column === 'paid') {
        // Sort "yes" before "no"
        if (a[column]?.toLowerCase() === 'yes' && b[column]?.toLowerCase() !== 'yes') {
          return newOrder === 'asc' ? -1 : 1;
        }
        if (a[column]?.toLowerCase() !== 'yes' && b[column]?.toLowerCase() === 'yes') {
          return newOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }
      
      // Handle payment type
      if (column === 'payment') {
        // Convert to lowercase for case-insensitive comparison
        const aValue = (a[column] || '').toLowerCase();
        const bValue = (b[column] || '').toLowerCase();
        if (aValue < bValue) return newOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return newOrder === 'asc' ? 1 : -1;
        return 0;
      }

      // Default string comparison for other columns
      const aValue = a[column] || '';
      const bValue = b[column] || '';
      if (aValue < bValue) return newOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return newOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setAttendees(sortedAttendees);
  };

  
  const navigateToEventFinance = () => {
    if (selectedEvent) {
      navigate(`/dashboard/event_payments/${selectedEvent}`);
    } else {
      setResultMessage(t('prepare_event.select_event_first'));
      setResultSeverity("warning");
    }
  };

  const copyToClipboard = async (part = "whole") =>  {
    let tableText = "";

    switch (part) {
      case "left":
        tableText = `${t("prepare_event.header_name")}\t${t("prepare_event.header_rsvp")} \n`;
        break;
      case "right":
        tableText = `${t("prepare_event.header_amount")}\t${t("prepare_event.header_payment")}\t${t("prepare_event.header_comments")}\t${t("prepare_event.header_rsvp")}\n`;
        break;
      case "notion": 
        break;
      default:
        tableText = `${t("prepare_event.header_name")}\t${t("prepare_event.header_member_type")}\t ${t("prepare_event.header_amount")}\t${t("prepare_event.header_payment")}\t${t("prepare_event.header_comments")}\t${t("prepare_event.header_rsvp")}\n`;
        break;
    }

    attendees.forEach((attendee) => {
      switch (part) {
        case "left":
          tableText += `${attendee.Name}\t${attendee.RSVPDate}\n`;
          break;
        case "right":
          tableText += `${attendee.paid}\t${attendee.amount}\t${attendee.payment}\t${attendee.comments}\t${attendee.RSVPDate}\n`;
          break;
        case "notion":
          break;
        default:
          tableText += `${attendee.Name}\t${attendee.paid}\t${attendee.amount}\t${attendee.payment}\t${attendee.comments}\t${attendee.RSVPDate}\n`;
          break;
      }
    });
    
    switch (part) {
      case "notion":
         // Create an HTML table string
      const notionTable = `<table>
          ${attendees.map(attendee => `
            <tr>
              <td>${attendee.Name}</td>
              <td>${attendee.paid}</td>
              <td>${attendee.amount}</td>
              <td>${attendee.payment}</td>
              <td>${attendee.comments}</td>
              <td>${attendee.RSVPDate}</td>
            </tr>
              `).join('')}
            </table>`;
    
       const blob = new Blob([notionTable], { type: 'text/html' });
        const item = new ClipboardItem({ 'text/html': blob });
        navigator.clipboard.write([item])
           .then(() => {
              setResultMessage(t('prepare_event.copied_message', { part }));
              setResultSeverity("success");
            })
           .catch((error) => {
              setResultMessage(t('prepare_event.copy_error'));
              setResultSeverity("danger");
            });
      break;
      default:
        navigator.clipboard
            .writeText(tableText)
            .then(() => {
              setResultMessage(t('prepare_event.copied_message', { part }));
              setResultSeverity("success");
            })
            .catch((error) => {
              setResultMessage(t('prepare_event.copy_error'));
              setResultSeverity("danger");
            });
    }
  };

  const closeAlert= (e) =>
    {
      setResultMessage("");
  }

  const syncWithMeetup = async () => {
    try {
      setSyncingMeetup(true);
      setResultMessage( '');
      const response = await axiosInstance.post(`${meetupApiUrl}/update-event-attendance`, { eventId: selectedEvent });
      
      if (response.data.success) {
        handleRecoverEvent();
      } else {
        setResultMessage( t('events.meetup_sync_error'));
        setResultSeverity('danger' );
      }
    } catch (error) {
      console.error('Error syncing with Meetup:', error);
      setResultMessage( t('events.meetup_sync_error'));
      setResultSeverity('danger');
    } finally {
      setSyncingMeetup(false);
    }
  };
  

  return (
    <Fragment>
      <Container>
        <h1>{t('prepare_event.title')}</h1>
        {resultMessage && (
          <Alert variant={resultSeverity} dismissible onClose={(e) => closeAlert(e)}>{resultMessage}</Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={2} className="d-flex align-items-end">
              <Form.Group controlId="eventSelect">
                <Form.Label>{t('prepare_event.select_event_label')}</Form.Label>
                <Form.Select
                  value={selectedEvent}
                  onChange={(e) => setSelectedEvent(e.target.value)}
                  required
                >
                  <option value="">{t('prepare_event.select_event_placeholder')}</option>
                  {events.map((event) => (
                    <option key={event.id} value={event.id}>
                      {`${event.name} (${new Date(event.date).toLocaleDateString()})`}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={2} className="d-flex align-items-end">
              <Button variant="secondary" onClick={handleRecoverEvent} disabled={!selectedEvent} >
                {isSubmitting ? t('prepare_event.recovering') : t('prepare_event.recover')}
              </Button>
            </Col>
            <Col sm={2} className="d-flex align-items-end">
              <Button 
                  variant="info" 
                  onClick={syncWithMeetup} 
                  disabled={syncingMeetup||!selectedEvent}
                  className="d-flex align-items-center"
                >
                  <FaSync className={syncingMeetup ? "me-2 fa-spin" : "me-2"} />
                  {syncingMeetup ? t('events.syncing_meetup') : t('events.sync_meetup')}
                </Button>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="attendanceFile">
                <Form.Label>{t('prepare_event.attendance_label')}</Form.Label>
                <Form.Control
                  type="file" 
                  accept=".xls,.xlsx,.csv,.json"
                  onChange={handleFileChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="updateReport" className="form-inline inline-form">
                <Form.Label>{t('prepare_event.update_report')}</Form.Label>
                <Form.Check id="updateReportCC"
                  type="checkbox"
                  onChange={(e) => setUpdateReport(e.target.checked)}
                />
              </Form.Group>
            </Col>
            <Col sm={1} className="d-flex align-items-end">
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? t('prepare_event.submitting') : t('prepare_event.submit_button')}
              </Button>
            </Col>
          </Row>
        </Form>
        {attendees.length > 0 && (
          <div className="mt-4">
            <h2>{t('prepare_event.attendees_header')}</h2>
            <div className="d-flex gap-2 mb-3">
              <Button variant="secondary" onClick={() => copyToClipboard("whole")}>
                {t('prepare_event.copy_table')}
              </Button>
              <Button variant="secondary" onClick={() => copyToClipboard("notion")}>
                {t('prepare_event.copy_notion')}
              </Button>
              <Button variant="primary" onClick={navigateToEventFinance}>
              <FaMoneyCheck /> 
              </Button>
            </div>
            <Table striped bordered>
              <thead>
                <tr>
                  {['Name', 'paid', 'amount','payment', 'comments', 'RSVPDate'].map((col) => (
                    <th
                      key={col}
                      onClick={() => handleSort(col)}
                      className={selectedColumn === col ? 'bg-primary text-white' : ''}
                    >
                      {t(`prepare_event.${col.toLowerCase()}`)} {sortColumn === col && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {attendees.map((attendee, rowIndex) => (
                  <tr key={rowIndex}>
                    {['Name', 'paid', 'amount','payment', 'comments', 'RSVPDate'].map((col) => (
                      <td key={col}>{attendee[col]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default PrepareEvent;
