import axios from 'axios';
import { store } from '../store/index'; // Your Redux store
import { setAccessToken, setRefreshToken, clearTokens } from '../store/setting/tokenSlice'; // Add a setRefreshToken action
import { clearUser, setAuthenticated } from '../store/setting/userSlice';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../store/setting/loadingSlice';




// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Request Interceptor to automatically attach the access token
axiosInstance.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getState().tokens;  // ✅ Now correctly accesses the token
    store.dispatch(setLoading(true)); // Start loading when a request starts
    
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    store.dispatch(setLoading(false));
    return config;
  },
  (error) => {
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  }
);

// Response Interceptor to handle token expiration and refreshing
axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(setLoading(false));
    return response;//Simply return the response if successful
  },
  async (error) => {
    const originalRequest = error.config;
    store.dispatch(setLoading(false));
    store.dispatch(clearLoading());

    // If access token is expired and we have not retried yet
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Get the current refresh token
        const { refreshToken } = store.getState().tokens;  // ✅ Now correctly accesses the token
        //const user = store.getState().auth.user;

        if (!refreshToken) {
          throw new Error("No refresh token available");
        }

        // Request a new access token using the refresh token
        const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/refresh`, {
          refreshToken: refreshToken,
        });

        // Update the access token and refresh token in Redux store or localStorage
        store.dispatch(setAccessToken(data.accessToken));

        if (data.refreshToken) {
          store.dispatch(setRefreshToken(data.refreshToken));
        }

        // Retry the original request with the new access token
        originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
        return axiosInstance(originalRequest);
      } catch (e) {
        console.error('Refresh token expired or invalid. Redirecting to login...');
        
        // Clear local storage
        window.localStorage.clear();

        // Dispatch all actions through the store
        store.dispatch(clearTokens());
        store.dispatch(clearUser());
        store.dispatch(setAuthenticated(false));
        store.dispatch(clearLoading());
        store.dispatch(setLoading(false));

        // Redirect after state is cleared
        window.location.href = '/login';
        
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      finally
      {    
        store.dispatch(setLoading(false));
        clearLoading();
      }
    }
    if (error.response && error.response.status === 403)
    {
      console.error('Refresh token expired or invalid. Redirecting to login...');
      debugger;
        window.localStorage.setItem("auth state", JSON.stringify(false));
        window.localStorage.setItem("user state", null);
        window.localStorage.clear();
        const dispatch = useDispatch();  // to dispatch actions to Redux store
        store.dispatch(setLoading(false));

        dispatch(clearUser());
        dispatch(clearTokens());
        dispatch(clearLoading());
        
      window.location.href = '/'; 
    }
    return Promise.reject(error); // Handle other errors normally
  }
);

export default axiosInstance;
