import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Button, Modal, Form, Table,Alert, Row, Col } from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { handleAxiosError } from '../../utilities/error_handler';
import TransactionDetail from '../../components/payments/TransactionDetail';
import { PaymentUtils } from '../../components/payments/PaymentUtils';
import TransactionEdit from '../../components/payments/TransactionEdit';

const MemberPayments = () => {  
    const { memberId } = useParams();
    const { t } = useTranslation();
    const {formatDateForDisplay} = PaymentUtils();
    const [member, setMember] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'issued_at', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState(null);

    const membersAPI= process.env.REACT_APP_MEMBERS_API;
    const transactionsAPI= process.env.REACT_APP_TRANSACTIONS_API;

    const navigate = useNavigate();

    
    
    useEffect(() => {
        fetchMemberData();
    }, [memberId]);

    const fetchMemberData = async () => {
        setLoading(true);
        try {
            // Separate the API calls to handle them independently
            try {
                const memberResponse = await axiosInstance.get(`${membersAPI}/members/${memberId}`);
                setMember(memberResponse.data.member);
            } catch (memberErr) {
                handleAxiosError(memberErr, setError, 'member_data', 'load');
                // Continue execution to try fetching transactions
            }
            
            try {
                const transactionsResponse = await axiosInstance.get(`${transactionsAPI}/member/${memberId}`);
                setTransactions(transactionsResponse.data);
            } catch (transactionErr) {
                handleAxiosError(transactionErr, setError, 'transaction_data', 'load');
                // Set empty transactions array if this fails
                setTransactions([]);
            }
        } catch (err) {
            // This catch block handles any other errors
            handleAxiosError(err, setError, 'data', 'load');
        } finally {
            setLoading(false);
        }
    };
    const handleView = (transaction) => {
        setSelectedTransaction(transaction);
        setShowViewModal(true);
    };

    const handleEdit = (transaction) => {
        setSelectedTransaction(transaction);
        setShowEditModal(true);
    };

    const handleDelete = (transaction) => {
        setSelectedTransaction(transaction);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        try {
            await axiosInstance.delete(`${transactionsAPI}/${selectedTransaction.id}`);
            setTransactions(transactions.filter(t => t.id !== selectedTransaction.id));
            setShowDeleteModal(false);
        } catch (err) {
            handleAxiosError(err, setError, 'transaction', 'delete');
        }
    };

     // Handle transaction field change
  const handleTransactionChange = (field, value) => {
    setSelectedTransaction(prev => ({
      ...prev,
      [field]: value
    }));
  };

    async function updateTransaction() {
        try {
            const response = await axiosInstance.put(`${transactionsAPI}/${selectedTransaction.id}`, selectedTransaction);
            
            // Ensure the returned transaction has all required properties
            const updatedTransaction = {
                ...response.data.transaction,
                description: response.data.transaction.description || '' 
            };
            
            const updatedTransactions = transactions.map(t => 
                t.id === selectedTransaction.id ? updatedTransaction : t
            );
            
            setTransactions(updatedTransactions);
            fetchMemberData();
            setShowEditModal(false);
            
            return response.data;
        } catch (err) {
            handleAxiosError(err, setError, 'transaction', 'update');
        }
    }
    
    const handleEventClick = (eventId) => {
        if (eventId) {
            navigate(`/dashboard/event_payments/${eventId}`);
        }
    };

    const sortedTransactions = React.useMemo(() => {
        let sortableTransactions = [...transactions];
        if (sortConfig !== null) {
            sortableTransactions.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableTransactions;
    }, [transactions, sortConfig]);

    const filteredTransactions = React.useMemo(() => {
        return sortedTransactions.filter(transaction =>
            transaction && (
                (transaction.description && transaction.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (transaction.reason && transaction.reason.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (transaction.order_id && transaction.order_id.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        );
    }, [sortedTransactions, searchTerm]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const calculateEconomicSummary = () => {
        const totalAmount = transactions.reduce((sum, transaction) => sum + parseFloat(transaction.amount || 0), 0);
        const completedTransactions = transactions.filter(transaction => transaction.transaction_status === 'COMPLETED').length;
        const pendingTransactions = transactions.filter(transaction => transaction.transaction_status === 'PENDING').length;
        const failedTransactions = transactions.filter(transaction => transaction.transaction_status === 'FAILED').length;
        const cancelledTransactions = transactions.filter(transaction => transaction.transaction_status === 'CANCELLED').length;
        return { totalAmount, completedTransactions, pendingTransactions, failedTransactions, cancelledTransactions };
    };

    const economicSummary = calculateEconomicSummary();

    if (loading) {
        return <div className="text-center mt-5">{t('loading')}</div>;
    }

    return (
        <Container className="mt-4">
           

            {/* Member Information */}
           
            <Card className="mb-4">
               
                <Card.Header>
                    <Row>
                        <Col >
                            <h3>{member ? `${member.firstname} ${member.lastname}` : `${t('members.not_found')} ${memberId} `}</h3>
                            {member?.alias && <small className="text-muted">@{member.alias}</small>}
                        </Col>
                        <Col style={{textAlign:'right'}}>
                            {member?.numSocio ? (
                                <img src={`https://pianomeetups.com/members/photos/${('000' + member.numSocio).slice(-3)}.jpg`}
                                    style={{ width: 'auto', maxHeight: '100px' }}
                                    alt="member" />
                            ) : (
                                <img src="/images/isotipo.png" style={{ width: 'auto', maxHeight: '100px' }} />
                            )}
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className="row mb-4 mt-4">
                        <div className="col-md-4">
                            <p><strong>{t('members.email')}:</strong> {member?.email || '-'}</p>
                            <p><strong>{t('members.telefono')}:</strong> {member?.telefono || '-'}</p>
                        </div>
                        <div className="col-md-4">
                            <p><strong>{t('members.member_since')}:</strong> {member?.registrationDate || '-'}</p>
                            <p><strong>{t('members.category')}:</strong> {member?.category || '-'}</p>
                        </div>
                        <div className="col-md-4 text-center">
                        </div>
                    </Row>
                    <Row>
                         {/* Economic Summary */}
                         <div className="col-md-3">
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className='one-line-text'>{t('payments.total_amount')}</Card.Title>
                                    <Card.Text className="h3 text-primary">{economicSummary.totalAmount.toFixed(2)}€</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-2">
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className='one-line-text'>{t('payments.completed_transactions')}</Card.Title>
                                    <Card.Text className="h3 text-success">{economicSummary.completedTransactions}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-2">
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className='one-line-text'>{t('payments.pending_transactions')}</Card.Title>
                                    <Card.Text className="h3 text-alert">{economicSummary.pendingTransactions}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-2">
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className='one-line-text'>{t('payments.failed_transactions')}</Card.Title>
                                    <Card.Text className="h3 text-danger">{economicSummary.failedTransactions}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-2">
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className='one-line-text'>{t('payments.cancelled_transactions')}</Card.Title>
                                    <Card.Text className="h3 text-muted">{economicSummary.cancelledTransactions}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                    </Row>
                    {error && <Alert variant="danger" dismissible={true}>{error}</Alert>}

                    <h4>{t('payments.payment_history')}</h4>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mb-3"
                    />
                    <div className="table-responsive">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th onClick={() => requestSort('issued_at')}>
                                        {t('payments.table.date')}
                                        {sortConfig.key === 'issued_at' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                                    </th>
                                    <th onClick={() => requestSort('order_id')}>
                                        {t('payments.table.order_id')}
                                        {sortConfig.key === 'order_id' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                                    </th>
                                    <th onClick={() => requestSort('description')}>
                                        {t('payments.table.description')}
                                        {sortConfig.key === 'description' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                                    </th>
                                    <th onClick={() => requestSort('eventId')}>
                                        {t('payments.table.event')}
                                        {sortConfig.key === 'eventId' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                                    </th>
                                    <th onClick={() => requestSort('reason')}>
                                        {t('payments.table.type')}
                                        {sortConfig.key === 'reason' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                                    </th>
                                    <th onClick={() => requestSort('amount')}>
                                        {t('payments.table.amount')}
                                        {sortConfig.key === 'amount' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                                    </th>
                                    <th onClick={() => requestSort('transaction_status')}>
                                        {t('payments.table.status')}
                                        {sortConfig.key === 'transaction_status' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : null}
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTransactions.map(transaction => (
                                    <tr key={transaction.id}>
                                        <td>{formatDateForDisplay(transaction.issued_at)}</td>
                                        <td>{transaction.order_id}</td>
                                        <td style={{maxWidth:'30%'}}>{transaction.description}</td>
                                        <td>
                                            {transaction.eventId && (
                                                <span 
                                                    className="text-primary" 
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleEventClick(transaction.eventId)}
                                                >
                                                    #{transaction.eventId}
                                                </span>
                                            )}
                                        </td>
                                        <td>{transaction.reason}</td>
                                        <td>{parseFloat(transaction.amount).toFixed(2)}€ {(transaction.fee && parseFloat(transaction.fee) !== 0) ?(
                                            <span style={{color:'red',fontSize:'10px'}}>
                                                -{parseFloat(transaction.fee).toFixed(2)}
                                            </span>
                                        ):''}</td>
                                        <td>
                                            <span className={`badge bg-${
                                                transaction.transaction_status === 'COMPLETED' ? 'success' :
                                                transaction.transaction_status === 'PENDING' ? 'warning' : 'danger'
                                            }`}>
                                                {transaction.transaction_status}
                                            </span>
                                        </td>
                                        <td style={{minWidth:'140px'}}>
                                            <Button variant="primary" size="sm" onClick={() => handleView(transaction)}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>{' '}
                                            <Button variant="warning" size="sm" onClick={() => handleEdit(transaction)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>{' '}
                                            <Button variant="danger" size="sm" onClick={() => handleDelete(transaction)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
                

            </Card>
        
            {/* View Modal */}
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Transaction Details</Modal.Title>
                </Modal.Header>
                <Modal.Body > 
                    {selectedTransaction && (
                        <TransactionDetail transaction={selectedTransaction} />
                    )}
                </Modal.Body>
            </Modal>

            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedTransaction && (
                            <TransactionEdit transaction={selectedTransaction} handleTransactionChange={handleTransactionChange} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={updateTransaction}>Save Changes</Button>

                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this transaction?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={confirmDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default MemberPayments;
