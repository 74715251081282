import React, { useState, useEffect, useCallback } from 'react';
import userService from './userService';
import { Button, Table, Modal, Form, Alert } from 'react-bootstrap';
import { FaEdit, FaTrashAlt, FaEnvelope } from 'react-icons/fa'; // Import FontAwesome icons
import { useTranslation } from 'react-i18next';
import { useUser } from '../../store/hooks';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal,setShowConfirmModal] = useState(false);
  const [modalType,setModalType] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState(''); // New state for password mismatch
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const { t } = useTranslation();
  const currentUser = useUser();

  
  const loadUsers = useCallback(async () => {
    try {
      const response = await userService.getUsers();
      setUsers(response.data);
    } catch (error) {
      setMessage(t('users.error_loading'));
      setError(true);
      console.error('Error loading users:', error);
    }
  }, [t]); // Add t as dependency since it's used inside loadUsers

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);


  const handleShowModal = (user = null) => {
    setSelectedUser(user || { username: '', name: '', email: '', role: '2', lang: 'es' });
    setPassword('');
    setConfirmPassword('');
    setSendWelcomeEmail(false);
    setMessage('');
    setPasswordError(''); // Clear any existing error on modal open
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setShowModal(false);
  };

  const handleSave = async () => {
    // Check for password mismatch
    if (password && password !== confirmPassword) {
      setPasswordError(t('users.password_mismatch')); // Set the password error message
      return;
    }

    // Clear the password error if validation passes
    setPasswordError('');

    try {
      const userData = {
        username: selectedUser?.username || '',
        name: selectedUser?.name || '',
        email: selectedUser?.email || '',
        role: selectedUser?.role || '2',
        lang: selectedUser?.lang,
        password: password || undefined, // Only update if password is provided
        sendWelcomeEmail:sendWelcomeEmail||false
      };

      if (selectedUser?.id) {
        await userService.updateUser(selectedUser.id, userData);
        if (sendWelcomeEmail) {
          await userService.sendWelcomeEmail(selectedUser.id);
        }
      } else {
        await userService.createUser(userData);
      }
      loadUsers();
      handleCloseModal();
    } catch (error) {
        const aux=[];
        if (Array.isArray(error.response.data.errors))
        {   
            error.response.data.errors.forEach((el)=>aux.push(el.msg));
        }else{
            aux.push(error.response.data.errors.message)
        }
        
      setMessage(t('users.error_saving')+aux.join(" | "));
      setError(true);
      console.error('Error saving user:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      setShowConfirmModal(false);
      await userService.deleteUser(id);
      loadUsers();
      setMessage(t('users.user_deleted'));
      setError(false);
    } catch (error) {
      setMessage(t('users.error_deleting'));
      setError(true);
      console.error('Error deleting user:', error);
    }
  };

  const handleSendWelcomeEmail = async (id) => {
    try {
      setShowConfirmModal(false);
      await userService.sendWelcomeEmail(id);
      setMessage(t('users.email_sent'));
      setError(false);
    } catch (error) {
      setMessage(t('users.error_sending_email'));
      setError(true);
      console.error('Error sending email:', error);
    }
  };

  const updateRole = async (id, role) => {
    
    try {
      await userService.updateUser(id, { role: role });
      setError(false);
      setMessage(t('users.role_updated'));
      setError(false);
    } catch (error) {
      setMessage(t('users.role_update_error'));
      setError(true);
      console.error('Error updating role:', error);
    }
  }

  const confirmDelete = (id) => {
    setModalType("delete");
    setSelectedUser({ ...selectedUser, id:id});
    setShowConfirmModal(true);
  };
  const confirmTSendMail = (id) => {
    setModalType("send");
    setSelectedUser({ ...selectedUser, id:id});
    setShowConfirmModal(true);
  };

  const handleChangePassword = async (id) => {
    // Logic for handling password change
    // Could open another modal for this
  };

  return (
    <div>
      <h1>{t('users.app_users_title')}</h1><br />
      {message && <Alert variant={error ? "danger" : "success"} onClose={() => setMessage()} dismissible>{message}</Alert>}

      <Button className='mb-3' onClick={() => { setSelectedUser(null); handleShowModal() }}>{t('users.create_user')}</Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>{t('users.username')}</th>
            <th>{t('users.name')}</th>
            <th>{t('users.email')}</th>
            <th>{t('users.role')}</th>
            <th>{t('users.lang')}</th>
            <th>{t('users.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.username}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td className='text-center'>
                <Form.Select
                  value={user.role}
                  onChange={(e) => {
                    setSelectedUser({ ...user, role: e.target.value });
                    updateRole(user.id, e.target.value);
                    user.role = e.target.value;
                  }}
                  disabled={currentUser.id === user.id}
                >
                  <option value="1">{t('users.role_super')}</option>
                  <option value="2">{t('users.role_manager')}</option>
                  <option value="3">{t('users.role_user')}</option>
                </Form.Select>
              </td>
              <td className='text-center'>{user.lang}</td>
              <td className='text-center'>
                <Button onClick={() => handleShowModal(user)}><FaEdit /></Button>
                <Button variant="danger" onClick={() => confirmDelete(user.id)}><FaTrashAlt /></Button>
                <Button variant="info" onClick={() => confirmTSendMail(user.id)}><FaEnvelope /></Button>
                {/* <Button variant="warning" onClick={() => handleChangePassword(user.id)}><FaKey /></Button>*/}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for create/edit */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedUser ? t('users.edit_user') : t('users.create_user')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {message && <Alert variant={error ? "danger" : "success"} onClose={() => setMessage()} dismissible>{message}</Alert>}

          <Form>
            <Form.Group className='mb-2'>
              <Form.Label>{t('users.username')}</Form.Label>
              <Form.Control
                type="text"
                value={selectedUser?.username || ''}
                onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })}
              />
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Label>{t('users.name')}</Form.Label>
              <Form.Control
                type="text"
                value={selectedUser?.name || ''}
                onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Label>{t('users.email')}</Form.Label>
              <Form.Control
                type="email"
                value={selectedUser?.email || ''}
                onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Label>{t('users.role')}</Form.Label>
              <Form.Select
                value={selectedUser?.role || '2'}
                onChange={(e) => setSelectedUser({ ...selectedUser, role: e.target.value })}
              >
                <option value="1">{t('users.role_super')}</option>
                <option value="2">{t('users.role_manager')}</option>
                <option value="3">{t('users.role_user')}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Label>{t('users.lang')}</Form.Label>
              <Form.Select id="lang"
                value={selectedUser?.lang || 'es'}
                onChange={(e) => setSelectedUser({ ...selectedUser, lang: e.target.value })}
              >
                <option value="es">{t('users.lang_spanish')}</option>
                <option value="en">{t('users.lang_english')}</option>
                <option value="fr">{t('users.lang_french')}</option>
              </Form.Select>
            </Form.Group>
            {currentUser.id !== selectedUser?.id && (
              <>
                <Form.Group className='mb-2'>
                  <Form.Label>{t('users.password')}</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-2'>
                  <Form.Label>{t('users.confirm_password')}</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {passwordError && <div className="text-danger">{passwordError}</div>} {/* Display the error message */}
                </Form.Group>
                {!selectedUser?.id && (
                  <Form.Group className='mb-2'>
                    <Form.Check
                      type="checkbox"
                      label={t('users.send_welcome_email')}
                      checked={sendWelcomeEmail}
                      onChange={(e) => setSendWelcomeEmail(e.target.checked)}
                    />
                  </Form.Group>
                )}
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('users.close')}
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {t('users.save')}
          </Button>
        </Modal.Footer>
      </Modal>


      {/* CONFIRM MODAL */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("users."+modalType.toString()+'.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("users."+modalType.toString()+'.body',{id:selectedUser?.id})}
         {/*  {t('Are you sure you want to delete this user? This action cannot be undone.')}*/}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="danger" onClick={() => modalType === 'delete'? handleDelete(selectedUser.id) : handleSendWelcomeEmail(selectedUser.id)}>
            {t("users."+modalType.toString()+'.action')}
          </Button>
        </Modal.Footer>
        </Modal>
    </div>
  );
};

export default UserManagement;
