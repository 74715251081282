import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import SignIn from './views/auth/sign-in';
import Error404 from './views/errors/error404';
import Error500 from './views/errors/error500';
import Maintenance from './views/errors/maintenance';
import Default from './Default';
import Enlaces from './views/Links';
import { SubheaderProvider } from './components/SubheaderContext';
import Dashboard from './views/Dashboard';
import Landing from './Landing';
import Logout from './Logout';
import MemberForm from './views/members/MemberForm';
import MembersList from './views/members/TransientMembersList';
import DueMembershipsList from './views/reports/DueMembershipsList';
import BirthdayReport from './views/reports/BirthdayReport';
import MemberCardReport from './views/reports/MemberCardReport';
import UploadMemberImage from './views/members/UploadMemberImage';
import MembersReport from './views/reports/MembersReport';
import LandingDashboard from './views/LandingDashboard';
import Dashboard404 from './views/Dashboard404';
import AttendanceForm from './views/members/AttendanceForm';
import ShowcasesForm from './views/members/ShowcasesForm';
import MembersInconsistencyReport from './views/reports/MembersInconsistencyReport';
import UserManagement from './views/users/UserManagement';
import NextEventReport from './views/reports/NextEventReport';
import GrantMemberBadge from './views/members/GrantMemberBadge';
import WhatsAppChat from './views/wab/WhatsAppChat';
import ManageEvents from './views/events/ManageEvents';
import PrepareEvent from  './views/events/PrepareEvent';
import PaymentsManager from './views/tpv/PaymentsManager';
import { setAuthenticated } from './store/setting/userSlice';
import { useAuthenticated } from './store/hooks';
import ManualPaymentForm from './views/tpv/NewTPVPayment';
import PaymentResult from './views/tpv/payment-result';
import MemberPayments from './views/members/MemberPayments';
import EventPayments from './views/tpv/EventPayments';
import PaymentIssuesReport from './views/tpv/PaymentIssuesReport';
import TransactionsManager from './views/tpv/TransactionsManager';


//import SendMessages from './utilities/SendMessages';


function App() {

  const navigate = useNavigate();
  const isAuthenticated=useAuthenticated();
  
  useEffect(() => {
    document.title = process.env.REACT_APP_NAME;
    console.log("APP STARTED: "+process.env.REACT_APP_VERSION);
    
    if (isAuthenticated === null) {
      setAuthenticated(false);
    }
  }, [isAuthenticated]);

  const navigateToSignIn = () => {
    console.log("Trying to go to sign in");
    return navigate("login");
  };

  return (
    <SubheaderProvider>
        <Routes>
          <Route path="/" element={ <Default  onLoginRegister={navigateToSignIn} />} >
            <Route index element={ <Landing /> } />
            <Route path="dashboard" element={isAuthenticated ? <Dashboard   /> : <Navigate to="/login" />} >
              <Route index  element={<LandingDashboard />} />
              <Route path="new_member" element={<MemberForm />} />
              <Route path="new_member/:id" element={<MemberForm />} />
              <Route path="list_members" element={<MembersList />} />
              <Route path="edit_member/:id" element={<MemberForm />} />
              <Route path="members/:memberId/payments" element={<MemberPayments />} />
              <Route path="due_memberships" element={<DueMembershipsList />} />
              <Route path="badges" element={<GrantMemberBadge />}/>
              <Route path="birthdays" element={<BirthdayReport />} />
              <Route path="member_card" element={<MemberCardReport />} />
              <Route path="upload_photo" element={<UploadMemberImage />} />
              <Route path="members_list" element={<MembersReport /> } />
              <Route path="attendance" element={<AttendanceForm /> } />
              <Route path="videos" element={<ShowcasesForm /> } />
              <Route path="badges" element={<Dashboard404 /> } />
              <Route path="errors" element={<MembersInconsistencyReport /> } />
              <Route path="users" element={<UserManagement /> } />
              <Route path="manage_events" element={<ManageEvents />} />
              <Route path="contact_event" element={<NextEventReport />}/>
              <Route path="prepare_event/:eventId?" element={<PrepareEvent />}/>
              <Route path="wab" element={<WhatsAppChat />}/>
              <Route path="manual_payment" element={<ManualPaymentForm />} /> 
              <Route path="event_payments" element={<EventPayments />} />
              <Route path="event_payments/:eventId" element={<EventPayments />} />
              <Route path="payments_issues/:eventId?" element={<PaymentIssuesReport />} />
              <Route path="manage_payments" element={<PaymentsManager />} />
              <Route path="manage_transactions/:eventId?/:transactionId?" element={<TransactionsManager />} />
            </Route>
            <Route path="enlaces" element={<Enlaces />} />
            <Route path="login" element={!isAuthenticated ? <SignIn  /> : <Navigate to="/" />} s/>
            <Route path="links" element={ <Enlaces /> } />
            <Route path="new_member" element={<MemberForm />} />
          </Route>

          <Route path="payment-result" element={<PaymentResult />} />
          <Route path="logout" element={<Logout  />} />
          <Route path="400" element={<Error404 />} />
          <Route path="500" element={<Error500 />} />
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
    </SubheaderProvider>
  );
}

export default App;
