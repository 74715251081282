import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from '../../components/Card';

import auth1 from '../../assets/img/mpm/sign_in_bg.jpg';
import config from '../../config/header.json';
import { useSubheader } from '../../components/SubheaderContext';

import { store } from '../../store'; // Your Redux store
import { setAuthenticated, setUser } from '../../store/setting/userSlice';
import { useDispatch } from 'react-redux';
import { setTokens } from '../../store/setting/tokenSlice';


const SignIn = () => {
    const { t, i18n } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [logo, setLogo] = useState('');
    const navigate = useNavigate();
    
    const apiUrl = process.env.REACT_APP_LOGIN_API;

    const dispatch = useDispatch();  // to dispatch actions to Redux store

    const { setLinks } = useSubheader();

    useEffect(() => {
        setLinks([]);
        setLogo(config.logoSrc);
        return () => setLinks([]);
    }, [setLinks]);

    const handleLogin = async (e) => {
        e.preventDefault();
    
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });
    
            const data = await response.json();
            
            if (data.success) {
                const { user, accessToken, refreshToken } = data;
                // ✅ Save to localStorage (important for session persistence)
                localStorage.setItem('auth state', true);
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('user', JSON.stringify(user));

                // ✅ Save to Redux    
                dispatch(setUser(user));
                dispatch(setAuthenticated(true));
                dispatch(setTokens({ accessToken, refreshToken }));  // This updates the Redux store
               
                // ✅ Wait for Redux to update before using store.getState()
                setTimeout(() => {
                    console.log("Redux state after login:", store.getState().tokens);  
                }, 100); 
    
                // ✅ Change language based on user preference
                i18n.changeLanguage(user.lang);
                // ✅ Dispatch Redux actions
                setAuthenticated(true);
                // ✅ Redirect based on role
                if (user.role === 'Manager' || user.role === 'Super') {
                    navigate('/dashboard');
                } else {
                    navigate('/');
                }
            } else {
                setError(data.message || 'Login failed');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred while logging in');
            localStorage.clear();   
        }
    };
    


    return (
        <section className="login-content">
            <Row className="m-0 align-items-center vh-100">
                <Col md="6">
                    <Row className="justify-content-center">
                        <Col md="10">
                            <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                                <Card.Body>
                                    <p className="text-center" >
                                        <Image src={logo} className="mb-3" style={{ maxWidth: '200px' }} />
                                    </p>
                                    <h2 className="mb-2 text-center">{t('signIn')}</h2>
                                    <p className="text-center">{t('loginToAccessUtilities')}</p>
                                    <Alert id="loginError" variant="danger" show={!!error} onClose={() => setError("")} dismissible>
                                        <span>{error && <p>{error}</p>}</span>
                                    </Alert>
                                    <Form onSubmit={handleLogin} style={{ zIndex: 1 }}>
                                        <Row>
                                            <Col lg="12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="username">{t('username')}</Form.Label>
                                                    <Form.Control type="text" id="username" placeholder={t('username')} value={username} onChange={(e) => setUsername(e.target.value)} required />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="password">{t('password')}</Form.Label>
                                                    <Form.Control type="password" id="password" placeholder={t('password')} value={password} onChange={(e) => setPassword(e.target.value)} required />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="12" className="d-flex justify-content-between">
                                                <Form.Check className="form-check mb-3">
                                                    <Form.Check.Input type="checkbox" id="customCheck1" />
                                                    <Form.Check.Label htmlFor="customCheck1">{t('rememberMe')}</Form.Check.Label>
                                                </Form.Check>
                                                <Link to="/auth/recoverpw">{t('forgotPassword')}</Link>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center">
                                            <Button type="submit" variant="primary">{t('signIn')}</Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md="6" className="login-image d-md-flex d-none p-0 mt-n1 vh-100 overflow-hidden" style={{backgroundImage: `url(${auth1})`, backgroundPosition: "center", backgroundSize: "cover" }}>
                </Col>
            </Row>
        </section>
    );
};

export default SignIn;
