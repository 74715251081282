import React, { useState, useEffect, useMemo } from 'react';
import { Container, Table, Button, Spinner, Alert, Form, OverlayTrigger, Tooltip, Card, Collapse, Navbar } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../../utilities/axios_instance';
import { handleAxiosError } from '../../utilities/error_handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faInfoCircle, faExclamationTriangle, faFilePen, faExclamationCircle, faPlus, faCreditCard, faFilter, faFilterCircleXmark, faTrash, faAsterisk, faLink } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import '../reports/Reports.css';
import TPVPayment from './NewTPVPayment';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../store/hooks';
import { FaExclamationTriangle, FaMeetup } from 'react-icons/fa';
import { PaymentUtils } from '../../components/payments/PaymentUtils';
import AddEventPaymentModal from '../../components/payments/AddEventPaymentModal';


const EventPayments = () => {
    const { eventId } = useParams();
    const { getTransactionStatusBadge, getPaymentStatusBadge } = PaymentUtils();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [event, setEvent] = useState(null);
    const [events, setEvents] = useState(null);
    const [payments, setPayments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const paymentsAPI = process.env.REACT_APP_PAYMENTS_API;
    const transactionsAPI = process.env.REACT_APP_TRANSACTIONS_API;
    const eventsAPI = process.env.REACT_APP_EVENTS_API;
    const meetupAPI = process.env.REACT_APP_MEETUP_API;

    const [selectedEventId, setSelectedEventId] = useState(null);
    const [attendeeTransactions, setAttendeeTransactions] = useState([]);
    const [showTransactions, setShowTransactions] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [summary, setSummary] = useState(null);
    const [showEventSelect, setShowEventSelect] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [editingCell, setEditingCell] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [showSummary, setShowSummary] = useState(true);
    const [showManualEntry, setShowManualEntry] = useState(false);
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

    const [manualTransactionEntry, setManualTransactionEntry] = useState({
        type: 'bizum',
        reason: 'entrada',
        status: 'COMPLETED',
        description: '',
        amount: 0,
        member_id: null,
        meetup_id: null,
        event_payment_id: null,
        payment_date: new Date()
    });
    const user = useUser();
    const [showTPVPaymentModal, setShowTPVPaymentModal] = useState(false);
    const [tpvPaymentData, setTPVPaymentData] = useState({
        eventId: null,
        memberId: null,
        contact: null,
        amount: 0
    });
    const [resultMessage, setResultMessage] = useState(null);



    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [paymentToDelete, setPaymentToDelete] = useState(null);

    // Add function to handle payment deletion
    const handleDeletePayment = async () => {
        try {
            await axiosInstance.delete(`${paymentsAPI}/${paymentToDelete.id}`);
            setResultMessage(`Payment for ${paymentToDelete.attendee_name} has been deleted successfully`);
            await fetchEventData(selectedEventId);
            setShowDeleteModal(false);
        } catch (err) {
            handleAxiosError(err, setError, 'payment', 'delete');
        }
    };

    // Add file upload handler function
    const handleFileUpload = async () => {
        if (!selectedFile || !selectedEventId) return;

        setUploadLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('attendance', selectedFile);
        formData.append('eventId', selectedEventId);
        formData.append("updateReport", true)

        try {
            const response = await axiosInstance.post(`${eventsAPI}/next-event-attendance`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Refresh event data after successful upload
            await fetchEventData(selectedEventId);
            setSelectedFile(null);

            // Show success message or handle response
            setResultMessage('Event data updated successfully!');
        } catch (err) {
            handleAxiosError(err, setError, 'file', 'upload');
        } finally {
            setUploadLoading(false);
        }
    };


    useEffect(() => {

        fetchEvents();

        if (eventId) {
            setSelectedEventId(eventId);
            fetchEventData(eventId);
        }
    }, [eventId]);

    const fetchEvents = async () => {
        try {
            const response = await axiosInstance.get(`${eventsAPI}/events?ordered=true`);
            setEvents(response.data.events);
            setLoading(false);
        } catch (err) {
            handleAxiosError(err, setError, 'events', 'load');
        }
    };


    const handleEventSelect = async (selectedEvent) => {
        if (!selectedEvent) return;
        // Instead of fetching data directly, navigate to the event payments route
        navigate(`/dashboard/event_payments/${selectedEvent}`);
    };

    const fetchEventData = async (selectedEvent) => {
        setLoading(true);
        setError(null); // Reset error state

        try {
            const [eventRes, paymentsRes] = await Promise.all([
                axiosInstance.get(`${eventsAPI}/events/${selectedEvent}`),
                axiosInstance.get(`${paymentsAPI}/event-payments/${selectedEvent}`)
            ]);
            setEvent(null);
            setPayments(null);
            setTransactions(null);
            setSummary(null);
            setSearchTerm('');
            setEvent(eventRes.data);
            setPayments(paymentsRes.data.payments);
            setTransactions(paymentsRes.data.eventTransactions);

            // Extract transactions and calculate summary
            const trns = paymentsRes.data.eventTransactions || [];
            const showcases = trns.filter(t => t.reason.toLowerCase().includes('showcase'));
            const tickets = trns.filter(t =>
                t.reason.toLowerCase().includes('entrada') ||
                t.reason.toLowerCase().includes('invitado')
            );
            const others = trns.filter(t => !t.reason.toLowerCase().includes('showcase') && !t.reason.toLowerCase().includes('ticket'));


            setSummary(paymentsRes.data.summary);
            setSelectedEventId(selectedEvent);
            if (sortColumn) {
                requestSort(sortColumn, false);
            }
        } catch (err) {
            handleAxiosError(err, setError, 'event data', 'load');
        } finally {
            setLoading(false);
        }
    };

    const handlePaymentCreated = (newPayment) => {
        // Refresh the payments data
        fetchEventData(selectedEventId);
        // Show success message
        setResultMessage(t('event_payments.payment_created_success'));
    };

    const parseReviewReason = (reviewReasonString) => {
        try {
            return JSON.parse(reviewReasonString);
        } catch (error) {
            //console.error("Failed to parse review_reason:", error);
            return [];
        }
    };
    const handleViewTransactions = async (payment) => {
        try {
            setSelectedPayment(payment);
            setShowTransactions(true);
            const response = await axiosInstance.get(
                `${transactionsAPI}/member/${payment.member_id ? payment.member_id : payment.meetup_id}/${selectedEventId}/${payment.id}`
            );
            setAttendeeTransactions(response.data || []);
        } catch (err) {
            handleAxiosError(err, setError, 'events', 'load');
        }
    };



    // Replace the direct calculation with a useEffect
    const [filteredPayments, setFilteredPayments] = useState([]);

    useEffect(() => {
        if (!payments) {
            setFilteredPayments([]);
            return;
        }

        const filtered = payments.filter(payment =>
            payment.attendee_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            payment.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            payment.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            payment.payment_status?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredPayments(filtered);
    }, [payments, searchTerm]);

    // Update the sortedPayments calculation to use the new state
    const sortedPayments = useMemo(() => {
        if (!filteredPayments.length || !sortConfig.key) return filteredPayments;

        return [...filteredPayments].sort((a, b) => {
            const aValue = a[sortConfig.key] || '';
            const bValue = b[sortConfig.key] || '';

            if (typeof aValue === 'string') {
                return sortConfig.direction === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }

            return sortConfig.direction === 'asc'
                ? aValue - bValue
                : bValue - aValue;
        });
    }, [filteredPayments, sortConfig]);

    const requestSort = (key, updateOrder = true) => {
        let isAsc = sortColumn === key && sortOrder === 'asc';
        if (updateOrder)
            setSortOrder(isAsc ? 'desc' : 'asc');
        else
            isAsc = !isAsc;
        setSortColumn(key);

        // The sorting is now handled by the useMemo for sortedPayments
        // No need to manually sort and update payments here
        setSortConfig({
            key: key,
            direction: isAsc ? 'desc' : 'asc'
        });
    };
    const handleAmountEdit = async (payment, value) => {
        try {
            await axiosInstance.patch(`${paymentsAPI}${payment.id}`, {
                total_amount: parseFloat(value)
            });
            await fetchEventData(selectedEventId);
            if (sortColumn) {
                requestSort(sortColumn, false);
            }
            setEditingCell(null);
        } catch (err) {
            handleAxiosError(err, setError, 'events', 'load');
        }
    };
    const handleGuestEdit = async (payment, value) => {
        try {
            await axiosInstance.patch(`${paymentsAPI}/${payment.id}`, {
                guest_count: parseInt(value)
            });
            await fetchEventData(selectedEventId);
            if (sortColumn) {
                requestSort(sortColumn, false);
            }
            setEditingCell(null);
        } catch (err) {
            handleAxiosError(err, setError, 'events', 'load');
        }
    };

    const handleCreatePayment = (payment) => {
        setManualTransactionEntry({
            ...manualTransactionEntry,
            event_payment_id: payment.id,
            amount: payment.amount_pending,
            member_id: payment.member_id,
            meetup_id: payment.meetup_id,
            fee: 0,
            payment_date: payment.payment_date || new Date()
        });
        setShowManualEntry(true);
    };

    const handleManualTransactionEntrySubmit = async () => {
        try {
            const response = await axiosInstance.post(`${transactionsAPI}manual-entry`, {
                eventId: selectedEventId,
                contact: manualTransactionEntry.member_id || manualTransactionEntry.meetup_id,
                description: manualTransactionEntry.description.trim() === "" ? `${manualTransactionEntry.reason} - ${event.name}` : manualTransactionEntry.description,
                amount: manualTransactionEntry.amount,
                fee: manualTransactionEntry.fee || 0,
                type: manualTransactionEntry.type,
                reason: manualTransactionEntry.reason,
                status: manualTransactionEntry.status,
                member_id: manualTransactionEntry.member_id,
                meetup_id: manualTransactionEntry.meetup_id,
                event_payment_id: manualTransactionEntry.event_payment_id,
                payment_date: manualTransactionEntry.payment_date,
                issuer_id: user.id
            });

            setShowManualEntry(false);
            // Add a small delay before refreshing data to ensure backend processing is complete
            await fetchEventData(selectedEventId);
            setResultMessage('Payment added successfully!');
        } catch (err) {
            handleAxiosError(err, setError, 'manual payment', 'create');
        }
    };

    // Function to force attendance update event with Meetup data
    const updateEventAttendance = async () => {
        try {
            const response = await axiosInstance.post(`${meetupAPI}/update-event-attendance`, { eventId: event.id, meetupId: event.meetup_id });

            if (response.data.success) {
                setResultMessage(t('event_payments.sync_success'));
                fetchEventData(eventId);
            } else {
                setError(t('events.update_error', { id: event.id }));
            }
        } catch (error) {
            console.error('Error updating event from Meetup:', error);
            setError(t('events.update_error', { id: event.id }));
        } finally {
            setLoading(false);
        }
    };
    const openMeetupEvent = () => {
        window.open(`https://meetup.com/madrid-pianomeetups/events/${event.meetup_id}`, '_blank', 'noopener,noreferrer');
    };


    if (loading) return <Spinner animation="border" />;


    if (!selectedEventId) {
        return (
            <Container>
                <h2 className="mb-4">{t('event_payments.select_event')}</h2>
                <Form.Group className="mb-3">
                    <Form.Label>{t('event_payments.select_event_label')}</Form.Label>
                    <Form.Select
                        onChange={(e) => handleEventSelect(e.target.value)}
                        defaultValue={selectedEventId || ""}
                    >
                        <option value="" disabled>{t('event_payments.choose_event')}</option>
                        {events.map(event => (
                            <option key={event.id} value={event.id}>
                                {event.name} - {new Date(event.date).toLocaleDateString()}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Container>
        );
    }

    return (
        <Container>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 >
                    {event?.name} {t('event_payments.payments')}
                    <small className="text-muted ms-2 ">{new Date(event?.date).toLocaleDateString()}
                        <Button onClick={() => setShowEventSelect(!showEventSelect)}
                        aria-controls="event-select-form"
                        aria-expanded={showEventSelect}
                        className="btn-sm me-2 ms-2 mb-2"
                        variant="outline-secondary"
                        title={showEventSelect ? t('event_payments.hide_event_selection') : t('event_payments.show_event_selection')}
                    >
                        <FontAwesomeIcon icon={showEventSelect ? faFilterCircleXmark : faFilter} />
                        </Button>
                    </small>
                </h2>

                <h2 className="d-flex mb-2">
                    <Button
                        onClick={() => openMeetupEvent()}
                        className="btn-sm me-2"
                        variant="primary" size="sm"
                        title={t('events.meetup')} altw={t('events.meetup')}
                        disabled={!event?.meetup_id}
                    >
                        <FaMeetup />
                    </Button>

                    <Button
                        onClick={() => navigate(`/dashboard/payments_issues/${eventId}`)}
                        className="btn-sm"
                        variant="warning"
                        title={t('payment_issues.title')} alt={t('payment_issues.title')}
                        disabled={!event?.meetup_id}
                    >
                        <FaExclamationTriangle />
                    </Button>
                </h2>
            </div>

            <Collapse in={showEventSelect}>
                <div id="event-select-form">
                    <div className="d-flex align-items-center gap-3">
                        <Form.Group className="mb-3 flex-grow-1 col-2">
                            <Form.Label>{t('event_payments.select_event_label')}</Form.Label>
                            <Form.Select
                                onChange={(e) => handleEventSelect(e.target.value)}
                                value={selectedEventId || ""}
                            >
                                <option value="" disabled>{t('event_payments.choose_event')}</option>
                                {events?.map(evt => (
                                    <option key={evt.id} value={evt.id}>
                                        {evt.name} - {new Date(evt.date).toLocaleDateString()}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Text className="text-muted">
                                {t('event_payments.select_event_review')}
                            </Form.Text>
                        </Form.Group>

                        {selectedEventId && (
                            <>
                                <Form.Group className="mb-3 flex-grow-1">
                                    <Form.Label>{t('event_payments.update_event_data')}</Form.Label>
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            type="file"
                                            accept=".csv,.xls,.xlsx"
                                            onChange={(e) => {
                                                if (e.target.files.length > 0) {
                                                    const file = e.target.files[0];
                                                    setSelectedFile(file);
                                                }
                                            }}
                                            className="me-2"
                                            style={{ maxWidth: '300px' }}
                                        />
                                        <Button
                                            variant="primary"
                                            onClick={handleFileUpload}
                                            disabled={!selectedFile || uploadLoading}
                                            size="sm"
                                        >
                                            {uploadLoading ? t('event_payments.uploading') : t('event_payments.upload')}
                                        </Button>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {t('event_payments.upload_new_file')}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3 flex-grow-1">
                                    <Form.Label>{t('event_payments.utils')}</Form.Label>
                                    <div className="d-flex align-items-center">
                                        <Button
                                            variant="primary"
                                            onClick={updateEventAttendance}
                                            disabled={!selectedEventId || uploadLoading}
                                            size="sm"
                                        >
                                            {t('event_payments.sync_meetup')}
                                        </Button>
                                    </div>
                                    <Form.Text className="text-muted">
                                        {t('event_payments.sync_meetup_desc')}
                                    </Form.Text>
                                </Form.Group>
                            </>
                        )}
                    </div>
                </div>
            </Collapse>

            {error && <Alert variant="danger" dismissible={true}>{error}</Alert>}
            {resultMessage && <Alert variant="success" dismissible={true}>{resultMessage}</Alert>}

            {event && payments && (
                <>
                    {summary && (
                        <div className="mb-4">
                            <h4 onClick={() => setShowSummary(!showSummary)}
                                style={{ cursor: 'pointer' }}
                                className="d-flex align-items-center">
                                {t('event_payments.economic_summary')}
                                <FontAwesomeIcon
                                    icon={showSummary ? faEyeSlash : faEye}
                                    className="ms-2"
                                    size="xs"
                                />
                            </h4>

                            <Collapse in={showSummary}>
                                <div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Card.Title className='one-line-text'   >{t('event_payments.event_total')}  {summary.fees?.completed > 0 && (<OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                <div style={{ textAlign: 'left' }}>
                                                                    {summary.fees.attendees && summary.fees.attendees.map((requester, index) => (
                                                                        <div key={index}>
                                                                            {requester.contact}: {requester.amount}€ ({requester.status.substring(0, 3)})
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#6c757d', marginLeft: '5px' }} />
                                                    </OverlayTrigger>
                                                    )}</Card.Title>
                                                    <Card.Text className="h3 text-primary">{summary.total_amount}€
                                                        {summary.fees?.completed > 0 && (
                                                            <span className='fee' style={{ fontSize: '10px', color: 'red' }}>- {summary.fees?.completed} €</span>
                                                        )}
                                                    </Card.Text>
                                                    <p className="text-muted text-small">
                                                        {t('event_payments.expected_entries', { count: transactions?.length })}
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-2">
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Card.Title className='one-line-text'>{t('event_payments.tickets_total')}</Card.Title>
                                                    <Card.Text className="h3 text-success">{summary.tickets_total}€</Card.Text>
                                                    <p className="text-muted  text-small">
                                                        {t('event_payments.expected_entries', { count: payments.length })}
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-2">
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Card.Title className='one-line-text'>{t('event_payments.tickets_paid')}</Card.Title>
                                                    <Card.Text className="h3 text-success">{summary.tickets_paid}€</Card.Text>
                                                    <p className="text-muted one-line-text text-small">
                                                        {t('event_payments.payments_made', { count: payments.filter(p => parseFloat(p.amount_paid) > 0).length })}
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-2">
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Card.Title className='one-line-text'>{t('event_payments.tickets_pending')}</Card.Title>
                                                    <Card.Text className="h3 text-danger">{summary.tickets_pending}€</Card.Text>
                                                    <p className="text-muted one-line-text text-small">
                                                        {t('event_payments.pending_payments', { count: payments.filter(p => parseFloat(p.amount_pending) > 0).length })}
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-2">
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Card.Title className='one-line-text'>
                                                        {t('event_payments.showcases')}
                                                        {(summary.showcases.attendees && summary.showcases.attendees.length > 0) && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        <div style={{ textAlign: 'left' }}>
                                                                            {summary.showcases.attendees && summary.showcases.attendees.map((requester, index) => (
                                                                                <div key={index}>
                                                                                    {requester.contact}: {requester.amount}€ ({requester.status.substring(0, 4)})
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#6c757d', marginLeft: '5px' }} />
                                                            </OverlayTrigger>)}
                                                    </Card.Title>
                                                    <Card.Text className="h4 text-info">
                                                        {summary.showcases.total}€
                                                    </Card.Text>
                                                    <p className="text-muted one-line-text text-small">
                                                        {t('event_payments.paid_pending', { paid: summary.showcases.paid_count, pending: summary.showcases.pending_count })}
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-md-2">
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Card.Title className='one-line-text'>
                                                        {t('event_payments.other_reasons')}
                                                        {(summary.others.attendees && summary.others.attendees.length > 0) && (<OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        {summary.others.attendees && summary.others.attendees.map((requester, index) => (
                                                                            <div key={index}>
                                                                                {requester.contact}: {requester.amount}€ ({requester.status.substring(0, 4)})
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#6c757d', marginLeft: '5px' }} />
                                                        </OverlayTrigger>)}
                                                    </Card.Title>
                                                    <Card.Text className="h4 text-secondary">
                                                        {summary.others.total}€
                                                    </Card.Text>
                                                    <p className="text-muted one-line-text text-small">
                                                        {payments.filter(p =>
                                                            p.reason?.toLowerCase().includes('other') ||
                                                            p.reason?.toLowerCase().includes('otro')
                                                        ).length} {t('event_payments.payments')}
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        {/* <div className="col-md-2">
                                            <Card className="mb-3">
                                                <Card.Body>
                                                    <Card.Title className='one-line-text'>
                                                        {t('event_payments.fees')}
                                                    </Card.Title>
                                                    <Card.Text className="h4 text-danger">
                                                        {summary.fees?.total || "0.00"}€
                                                    </Card.Text>
                                                    <p className="text-muted one-line-text text-small">
                                                        {t('event_payments.completed_fees')}: {summary.fees?.completed || "0.00"}€
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </div> */}
                                    </div>
                                    <h5>{t('event_payments.payment_methods_summary')}</h5>
                                    <Table striped bordered size="sm" className="mb-4">
                                        <thead>
                                            <tr>
                                                <th>{t('event_payments.method')}</th>
                                                <th>{t('event_payments.total_count')}</th>
                                                <th>{t('event_payments.total_amount')}</th>
                                                <th>{t('event_payments.completed_count')}</th>
                                                <th>{t('event_payments.completed_amount')}</th>
                                                <th>{t('event_payments.incomplete_count')}</th>
                                                <th>{t('event_payments.incompleted_amount')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(summary.payment_methods).map(([method, data]) => (
                                                <tr key={method}>
                                                    <td>{method.charAt(0).toUpperCase() + method.slice(1)}</td>
                                                    <td>{data.total_count}</td>
                                                    <td>{data.total_amount}€</td>
                                                    <td>{data.completed_count}</td>
                                                    <td>{data.completed_amount}€</td>
                                                    <td>{data.pending_count}</td>
                                                    <td>{data.pending_amount}€</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr className="fw-bold" style={{ borderTop: '2px solid black' }}>
                                                <td>{t('event_payments.total')}</td>
                                                <td>{Object.values(summary.payment_methods).reduce((sum, data) => sum + data.total_count, 0)}</td>
                                                <td>{Object.values(summary.payment_methods).reduce((sum, data) => sum + parseFloat(data.total_amount), 0).toFixed(2)}€</td>
                                                <td>{Object.values(summary.payment_methods).reduce((sum, data) => sum + data.completed_count, 0)}</td>
                                                <td>{Object.values(summary.payment_methods).reduce((sum, data) => sum + parseFloat(data.completed_amount), 0).toFixed(2)}€</td>
                                                <td>{Object.values(summary.payment_methods).reduce((sum, data) => sum + data.pending_count, 0)}</td>
                                                <td>{Object.values(summary.payment_methods).reduce((sum, data) => sum + parseFloat(data.pending_amount), 0).toFixed(2)}€</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </Collapse>
                        </div>
                    )}

                    {payments.length === 0 ? (
                        <Alert variant="info" dismissible>{t('event_payments.no_payments_found')}</Alert>
                    ) : (<>
                        <h4>{t('event_payments.ticket_sales_detail')}<Button
                    onClick={() => setShowAddPaymentModal(true)}
                    className="btn-sm me-2 ms-2"
                    variant="outline-success" size="sm"
                    title={t('event_payments.add_payment')}
                >
                    <FontAwesomeIcon icon={faPlus} style />
                </Button></h4>

                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder={t('event_payments.search_placeholder')}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Form.Group>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th onClick={() => requestSort('attendee_name')} style={{ cursor: 'pointer' }}>
                                        {t('event_payments.name')} {sortColumn === 'attendee_name' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => requestSort('category')} style={{ cursor: 'pointer' }}>
                                        {t('event_payments.category')} {sortColumn === 'category' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => requestSort('guest_count')} style={{ cursor: 'pointer' }}>
                                        {t('event_payments.guests')} {sortColumn === 'guest_count' && (sortOrder === 'asc' ? '↑' : '↓')}{' '}
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>{t('event_payments.edit_guest_count_tooltip')}</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#6c757d' }} />
                                        </OverlayTrigger>
                                    </th>
                                    <th onClick={() => requestSort('total_amount')} style={{ cursor: 'pointer' }}>
                                        {t('event_payments.total')} {sortColumn === 'total_amount' && (sortOrder === 'asc' ? '↑' : '↓')}{' '}
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>{t('event_payments.edit_total_amount_tooltip')}</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#6c757d' }} />
                                        </OverlayTrigger>
                                    </th>
                                    <th onClick={() => requestSort('amount_paid')} style={{ cursor: 'pointer' }}>
                                        {t('event_payments.paid')} {sortColumn === 'amount_paid' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => requestSort('amount_pending')} style={{ cursor: 'pointer' }}>
                                        {t('event_payments.pending')} {sortColumn === 'amount_pending' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => requestSort('payment_status')} style={{ cursor: 'pointer' }}>
                                        {t('event_payments.status')} {sortColumn === 'payment_status' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => requestSort('last_transaction_date')} style={{ cursor: 'pointer' }}>
                                        {t('event_payments.last_payment')} {sortColumn === 'last_transaction_date' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th>{t('event_payments.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedPayments.map((payment) => (
                                    <tr key={payment.id}>
                                        <td>
                                            {parseFloat(payment.amount_pending) < 0 && (
                                                <OverlayTrigger
                                                    placement="top" class="mr"
                                                    overlay={<Tooltip>{t('event_payments.negative_pending_tooltip')}</Tooltip>}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faExclamationTriangle}
                                                        style={{ color: '#dc3545', verticalAlign: 'super', marginRight: '5px' }}
                                                    />
                                                </OverlayTrigger>
                                            )}
                                            {payment.manually_updated === 1 && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>{t('event_payments.edited_manually_tooltip')}</Tooltip>}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faFilePen}
                                                        style={{ color: 'rgb(255, 193, 7)', verticalAlign: 'super', marginRight: '5px' }}
                                                    />
                                                </OverlayTrigger>
                                            )}
                                            {payment.needs_review === 1 && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip className='text-left' style={{ textAlign: 'left' }}>
                                                            {parseReviewReason(payment.review_reason).map((reasonArray, index) => (
                                                                <li key={index} style={{ textAlign: 'left' }}>
                                                                    {t(reasonArray[0], reasonArray)}
                                                                </li>
                                                            ))}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faExclamationCircle}
                                                        style={{ color: '#dc3545', verticalAlign: 'super', marginRight: '5px' }}
                                                    />
                                                </OverlayTrigger>
                                            )}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {t('event_payments.member_id_tooltip', { memberId: payment.member_id || t('event_payments.not_a_member') })}
                                                    </Tooltip>
                                                }
                                            >
                                                <span
                                                    className="text-primary"
                                                    style={{
                                                        cursor: payment.member_id || payment.meetup_id ? 'pointer' : 'default',
                                                        maxWidth: '150px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        display: 'inline-block'
                                                    }}
                                                    onClick={() => (payment.member_id || payment.meetup_id) && navigate(`/dashboard/members/${payment.member_id || payment.meetup_id}/payments`)}
                                                >
                                                    {payment.attendee_name}
                                                </span>
                                            </OverlayTrigger>
                                            {payment.just_added === 1 && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip className='text-left' style={{ textAlign: 'left' }}>
                                                            {t('event_payments.just_added',)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faAsterisk}
                                                        style={{ color: '#dc3545', verticalAlign: 'super', marginRight: '5px' }}
                                                    />
                                                </OverlayTrigger>
                                            )}
                                        </td>
                                        <td style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {payment.category || payment.type || (payment.member_id ? t('event_payments.member') : (payment.attended_events > 0 ? t('event_payments.no_socio') : t('event_payments.firstimer')))}
                                        </td>
                                        <td onDoubleClick={() => {
                                            setEditingCell(`${payment.id}-guests`);
                                            setEditValue(payment.guest_count);
                                        }}>
                                            {editingCell === `${payment.id}-guests` ? (
                                                <Form.Control
                                                    type="number"
                                                    value={editValue}
                                                    onChange={(e) => setEditValue(e.target.value)}
                                                    onBlur={() => handleGuestEdit(payment, editValue)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleGuestEdit(payment, editValue);
                                                        }
                                                    }}
                                                    autoFocus
                                                />
                                            ) : (
                                                payment.guest_count
                                            )}
                                        </td>
                                        <td onDoubleClick={() => {
                                            setEditingCell(`${payment.id}-total`);
                                            setEditValue(payment.total_amount);
                                        }}>
                                            {editingCell === `${payment.id}-total` ? (
                                                <Form.Control
                                                    type="number"
                                                    value={editValue}
                                                    onChange={(e) => setEditValue(e.target.value)}
                                                    onBlur={() => handleAmountEdit(payment, editValue)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleAmountEdit(payment, editValue);
                                                        }
                                                    }}
                                                    autoFocus
                                                />
                                            ) : (
                                                payment.total_amount
                                            )}
                                        </td>
                                        <td>{payment.amount_paid}</td>
                                        <td>{payment.amount_pending}</td>
                                        <td>{getPaymentStatusBadge(payment.payment_status)}</td>
                                        <td>{payment.last_transaction_date ? new Date(payment.last_transaction_date).toLocaleDateString() : payment.last_payment_date ? new Date(payment.last_payment_date).toLocaleDateString() : "-"}</td>
                                        <td>
                                            <Button
                                                variant="outline-primary"
                                                size="sm"
                                                onClick={() => handleViewTransactions(payment)}
                                                title={t('event_payments.view_transactions')}
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                onClick={() => handleCreatePayment(payment)}
                                                title={t('event_payments.add_manual_transaction')}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                            <Button
                                                variant="outline-warning"
                                                size="sm"
                                                onClick={() => setShowTPVPaymentModal(true)}
                                                title={t('event_payments.send_payment_request')}
                                            >
                                                <FontAwesomeIcon icon={faCreditCard} />
                                            </Button>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => {
                                                    setPaymentToDelete(payment);
                                                    setShowDeleteModal(true);
                                                }}
                                                title={t('event_payments.delete_payment')}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>)}
                </>
            )}


            <Modal show={showTransactions} onHide={() => setShowTransactions(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('event_payments.transactions_for', { name: selectedPayment?.attendee_name })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {attendeeTransactions?.paymentTransactions?.length > 0 ? (
                        <>
                            <h5>{t('event_payments.ticket_sales_detail')}</h5>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>{t('event_payments.date')}</th>
                                        <th>{t('event_payments.amount')}</th>
                                        <th>{t('event_payments.reason')}</th>
                                        <th>{t('event_payments.type')}</th>
                                        <th>{t('event_payments.status')}</th>
                                        <th>{t('event_payments.description')}</th>
                                        <th>{t('event_payments.order')}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {attendeeTransactions.paymentTransactions.map((transaction) => (
                                        <tr key={transaction.id}>
                                            <td>{new Date(transaction.issued_at).toLocaleDateString()}</td>
                                            <td>{transaction.amount}€</td>
                                            <td>{transaction.reason}</td>
                                            <td>{transaction.type}</td>
                                            <td>{getTransactionStatusBadge(transaction.status)}</td>
                                            <td>{transaction.description || '-'}</td>
                                            <td>{transaction.order_id || transaction.id || '-'}</td>
                                            <td><Link to={`/dashboard/manage_transactions/${eventId}/${transaction.id}`} size="sm" className="btn btn-secondary btn-xs"> <FontAwesomeIcon icon={faEye} variant="outline-secondaryt" /></Link> </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {attendeeTransactions.otherTransactions?.length > 0 && (
                                <>
                                    <h5>{t('event_payments.other_sales_detail')}</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>{t('event_payments.date')}</th>
                                                <th>{t('event_payments.amount')}</th>
                                                <th>{t('event_payments.reason')}</th>
                                                <th>{t('event_payments.type')}</th>
                                                <th>{t('event_payments.status')}</th>
                                                <th>{t('event_payments.description')}</th>
                                                <th>{t('event_payments.order')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attendeeTransactions.otherTransactions.map((transaction) => (
                                                <tr key={transaction.id}>
                                                    <td>{new Date(transaction.issued_at).toLocaleDateString()}</td>
                                                    <td>{transaction.amount}€</td>
                                                    <td>{transaction.reason}</td>
                                                    <td>{transaction.type}</td>
                                                    <td>{getTransactionStatusBadge(transaction.status)}</td>
                                                    <td>{transaction.description || '-'}</td>
                                                    <td>{transaction.order_id || transaction.id || '-'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>)}
                        </>
                    ) : (
                        <Alert variant="info" dismissible>{t('event_payments.no_transactions_found')}</Alert>
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={showManualEntry} onHide={() => setShowManualEntry(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('event_payments.manual_payment_entry')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('event_payments.payment_type')}</Form.Label>
                            <Form.Select
                                value={manualTransactionEntry.type}
                                onChange={(e) => setManualTransactionEntry({ ...manualTransactionEntry, type: e.target.value })}
                            >
                                {['bizum', 'transfer', 'paypal', 'cash', 'other', 'invite'].map(type => (
                                    <option key={type} value={type}>{t(`transactions.types.${type.toLowerCase()}`)}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t('event_payments.reason')}</Form.Label>
                            <Form.Select
                                value={manualTransactionEntry.reason}
                                onChange={(e) => setManualTransactionEntry({ ...manualTransactionEntry, reason: e.target.value })}
                            >
                                {[
                                    'showcase', 'entrada', 'invitado', 'otro',
                                    'showcase-socio', 'entrada-junior', 'entrada-puerta',
                                    'showcase-junior'
                                ].map(reason => (
                                    <option key={reason} value={reason}>{t(`transactions.reasons.${reason.replace('-', '_')}`)}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t('event_payments.status')}</Form.Label>
                            <Form.Select
                                value={manualTransactionEntry.status}
                                onChange={(e) => setManualTransactionEntry({ ...manualTransactionEntry, status: e.target.value })}
                            >
                                {['PENDING', 'COMPLETED', 'RETURNED'].map(status => (
                                    <option key={status} value={status}>{t(`transactions.statuses.${status}`)}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t('event_payments.amount')} (€)</Form.Label>
                            <Form.Control
                                type="number"
                                step="1"
                                value={manualTransactionEntry.amount}
                                onChange={(e) => setManualTransactionEntry({ ...manualTransactionEntry, amount: parseFloat(e.target.value) })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('event_payments.fee')} (€)</Form.Label>
                            <Form.Control
                                type="number"
                                step="0.1"
                                value={manualTransactionEntry.fee}
                                onChange={(e) => setManualTransactionEntry({ ...manualTransactionEntry, fee: parseFloat(e.target.value) })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('event_payments.description')}</Form.Label>
                            <Form.Control
                                type="text"
                                value={manualTransactionEntry.description}
                                placeholder={t('event_payments.enter_payment_description')}
                                onChange={(e) => setManualTransactionEntry({ ...manualTransactionEntry, description: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('event_payments.payment_date')}</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={manualTransactionEntry.payment_date ? new Date(manualTransactionEntry.payment_date).toLocaleString('sv-SE', { hour12: false }).replace(' ', 'T').replace(/\//g, '-').replace(',', '').slice(0, 16) : ''}
                                onChange={(e) => {
                                    const localDate = new Date(e.target.value);
                                    setManualTransactionEntry({
                                        ...manualTransactionEntry,
                                        payment_date: localDate.toISOString()
                                    })
                                }
                                }
                            />
                            <Form.Text className="text-muted">
                                {t('event_payments.select_payment_date')}
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowManualEntry(false)}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="primary" onClick={handleManualTransactionEntrySubmit}>
                        {t('event_payments.submit_payment')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showTPVPaymentModal}
                onHide={() => setShowTPVPaymentModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('event_payments.send_payment_request')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showTPVPaymentModal && (
                        <TPVPayment
                            initialData={tpvPaymentData}
                            onSuccess={() => {
                                setShowTPVPaymentModal(false);
                                fetchEventData(selectedEventId); // Refresh data after successful payment
                            }}
                            inModal={true} // Pass this prop so the component knows it's in a modal
                        />
                    )}
                </Modal.Body>
            </Modal>
            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('event_payments.confirm_deletion')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {paymentToDelete && (
                        <>
                            <p>{t('event_payments.delete_payment_record', { name: paymentToDelete.attendee_name })}</p>

                            {paymentToDelete.amount_paid > 0 && (
                                <Alert variant="warning" dismissible>
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                                    {t('event_payments.already_paid_warning', { amount: paymentToDelete.amount_paid })}
                                </Alert>
                            )}

                            <p className="text-danger">{t('event_payments.action_cannot_be_undone')}</p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="danger" onClick={handleDeletePayment}>
                        {t('event_payments.delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <AddEventPaymentModal
            show={showAddPaymentModal}
            onHide={() => setShowAddPaymentModal(false)}
            eventId={selectedEventId}
            onSuccess={handlePaymentCreated}
        />
        </Container>
    );
};
export default EventPayments;
