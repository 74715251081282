import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Button, Table, Modal, Pagination, Container, Row, Col, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utilities/axios_instance';
import { FaSearch, FaEdit, FaQrcode, FaIdCard, FaIdBadge } from 'react-icons/fa'; // Icons for actions
import QRCode from 'react-qr-code';  // Import the QR code component
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MembersReport = () => {
  const { t } = useTranslation();
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false); // New state for edit modal
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const [numSocioSuggestion, setNumSocioSuggestion] = useState(null);

  const apiUrl = process.env.REACT_APP_MEMBERS_API;
  const loading = useSelector((state) => state.loading.isLoading);
  const navigate = useNavigate();


  const fetchMembers = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${apiUrl}/members`);
      setMembers(response.data.members);
      setFilteredMembers(response.data.members);
      setTotalPages(Math.ceil(response.data.members.length / itemsPerPage));
    } catch (error) {
      console.error(error);
    }
  }, [apiUrl, itemsPerPage]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);
  
  const gotoGrantBadges = (member) => {
    navigate(`/dashboard/badges?mid=${member.id}`);
  };

  // Search Handler
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filteredData = members.filter((member) =>
      String(member.numSocio).toLowerCase().includes(value) ||
      String(member.alias).toLowerCase().includes(value) ||
      String(member.firstname).toLowerCase().includes(value) ||
      String(member.lastname).toLowerCase().includes(value) ||
      String(member.email).toLowerCase().includes(value) ||
      String(member.category).toLowerCase().includes(value)
    );
    setFilteredMembers(filteredData); // Update filtered members based on search term
    setTotalPages(Math.ceil(filteredData.length / itemsPerPage)); // Update total pages based on search results
    setCurrentPage(1); // Reset to the first page
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowDetails = async (member) => {
    const response = await axiosInstance.get(`${apiUrl}/members/${member.id}`);
    const fetchedMember = response.data.member;
    setSelectedMember(fetchedMember);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Generate member's card URL
  const generateMemberCardUrl = (member) => {
    return `https://pianomeetups.com/members/member.html?mid=mpm${member.numSocio}`;
  };

  // Get members for current page
  const getCurrentPageMembers = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredMembers.slice(startIndex, endIndex);
  };

  const handleShowQRModal = async (member) => {

    setSelectedMember(member);
    setShowQRModal(true);  // Show edit modal
  };

  const handleCloseQRModal = () => {
    setSelectedMember(null)
    setShowQRModal(false);  // Close edit modal
  };

  const handleShowEditModal = async (member) => {
    const response = await axiosInstance.get(`${apiUrl}/members/${member.id}`);
    fetchNumSocioSuggestion()
    const fetchedMember = response.data.member;
    setSelectedMember(fetchedMember);
    setShowEditModal(true);  // Show edit modal
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);  // Close edit modal
  };


  // Update member info when input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedMember({
      ...selectedMember,
      [name]: value,
    });
  };

  // Save updated member info
  const handleSave = async () => {
    try {
      await axiosInstance.put(`${apiUrl}/members/${selectedMember.id}`, selectedMember);
      setError(false);
      setMessage('Member saved');
      fetchMembers(); // Callback to refresh member data after saving
      handleCloseEditModal();
    } catch (error) {
      console.error('Error updating member:', error);
      setMessage('Failed to save the member');
      setError(true);
      handleCloseEditModal();
    }
  };


  const handlePhotoUpdate= async (e) => {
    try {
      setError(null);
      setMessage(null);
      
      const formDataToSend = new FormData();
      formDataToSend.append('id', selectedMember.id);
      formDataToSend.append('image',  e.target.files[0]);
  
      try {
        const response = await axiosInstance.post(`${apiUrl}/upload_image`, formDataToSend);

        // selectedMember.numSocio=selectedMember.numSocio;
        if (response.data.success) {
          setMessage(t('upload.image_uploaded_successfully'));
        } else {
          setError(t('upload.failed_to_upload_image'));
        }
      } catch (error) {
        setError(t('upload.error_uploading_image'));
      } finally {
        //setShowModal(false);
      }
    } catch (error) {
      console.error('Error updating member:', error);
      setMessage('Failed to save the member');
      setError(true);
      handleCloseEditModal();
    }
  };
  const fetchNumSocioSuggestion = async () => {
    try {
      const response = await axiosInstance.get(`${apiUrl}/max-numsocio`);
      setNumSocioSuggestion(response.data.maxNumSocio + 1);
    } catch (error) {
      console.error('Error fetching max NumSocio:', error);
      setError('Failed to fetch NumSocio suggestion');
    }
  };

  return (
    <div>
      <h1>{t('members.list_title')}</h1>
      {message && <Alert variant={error?"danger":"success"} onClose={() => setMessage()} dismissible>{message}</Alert>}

      {/* Search Input */}
      <InputGroup className="mb-3">
        <FormControl
          placeholder={t('members.search_placeholder')}
          aria-label="Search"
          aria-describedby="search-icon"
          value={searchTerm}
          onChange={handleSearch} // Search handler
        />
        <InputGroup.Text id="search-icon">
          <FaSearch />
        </InputGroup.Text>
      </InputGroup>

      {loading ? (
        <p>{t('members.loading')}</p>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t('members.id')}</th>
                <th>{t('members.num_socio')}</th>
                <th>{t('members.alias')}</th>
                <th>{t('members.first_name')}</th>
                <th>{t('members.last_name')}</th>
                <th>{t('members.category')}</th>
                <th>{t('members.email')}</th>
                <th>{t('members.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {getCurrentPageMembers().map((member) => (
                <tr key={member.id}>
                  <td>{member.id}</td>
                  <td>{member.numSocio}</td>
                  <td>{member.alias}</td>
                  <td>{member.firstname}</td>
                  <td>{member.lastname}</td>
                  <td>{t(`members.categories.${member.category}`)}</td>
                  <td>{member.email}</td>
                  <td>
                    <Button variant="info" onClick={() => handleShowDetails(member)} className="mr-2">
                      <FaSearch />
                    </Button>
                    <Button variant="primary" className="mr-2" onClick={() => window.open(generateMemberCardUrl(member), '_blank')}>
                      <FaIdCard />
                    </Button>
                    <Button variant="success" className="mr-2" onClick={() => handleShowEditModal(member)}>
                      <FaEdit />
                    </Button>
                    <Button variant="secondary" className="mr-2" onClick={() => handleShowQRModal(member)}>
                      <FaQrcode />
                    </Button>
                    <Button variant="secondary" className="mr-2" onClick={() => gotoGrantBadges(member)}>
                      <FaIdBadge />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <Pagination>
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}

      {/* QR Code Modal */}
      {showQRModal && (
        <Modal show={selectedMember && !showModal} onHide={() => handleCloseQRModal()}>
          <Modal.Header closeButton>
            <Modal.Title>{t('members.qr_code')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <QRCode value={generateMemberCardUrl(selectedMember)} size={200} />
            <p>{generateMemberCardUrl(selectedMember)}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseQRModal()}>
              {t('close')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}



      {/* Member Details Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('members.details')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMember && (
            <Container>
              <Row className='mb-4'>
                <Col>
                  <div>
                    <strong>{t('members.id')}:</strong> {selectedMember.id}
                  </div>
                  <div>
                    <strong>{t('members.num_socio')}:</strong> {selectedMember.numSocio}
                  </div>
                  <div>
                    <strong>{t('members.alias')}:</strong> {selectedMember.alias}
                  </div>
                  <div>
                    <strong>{t('members.firstname')}:</strong> {selectedMember.firstname}
                  </div>
                  <div>
                    <strong>{t('members.lastname')}:</strong> {selectedMember.lastname}
                  </div>
                  <div>
                    <strong>{t('members.gender')}:</strong> {selectedMember.gender==='H'?t('members.male'):selectedMember.gender==='M'?t('members.female'):t('members.other')}
                  </div>
                  <div>
                    <strong>{t('members.email')}:</strong> {selectedMember.email}
                  </div>
                  <div>
                    <strong>{t('members.category')}:</strong>{t(`members.categories.${selectedMember.category}`)}
                  </div>
                  <div>
                    <strong>{t('members.registration_date')}:</strong> {selectedMember.registrationDate}
                  </div>
                  <div>
                    <strong>{t('members.birth_date')}:</strong> {new Date(selectedMember.birthDate).toLocaleDateString()}
                  </div>
                  <div>
                    <strong>{t('members.dni')}:</strong> {selectedMember.DNI}
                  </div>
                  <div>
                    <strong>{t('members.telefono')}:</strong> {selectedMember.telefono}
                  </div>
                </Col>
                <Col>
                  <div>
                    <img
                      src={`https://pianomeetups.com/members/photos/${('000' + selectedMember.numSocio).slice(-3)}.jpg`}
                      style={{ width: '100%', minHeight: '200px' }}
                      alt="member"
                    />
                  </div>
                  <div>
                    <strong>{t('members.address')}:</strong> {selectedMember.address}
                  </div>
                  <div>
                    <strong>{t('members.city')}:</strong> {selectedMember.city}
                  </div>
                  <div>
                    <strong>{t('members.province')}:</strong> {selectedMember.province}
                  </div>
                  <div>
                    <strong>{t('members.postalcode')}:</strong> {selectedMember.postalcode}
                  </div>
                  
                  <div>
                    <strong>{t('members.lang')}:</strong> {selectedMember.lang}
                  </div>
                </Col>
              </Row>
              <Row>
              {selectedMember.tags && selectedMember.tags.trim() && (
                 <div>
                    <strong>{t('members.tags.label')}:</strong>{' '}
                    {selectedMember.tags && selectedMember.tags.split(',').map((tag, index) => (
                      <span 
                        key={index}
                        className="badge bg-secondary me-1"
                      >
                        {t(`members.tags.${tag.trim()}`)}
                      </span>
                    ))}
                  </div>
              )}
                {selectedMember.comentarios && selectedMember.comentarios.trim() && (
                  <div>
                    <strong>{t('members.comentarios')}:</strong> {selectedMember.comentarios}
                  </div>
                )}
                {selectedMember.signature && selectedMember.signature.trim() && (
                  <div className='text-center'>
                    <img src={selectedMember.signature} width={'auto'} height={'100px'} alt="member signature" />
                  </div>
                )}

              </Row>
            </Container>
          )}
        </Modal.Body>
      </Modal>


      {/* Editable Member Details Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('members.edit_details')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMember && (
            <Container>
              <Row className='mb-4'>
                <Col>
                  <Form>
                    {/* Non-editable fields */}
                    <Row>
                      <Col sm="3">
                        <Form.Group as={Row} className='mb-3' controlId="memberId">
                          <Form.Label column sm="6">{t('members.id')}</Form.Label>
                          <Col sm="6">
                            <Form.Control type="text" value={selectedMember.id} plaintext readOnly />
                          </Col>
                        </Form.Group>
                      </Col><Col sm="8">
                        {/* Editable fields */}
                        <Form.Group as={Row} controlId="numSocio">
                          <Form.Label column sm="4">{t('members.num_socio')}</Form.Label>
                          <Col sm="4">
                            <Form.Control
                              type="text"
                              name="numSocio"
                              value={selectedMember.numSocio}
                              onChange={handleInputChange}
                            />
                          </Col>
                          {!selectedMember.numSocio&&(
                          <Col sm="4" className='small align-middle justify-content-center align-self-center d-flex'>Sug.: {numSocioSuggestion}</Col>
                        )}
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Form.Group controlId="registrationDate">
                    <Form.Label>{t('members.registration_date')}</Form.Label>
                    <Form.Control type="text" value={selectedMember.registrationDate} readOnly />
                  </Form.Group>*/}

                  <Row>
                      <Col sm="6">
                    <Form.Group controlId="meetupID" className='mb-2'>
                      <Form.Label>{t('members.meetupID')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="meetupID"
                        value={selectedMember.meetupID}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    </Col><Col sm="6">
                    <Form.Group controlId="category" className='mb-2'>
                      <Form.Label>{t('members.category')}</Form.Label>
                      <select className="form-select" id="category" name="category" value={selectedMember.category} 
                      onChange={handleInputChange} >
                        <option value="Socio numerario">{t('members.categories.Socio numerario')}</option>
                        <option value="Simpatizante">{t('members.categories.Simpatizante')}</option>
                        <option value="Socio becado">{t('members.categories.Socio becado')}</option>
                        <option value="Socio espectador">{t('members.categories.Socio espectador')}</option>
                        <option value="No socio">{t('members.categories.No socio')}</option>
                      </select>
                    </Form.Group>
                    </Col>
                    </Row>

                    <Form.Group controlId="alias" className='mb-2'>
                      <Form.Label>{t('members.alias')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="alias"
                        value={selectedMember.alias}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="firstname" className='mb-2'>
                      <Form.Label>{t('members.firstname')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstname"
                        value={selectedMember.firstname}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="lastname" className='mb-2'>
                      <Form.Label>{t('members.lastname')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastname"
                        value={selectedMember.lastname}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="birthDate" className='mb-2'>
                      <Form.Label>{t('members.birth_date')}</Form.Label>
                      <Form.Control
                        type="date"
                        name="birthDate"
                        value={selectedMember.birthDate ? new Date(selectedMember.birthDate).toISOString().split('T')[0] : ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="gender" className='mb-2'>
                      <Form.Label>{t('members.gender')}</Form.Label>
                      <Form.Control
                        as="select"
                        name="gender"
                        value={selectedMember.gender}
                        onChange={handleInputChange}
                      >
                        <option value="">{t('members.select_gender')}</option>
                        <option value="H">{t('members.male')}</option>
                        <option value="M">{t('members.female')}</option>
                        <option value="O">{t('members.other')}</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="email" className='mb-2'>
                      <Form.Label>{t('members.email')}</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={selectedMember.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="dni" className='mb-2'>
                      <Form.Label>{t('members.dni')}</Form.Label>
                      <Form.Control
                        type="dni"
                        name="DNI"
                        value={selectedMember.DNI}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="telefono">
                      <Form.Label>{t('members.telefono')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="telefono"
                        value={selectedMember.telefono}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    
                  </Form>
                </Col>
                <Col>
                  <div>
                    <img
                      src={`https://pianomeetups.com/members/photos/${('000' + selectedMember.numSocio).slice(-3)}.jpg`}
                      style={{ width: '100%', minHeight: '200px' }}
                      alt="member"
                    />
                    <Form.Group controlId="formImage">
                                  <Form.Label>{t('upload.choose_image')}</Form.Label>
                                  <Form.Control type="file" name="image" value={selectedMember.imageFile} onChange={handlePhotoUpdate} accept=".jpg,.jpeg" />
                      </Form.Group>
                  </div>
                  <Form.Group controlId="address" className='mb-2'>
                      <Form.Label>{t('members.address')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={selectedMember.address}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="city" className='mb-2'>
                      <Form.Label>{t('members.city')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={selectedMember.city}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="province" className='mb-2'>
                      <Form.Label>{t('members.province')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="province"
                        value={selectedMember.province}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="postalcode" className='mb-2'>
                      <Form.Label>{t('members.postalcode')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="postalcode"
                        value={selectedMember.postalcode}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                </Col>
                </Row>
                <Row>
                  <Col>
                                <Form.Group controlId="tags" className='mb-2'>
                                  <Form.Label>{t('members.tags.label')}</Form.Label>
                                  <div className="d-flex flex-wrap gap-2 mb-2">
                                    {selectedMember.tags && selectedMember.tags.split(',').map((tag, index) => (
                                      <span 
                                        key={index}
                                        className="badge bg-secondary d-flex align-items-center"
                                      >
                                        {t(`members.tags.${tag.trim()}`)}
                                        <button
                                          type="button"
                                          className="btn-close btn-close-white ms-2"
                                          style={{ fontSize: '0.5rem' }}
                                          onClick={() => {
                                            const updatedTags = selectedMember.tags
                                              .split(',')
                                              .filter((t) => t.trim() !== tag.trim())
                                              .join(',');
                                            setSelectedMember({
                                              ...selectedMember,
                                              tags: updatedTags,
                                            });
                                          }}
                                        />
                                      </span>
                                    ))}
                                  </div>
                                  <Form.Select
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        const currentTags = selectedMember.tags ? selectedMember.tags.split(',') : [];
                                        if (!currentTags.includes(e.target.value)) {
                                          const updatedTags = [...currentTags, e.target.value]
                                            .filter(Boolean)
                                            .join(',');
                                          setSelectedMember({
                                            ...selectedMember,
                                            tags: updatedTags,
                                          });
                                        }
                                        e.target.value = ''; // Reset select after adding
                                      }
                                    }}
                                  >
                                    <option value="">{t('members.tags.select_tag')}</option>
                                    <option value="publico">{t('members.tags.publico')}</option>
                                    <option value="voysitoco">{t('members.tags.voysitoco')}</option>
                                    <option value="no_contactar">{t('members.tags.no_contactar')}</option>
                                    <option value="excedencia">{t('members.tags.excedencia')}</option>
                                    <option value="austero">{t('members.tags.austero')}</option>
                                    <option value="conflictivo">{t('members.tags.conflictivo')}</option>
                                    <option value="promotor">{t('members.tags.promotor')}</option>
                                    <option value="overtimer">{t('members.tags.overtimer')}</option>
                                  </Form.Select>
                                </Form.Group>
                                <Form.Group controlId="comentarios" className='mb-2'>
                    <Form.Label>{t('members.comentarios')}</Form.Label><br />
                    <FormControl as="textarea" rows={3}
                      type="text"
                      name="comentarios"
                      value={selectedMember.comentarios}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  {selectedMember.signature && selectedMember.signature.trim() && (
                    <Form.Group controlId="signature">
                      <Form.Label>{t('members.signature')}</Form.Label><br />
                      <div className='text-center'>
                        <img src={selectedMember.signature} width={'auto'} height={'100px'} alt="member signature" />
                      </div>
                    </Form.Group>

                  )}
                </Col>
              </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            {t('members.cancel')}
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {t('members.save_changes')}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default MembersReport;
