import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Alert, Row } from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';
// Add import for navigation and tooltip
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// Add to imports
import { ListGroup } from 'react-bootstrap';

const ManualPaymentForm = ({ initialData = {}, onSuccess, inModal = false }) => {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        contact: initialData.contact || '',
        description: '',
        amount: initialData.amount ? 'custom' : '',
        customAmount: initialData.amount || '',
        eventId: initialData.eventId || '',
        memberId: initialData.memberId || '',
        reason: '',
        fee: initialData.fee || 0 // Add fee to formData state
    });

    useEffect(() => {
        if (initialData) {
            setFormData(prev => ({
                ...prev,
                contact: initialData.contact || prev.contact,
                eventId: initialData.eventId || prev.eventId,
                memberId: initialData.memberId || prev.memberId,
                customAmount: initialData.amount || prev.customAmount,
                amount: initialData.amount ? 'custom' : prev.amount
            }));
            
            // If we have a memberId, fetch member details
            if (initialData.memberId) {
                fetchMemberDetails(initialData.memberId);
            }
        }
    }, [initialData]);

    const fetchMemberDetails = async (memberId) => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_MEMBERS_API}/${memberId}`);
            const member = response.data;
            setFormData(prev => ({
                ...prev,
                member: `${member.firstname} ${member.lastname}`,
                contact: prev.contact || member.telefono || member.email || ''
            }));
        } catch (error) {
            console.error('Error fetching member details:', error);
        }
    };

    const reasons = [
        'showcase','showcase-socio','showcase-junior',
        'entrada','entrada-junior','entrada-puerta',
        'invitado',
        'otro'
    ];

    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const eventsAPI = process.env.REACT_APP_EVENTS_API;
    const paymentsAPI=process.env.REACT_APP_PAYMENTS_API;
    const transactionsAPI=process.env.REACT_APP_TRANSACTIONS_API;
    const [transactions, setTransactions] = useState([]);
    const [memberSearchResults, setMemberSearchResults] = useState([]);
    const [showMemberResults, setShowMemberResults] = useState(false);

    const handleMemberClick = (memberId) => {
        if (memberId) {
            navigate(`/dashboard/members/${memberId}/payments`);
        }
    };

    const handleMemberChange = async (e) => {
        const { value } = e.target;
        setFormData(prev => ({ ...prev, member: value }));
        
        if (value.length >= 2) {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_MEMBERS_API}/search`, {
                    params: { query: value, limit: 5 }
                });
                setMemberSearchResults(response.data);
                setShowMemberResults(true);
            } catch (error) {
                console.error('Error searching members:', error);
            }
        } else {
            setMemberSearchResults([]);
            setShowMemberResults(false);
        }
    };

    const handleMemberSelect = (member) => {
        setFormData(prev => ({
            ...prev,
            member: `${member.firstname} ${member.lastname}`,
            contact: member.telefono || member.email || ''
        }));
        setShowMemberResults(false);
    };
    useEffect(() => {
      const fetchEvents = async () => {
        try {
          const response = await axiosInstance.get(`${eventsAPI}/events`, { params: { ordered: 'true' } });
          if (Array.isArray(response.data.events)) {
            setEvents(response.data.events);
          } else {
            console.error("Unexpected API response format:", response.data);
          }
        } catch (error) {
          console.error(t('prepare_event.error_fetching_events'), error.message);
        }
      };
      fetchEvents();

      // Initial fetch of transactions
      fetchTransactions();
      setInterval(fetchTransactions, 15000);
    }, [eventsAPI, t]);

    const fetchTransactions = async () => {
        try {
            const response = await axiosInstance.get(`${transactionsAPI}/recent`, { params: { limit: 10, type:'paygold' } });
            setTransactions(response.data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    // Remove the direct call to fetchTransactions here
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(false);

        try {
            const finalAmount = formData.amount === 'custom' 
                ? formData.customAmount 
                : formData.amount;

            const response = await axiosInstance.post(`${paymentsAPI}/request-gateway-payment`, {
                contact: formData.contact,
                description: formData.description,
                amount: parseFloat(finalAmount),
                fee: parseFloat(formData.fee), // Include fee in the submission
                eventId: formData.reason === 'otro' ? null : formData.eventId,
                reason: formData.reason
            });

            if (response.data.paymentUrl) {
                window.location.href = response.data.paymentUrl;
            } else if (response.data.paymentDetails) {
                setTransactions(prev => [response.data.paymentDetails, ...prev.slice(0, 9)]);
                setSuccess(true);
                // Call onSuccess callback if provided
                if (onSuccess && typeof onSuccess === 'function') {
                    onSuccess(response.data.paymentDetails);
                }
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to create payment');
        }
    };

    // Add this function to get default description based on reason
    const getDefaultDescription = (reason) => {
        switch (reason) {
            case 'showcase':
                return `Pago showcase del evento _event_`;
            case 'showcase-socio':
                return 'Pago showcase del  _event_ (Socio)';
            case 'showcase-junior':
                return 'Pago showcase del evento _event_ ';
            case 'entrada':
                return 'Pago entrada del evento _event_';
            case 'entrada-junior':
                return 'Pago entrada del evento _event_';
            case 'invitado':
                return 'Pago entrada invitado del evento _event_';
            case 'entrada-puerta':
                return 'Pago entrada en puerta';
            default:
                return '';
        }
    };

    // Update handleInputChange to set default description when reason changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => {
            const updates = {
                ...prev,
                [name]: value
            };
            
            // Set default description when reason changes
            if (name === 'reason') {
                updates.description = getDefaultDescription(value);
                // Automatically set amount to 'custom' when reason is 'otro'
                if (value === 'otro') {
                    updates.amount = 'custom';
                }
            }
            
            // Update description when event is selected
            if (name === 'eventId' && prev.reason) {
                const selectedEvent = events.find(event => event.id.toString() === value);
                if (selectedEvent) {
                    const baseDescription = getDefaultDescription(prev.reason);
                    updates.description = baseDescription.replace('_event_', selectedEvent.name);
                }
            }
            
            return updates;
        });
    };

    // Add this function to get amounts based on reason
    const getAmountOptions = (reason) => {
        switch (reason) {
            case 'showcase':
                return [
                    { value: '10', label: '10€ (Socio)' },
                    { value: '15', label: '15€ (simpatizante)' },
                    { value: 'custom', label: t('payments.custom_amount_label') }
                ];
            case 'showcase-socio':
                return [
                    { value: '10', label: '10€ (Socio)' },
                    { value: 'custom', label:  t('payments.custom_amount_label') }
                ];
            case 'showcase-junior':
                return [
                    { value: '10', label: '10€ (Regular)' },
                    { value: '20', label: '20€ (Special)' },
                    { value: 'custom', label:  t('payments.custom_amount_label') }
                ];
            case 'entrada':
                return [
                    { value: '6', label: '6€ (Basic)' },
                    { value: '9', label: '9€ (Regular)' },
                    { value: '15', label: '15€ (Special)' },
                    { value: 'custom', label:  t('payments.custom_amount_label') }
                ];
            case 'entrada-junior':
                return [
                    { value: '6', label: '6€ (Junior)' },
                    { value: 'custom', label:  t('payments.custom_amount_label') }
                ];
            case 'invitado':
                return [
                    { value: '6', label: '6€ (Basic)' },
                    { value: '9', label: '9€ (Regular)' },
                    { value: 'custom', label:  t('payments.custom_amount_label') }
                ];
            case 'entrada-puerta':
                return [
                    { value: '6', label: '6€ (Basic)' },
                    { value: '9', label: '9€ (Regular)' },
                    { value: '15', label: '15€ (Special)' },
                    { value: 'custom', label:  t('payments.custom_amount_label') }
                ];
            default:
                return [
                    { value: 'custom', label:  t('payments.custom_amount_label')}
                ];
        }
    };

    return (
        <Container className={inModal ? 'p-0' : 'mt-4'}>
            <Card className="mb-4">
               {!inModal && ( <Card.Header>
                    <h3>{t('payments.title')}</h3>
                </Card.Header> )}
                <Card.Body className="mt-4">
                    {error && <Alert variant="danger" dismissible="true">{error}</Alert>}
                    {success && <Alert variant="success" dismissible="true">{t('payments.success_message')}</Alert>}

                    <Form onSubmit={handleSubmit}>
                        <div className="row">
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>{t('payments.reason_label')}</Form.Label>
                                <Form.Select
                                    name="reason"
                                    value={formData.reason}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">{t('payments.select_reason')}</option>
                                    {reasons.map(reason => (
                                        <option key={reason} value={reason}>
                                            {t(`payments.reasons.${reason}`)}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            {formData.reason !== 'otro' && (
                                <Form.Group className="mb-3 col-md-6">
                                    <Form.Label>{t('payments.event_label')}</Form.Label>
                                    <Form.Select
                                        name="eventId"
                                        value={formData.eventId}
                                        onChange={handleInputChange}
                                        required={formData.reason !== 'otro'}
                                    >
                                        <option value="">{t('payments.select_event')}</option>
                                        {events.map(event => (
                                            <option key={event.id} value={event.id}>
                                                {event.name} - {new Date(event.date).toLocaleDateString('es-ES')}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            )}
                        </div>

                        <div className="row">
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>{t('payments.contact_label')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={handleInputChange}
                                    required
                                    placeholder={t('payments.contact_placeholder')}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 col-md-6 position-relative">
                                <Form.Label>{t('payments.search_member_label')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="member"
                                    value={formData.member}
                                    onChange={handleMemberChange}
                                    placeholder={t('payments.search_member_placeholder')}
                                    autoComplete="off"
                                />
                                {showMemberResults && memberSearchResults.length > 0 && (
                                    <ListGroup 
                                        className="position-absolute w-100 z-1000" 
                                        style={{ maxHeight: '200px', overflowY: 'auto' }}
                                    >
                                        {memberSearchResults.map(member => (
                                            <ListGroup.Item 
                                                key={member.id}
                                                action
                                                onClick={() => handleMemberSelect(member)}
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <span>{member.firstname} {member.lastname}</span>
                                                <small className="text-muted">
                                                    {member.alias ? `@${member.alias}` : ''}
                                                </small>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                            </Form.Group>
                        </div>

                        <Form.Group className="mb-3">
                            <Form.Label>{t('payments.description_label')}</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                                rows={3}
                            />
                        </Form.Group>
                                <Row>
                        <Form.Group className="mb-3 col-6">
                            <Form.Label>{t('payments.amount_label')}</Form.Label>
                            <Form.Select
                                name="amount"
                                value={formData.amount}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">{t('payments.select_amount')}</option>
                                {getAmountOptions(formData.reason).map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        {formData.amount === 'custom' && (
                            <Form.Group className="mb-3">
                                <Form.Label>{t('payments.custom_amount_label')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="customAmount"
                                    value={formData.customAmount}
                                    onChange={handleInputChange}
                                    required
                                    min="0"
                                    step="0.01"
                                />
                            </Form.Group>
                        )}

                        {/* Add fee input field */}
                        <Form.Group className="mb-3  col-6">
                            <Form.Label>{t('payments.fee_label')}</Form.Label>
                            <Form.Control
                                type="number"
                                name="fee"
                                value={formData.fee}
                                onChange={handleInputChange}
                                required
                                min="0"
                                step="0.10"
                            />
                        </Form.Group>
                        </Row>
                        <Button type="submit" variant="primary">
                            {t('payments.submit_button')}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        {!inModal && (
            <Card>
                <Card.Header>
                    <h3>{t('payments.recent_transactions')}</h3>
                </Card.Header>
                <Card.Body>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                 <tr>
                                    <th>{t('payments.table.date')}</th>
                                    <th>{t('payments.table.order_id')}</th>
                                    <th>{t('payments.table.description')}</th>
                                    <th>{t('payments.table.amount')}</th>
                                    <th>{t('payments.table.status')}</th>
                                    <th>{t('payments.table.contact')}</th>
                                    <th>{t('payments.table.member')}</th>
                                    <th>{t('payments.table.issuer')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map(transaction => (
                                    <tr key={transaction.id}>
                                        <td>{new Date(transaction.created_at).toLocaleString()}</td>
                                        <td>{transaction.order_id}</td>
                                        <td>{transaction.description}</td>
                                        <td>{parseFloat(transaction.amount).toFixed(2)}€</td>
                                        <td>
                                            <span className={`badge bg-${
                                                transaction.transaction_status === 'COMPLETED' ? 'success' :
                                                transaction.transaction_status === 'PENDING' ? 'warning' : 'danger'
                                            }`}>
                                                {transaction.transaction_status}
                                            </span>
                                        </td>
                                        <td>{transaction.contact}</td>
                                        <td>
                                            {transaction.memberId && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {transaction.member_name} {transaction.member_lastname}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span 
                                                        className="text-primary" 
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleMemberClick(transaction.memberId)}
                                                    >
                                                        {transaction.member_alias || `#${transaction.memberId}`}
                                                    </span>
                                                </OverlayTrigger>
                                            )}
                                        </td>
                                        <td>
                                            {transaction.issuer}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card>
        )}
        </Container>
    );
};

export default ManualPaymentForm;