import React, { Fragment, useEffect } from 'react';
import { useSubheader } from '../components/SubheaderContext'; 
import QRCode from 'react-qr-code';
import { useUser } from '../store/hooks';

const Enlaces = () => {
  const { setLinks } = useSubheader();
  const user = useUser(); // Get user information from context

  useEffect(() => {
    setLinks([
      { href: '#form', i18n: 'links.form' },
      { href: '#landing', i18n: 'links.landing' },
      { href: '#whatsapp', i18n: 'links.whatstapp'}
    ]);

    // Cleanup on unmount
    return () => setLinks([]);
  }, [setLinks]);

  return (
    <Fragment>
      <style>
        h2{"{color:#E42021}"}
      </style>
      {/* Page Title */}
      <div className="page-title" data-aos="fade">
        <div className="container">
          <h1>Enlaces públicos</h1>
        </div>
      </div>{/* End Page Title */}
      {/* Formulario */}
      <section id="form" className="second-section section">
        <div className="container section-title" data-aos="fade-up">
          <span>Miembros<br /></span>
          <h2>Nuevo miembro</h2>
          <p>El siguiente enlace/QR sirve para que los nuevos miembros puedan rellenar
            el formulario de forma online.
          </p>
          <br/>
          <QRCode value='https://manager.pianomeetups.com/new_member' />
          <br/>
          {user? (<a href="https://manager.pianomeetups.com/new_member">Nuevo miembro</a>) : "" }
          
        </div>
        <div className="container text-center" data-aos="fade-up">
          <p>El formulario no da de alta directamente al usuario, pero si los crea en una base de
            datos temporal para facilitar su creación posterior.
          </p>
        </div>
      </section>{/* End Formulario */}

      {/* Starter Section */}
      <section id="landing" className="starter-section section">
        <div className="container section-title" data-aos="fade-up">
          <span>Landing<br /></span>
          <h2>Enlaces para la pagina resumen</h2>
          <p>El siguiente QR te lleva a la página "landing" con el resumen del proyecto y lo que hacemos:<br/>
           <a href="https://pianomeetups.com/landing">https://pianomeetups.com/landing</a></p>
           <br/>
          <QRCode value='https://pianomeetups.com/landing' />
        </div>
        <div className="container text-center" data-aos="fade-up">
          <p>Enseña este código QR para que los asistentes puedan tener la info resumida del proyecto.</p>
        </div>
      </section>{/* End Starter Section */}

  {/* Starter Section */}
      <section id="whatsapp" className="starter-section section">
        <div className="container section-title" data-aos="fade-up">
          <span>Whatsapp<br /></span>
          <h2>Enlaces para el grupo de WhatsApp</h2>
          <p>El siguiente QR mete en el grupo de whatsapp:<br/>
           <a href="https://chat.whatsapp.com/H2CHWX3paiNLeoaX3Olsf4">Acceso al grupo oficial</a></p>
           <br/>
          <QRCode value='https://chat.whatsapp.com/H2CHWX3paiNLeoaX3Olsf4' />
        </div>
        <div className="container text-center" data-aos="fade-up">
          <p>Enseña este código QR para que los asistentes puedan tener la info resumida del proyecto.</p>
        </div>
      </section>{/* End Starter Section */}

    </Fragment>
  );
};

export default Enlaces;