import React, { useEffect, useState, Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import languages from '../config/languages';
import headerConfig from '../config/header.json';
import Subheader from './Subheader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useAuthenticated, useUser } from '../store/hooks';
import { useDispatch } from 'react-redux';
import { clearTokens } from '../store/setting/tokenSlice';
import { clearUser, setAuthenticated } from '../store/setting/userSlice';
import UserProfilePopup from './UserProfilePopup';

const Header = ({
  onLoginRegister = () => { },
  showTopBar = true,
  showLogo = true,
  showSiteName = true
}) => {
  const { t, i18n } = useTranslation();
  const [socialLinks, setSocialLinks] = useState([]);
  const [logoSrc, setLogoSrc] = useState("");
  const [navLinks, setNavLinks] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser(); // Get user information from context
  const dispatch = useDispatch();  // to dispatch actions to Redux store
  const currentLanguage = languages.find(lang => lang.code === i18n.language);
  const isAuthenticated=useAuthenticated();

  const [showUserProfilePopup, setShowUserProfilePopup] = useState(false);
  const handleShowUserProfilePopup = () => setShowUserProfilePopup(true);
  const handleCloseUserProfilePopup = () => setShowUserProfilePopup(false);
    
  const handleSaveUserProfile = (updatedData) => {
    console.log('Saved Data:', updatedData);
    // Handle the save action (e.g., send data to the backend)
    handleCloseUserProfilePopup();
  };


  useEffect(() => {
    setSocialLinks(headerConfig.socialLinks);
    setLogoSrc(headerConfig.logoSrc);
    setNavLinks(headerConfig.navLinks);

    const scrollTop = document.querySelector('.scroll-top');

    const toggleScrollTop = () => {
      if (scrollTop) {
        window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
      }
    };

    const toggleScrolled = () => {
      const selectBody = document.querySelector('body');
      const selectHeader = document.querySelector('#header');
      if (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
      window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
    };

    const navmenuScrollspy = () => {
      const navmenulinks = document.querySelectorAll('#navmenu a');
      navmenulinks.forEach(navmenulink => {
        if (!navmenulink.hash) return;
        let section = document.querySelector(navmenulink.hash);
        if (!section) return;
        let position = window.scrollY + 200;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          document.querySelectorAll('#navmenu a.active').forEach(link => link.classList.remove('active'));
          navmenulink.classList.add('active');
        } else {
          navmenulink.classList.remove('active');
        }
      });
    };

    const setMenuActive = () => {
      const currentPath = location.pathname;
      const navmenulinks = document.querySelectorAll('#navmenu a');
      navmenulinks.forEach(navmenulink => {
        if (navmenulink.pathname === currentPath || navmenulink.pathname.includes (currentPath) ) 
        {
          navmenulink.classList.add('active');
        } else {
          navmenulink.classList.remove('active');
        }
      });
    };

    const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
    const mobileNavToogle = () => {
      document.querySelector('body').classList.toggle('mobile-nav-active');
      mobileNavToggleBtn.classList.toggle('bi-list');
      mobileNavToggleBtn.classList.toggle('bi-x');
    };

    const mobileLangToggleBtn = document.querySelector('.mobile-lang-toggle');
    const mobileLangToogle = () => {
      document.querySelector('body').classList.toggle('mobile-lang-active');
    };

    if (mobileNavToggleBtn) {
      mobileNavToggleBtn.addEventListener('click', mobileNavToogle);
    }
    if (mobileLangToggleBtn) {
      mobileLangToggleBtn.addEventListener('click', mobileLangToogle);
    }

    document.querySelectorAll('.langmenu a').forEach(langmenu => {
      langmenu.addEventListener('click', () => {
        if (document.querySelector('.mobile-lang-active')) {
          mobileLangToogle();
        }
      });
    });

    document.querySelectorAll('.navmenu a').forEach(navmenu => {
      navmenu.addEventListener('click', (e) => {
        if (document.querySelector('.mobile-nav-active')) {
          document.querySelector('body').classList.toggle('mobile-nav-active');
          document.querySelector('.mobile-nav-toggle').classList.toggle('bi-list');
          document.querySelector('.mobile-nav-toggle').classList.toggle('bi-x');
        }
      });
    });

    document.querySelectorAll('#navmenu .toggle-dropdown').forEach(navmenu => {
      navmenu.addEventListener('click', function (e) {
        e.preventDefault();
        this.parentNode.classList.toggle('active');
        this.parentNode.nextElementSibling.classList.toggle('dropdown-active');
        e.stopImmediatePropagation();
      });
    });

    const mobileNavHidePopup = () => {
      document.querySelectorAll('.navmenu a').forEach(navmenu => {
        navmenu.addEventListener('click', (e) => {
          if (document.querySelector('.mobile-nav-active')) {
            document.querySelector('body').classList.toggle('mobile-nav-active');
            document.querySelector('.mobile-nav-toggle').classList.toggle('bi-list');
            document.querySelector('.mobile-nav-toggle').classList.toggle('bi-x');
          }
        });
      });
    };

    window.addEventListener('load', toggleScrolled);
    document.addEventListener('scroll', toggleScrolled);
    window.addEventListener('load', navmenuScrollspy);
    document.addEventListener('scroll', navmenuScrollspy);
    window.addEventListener('scroll', toggleScrollTop);
    window.addEventListener('load', setMenuActive);
    document.addEventListener('scroll', setMenuActive);

    window.addEventListener('load', mobileNavHidePopup);
    document.addEventListener('scroll', mobileNavHidePopup);
    return () => {
      document.removeEventListener('scroll', toggleScrolled);
      window.removeEventListener('load', toggleScrolled);
      window.removeEventListener('scroll', toggleScrollTop);
      window.removeEventListener('load', navmenuScrollspy);
      document.removeEventListener('scroll', navmenuScrollspy);
      document.removeEventListener('scroll', setMenuActive);

      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.removeEventListener('click', mobileNavToogle);
      }
      if (mobileLangToggleBtn) {
        mobileLangToggleBtn.removeEventListener('click', mobileLangToogle);
      }
      document.querySelectorAll('#navmenu a').forEach(navmenu => {
        navmenu.removeEventListener('click', mobileNavToogle);
      });
      document.querySelectorAll('#navmenu .toggle-dropdown').forEach(navmenu => {
        navmenu.removeEventListener('click', function (e) {
          e.preventDefault();
          this.parentNode.classList.toggle('active');
          this.parentNode.nextElementSibling.classList.toggle('dropdown-active');
          e.stopImmediatePropagation();
        });
      });
    };

  }, [location]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleLogout = () => {
    setAuthenticated(false);
    window.localStorage.setItem("auth state", JSON.stringify(false));
    window.localStorage.setItem("user state", null);
    dispatch(clearUser());
    dispatch(setAuthenticated(false));
    dispatch(clearTokens());
    navigate('/');
  };

  return (
    <Fragment >
      <header id="header" className="header fixed-top sticky-top">
        {showTopBar && (
          <div className="topbar d-flex align-items-center">
            <div className="container d-flex justify-content-center justify-content-md-between">
              <div className="contact-info d-flex align-items-center">
                <i className="bi bi-envelope d-flex align-items-center">
                  <a href={`mailto:${t('header.contactEmail')}`}>{t('header.contactEmail')}</a>
                </i>
                <i className="bi bi-phone d-flex align-items-center ms-4">
                  <span>{t('header.phone')}</span>
                </i>
              </div>
              <div className="social-links d-none d-md-flex align-items-center">
                {socialLinks.map((link, index) => (
                  <a key={index} href={link.url} className={link.platform}>
                    <i className={link.iconClass}></i>
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="branding d-flex align-items-center">
          <div className="container position-relative d-flex align-items-center justify-content-between">
            <Link to="#" className="logo d-flex align-items-center" onClick={(e) => { e.preventDefault(); }}>
              {showLogo && (<img src={logoSrc} alt="Logo" />)}
              {showSiteName && (<h1 className="sitename">{t('header.siteName')}</h1>)}
            </Link>

            <nav id="navmenu" className="navmenu">
              <ul>
                {navLinks.map((link, index) => {
                  if (link.visible === 'always' || (link.visible === 'authenticated' && isAuthenticated) || (link.visible === 'unauthenticated' && !isAuthenticated)) {
                    return (
                      <li key={index} className={link.subLinks ? "dropdown" : ""}>
                        <a href={link.href} className={location.pathname === link.href
                                          ||(link.href.length>1 && location.pathname.includes(link.href)) ? "active" : ""}>
                          {t(link.i18n)} {link.subLinks && <i className="bi bi-chevron-down toggle-dropdown"></i>}
                        </a>
                        {link.subLinks && (
                          <ul>
                            {link.subLinks.map((subLink, subIndex) => (
                              <li key={subIndex}><a href={subLink.href}>{t(subLink.i18n)}</a></li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
            </nav>

            <div className="auth-buttons">
              {isAuthenticated ? (
                <>
                  <ul className="nav-item dropdown pe-3">

                    <a className="nav-link nav-profile d-flex align-items-center pe-0" href="./#" data-bs-toggle="dropdown">
                      <span   className='rounded-circle'><FontAwesomeIcon icon={faUser}/></span>
                      <span className="d-none d-md-block dropdown-toggle ps-2">{user?.name}</span>
                    </a>{/*<!-- End Profile Iamge Icon -->*/}

                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                      <li className="dropdown-header">
                        <h6>{user.username}</h6>
                        <span>{user.role}</span>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <li>
                        <a className="dropdown-item d-flex align-items-center" href="./#" onClick={handleShowUserProfilePopup}>
                          <i className="bi bi-person"></i>
                          <span> &nbsp; My Profile</span>
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a onClick={handleLogout} className="dropdown-item d-flex align-items-center" href="./#">
                          <i className="bi bi-box-arrow-right"></i>
                          <span> &nbsp; Sign Out</span>
                        </a>
                      </li> 

                    </ul>{/*<!-- End Profile Dropdown Items -->*/}
                  </ul>
                </>
              ) : (
                <>
                  <button onClick={onLoginRegister} className="btn btn-primary">{t('header.loginRegister')}</button>
                </>
              )}
            </div>

            <div className="language-select langmenu">
              <div className="dropdown">
                <button className="btn dropdown-toggle mobile-lang-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                          {currentLanguage ? (
                          <img src={currentLanguage.flag} className="flag-icon" alt='language' />
                      ) : (
                          <img src='static/media/es.18bb17a85f90627dfeb114967fa69bbe.svg' className="flag-icon" alt='default language' />
                      )}
                </button>
                <ul className="dropdown-menu" aria-labelledby="languageDropdown">
                  {languages.filter(lang => lang.code !== i18n.language).map((lang, index) => (
                    <li key={index}>
                      <Link className="dropdown-item" to="#" onClick={() => changeLanguage(lang.code)}>
                        <img src={lang.flag} alt={`${lang.name} flag`} className="flag-icon" /> {lang.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          </div>
        </div>
        <Subheader /> {/* Include the Subheader component */}
      </header>

      <UserProfilePopup
        show={showUserProfilePopup}
        handleClose={handleCloseUserProfilePopup}
        user={user}
        handleSave={handleSaveUserProfile}
      />
      <button className="scroll-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>↑</button>
    </Fragment>
  );
};

export default Header;
