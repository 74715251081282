import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utilities/axios_instance';
import { useSelector } from 'react-redux';
import {Button} from 'react-bootstrap';
import { FaIdCard ,FaCheck, FaWhatsapp, FaTimes } from 'react-icons/fa';


const ShowcasesForm = () => {
    const { t } = useTranslation(); // i18n hook
    const [eventId, setEventId] = useState('');
    const [showcases, setShowcases] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    const [resultSuccess, setResultSuccess] = useState(false);
    const [ambiguousMembers, setAmbiguousMembers] = useState([]);
    const [updatedMembers, setUpdatedMembers] = useState([]);
    const [editableAliases, setEditableAliases] = useState({}); // Track new aliases
    const [selectedMembers, setSelectedMembers] = useState({}); // Track selected member IDs
    const [selectedData, setSelectedData] = useState({}); // Track selected showcase data info
    const [selectedMessage, setSelectedMessage]= useState({}); // Track selected status message 
    const [selectedMessageSuccess, setSelectedMessageSuccess]= useState({}); // Track selected status message 
    const apiUrl = process.env.REACT_APP_MEMBERS_API;
    const wabUrl = process.env.REACT_APP_MESSAGING_API;
    const isLoading = useSelector((state) => state.loading.isLoading);
    const [whatsappStatus, setWhatsappStatus] = useState({}); // Status tracking for WhatsApp messages
    


     // WhatsApp message handler
     const sendWhatsAppMessage = (userId) => {
        setWhatsappStatus((prev) => ({ ...prev, [userId]: 'loading' })); // Set loading state
        axiosInstance
            .post(`${wabUrl}/send_wab_showcase`, { userId })
            .then(() => {
                setWhatsappStatus((prev) => ({ ...prev, [userId]: 'success' })); // Mark as success
                setResultMessage(t('next_event.wab_ok'));
                setResultSuccess(false);
            })
            .catch(() => {
                setWhatsappStatus((prev) => ({ ...prev, [userId]: 'failure' })); // Mark as failure
                setResultMessage(t('next_event.wab_ko'));
                setResultSuccess(true);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!showcases || !eventId) {
            setResultMessage(t('showcases.pleaseProvideBothEventIdAndShowcases'));
            setResultSuccess(false);
            return;
        }
        setAmbiguousMembers();

        const formData = {
            eventId,
            showcases
        };

        try {
            const response = await axiosInstance.post(`${apiUrl}/submit_showcases`, formData);
            if (response.data.success) {
                setResultMessage(t('showcases.successfullySubmitted'));
                setResultSuccess(true);
                if (response.data.error) {
                    setResultMessage(`${t('showcases.partialError')}: ${response.data.message}`);
                    setAmbiguousMembers(response.data.ambiguousMembers);
                    // Initialize editable aliases and selected members
                    const aliases = {};
                    const members = {};
                    const showcaseData = {};
                    const selectedMessageSuccess={};
                    response.data.ambiguousMembers.forEach((member, idx) => {
                        aliases[idx] = member.alias;
                        if (member.found> 0) {
                           // members[idx] = member.members[0].id;
                        }
                        showcaseData[idx]=member.data;
                        selectedMessage[idx]= member.found === 0 ? t('showcases.memberNotFound') : t('showcases.ambiguousMemberFound')
                        selectedMessageSuccess[idx]=false
                    });
                    setEditableAliases(aliases);
                    setSelectedMembers(members);
                    setSelectedData(showcaseData);
                    setSelectedMessage(selectedMessage);
                    setSelectedMessageSuccess(selectedMessageSuccess);
                }
                setUpdatedMembers(response.data.updatedMembers);
            } else {
                setResultMessage(`${t('showcases.errorSubmitting')}: ${response.data.message}`);
                setResultSuccess(false);
            }
        } catch (error) {
            console.error('Error during submission:', error);
            setResultMessage(`${t('showcases.errorSubmitting')}: ${error.message}`);
            setResultSuccess(false);
        } finally {
        }
    };

    const handleRetryAlias = async (rowAlias, idx,ev) => {
        
        try {
            const retryData = {
                eventId,
                alias: editableAliases[idx],
                showcase: selectedData[idx]
            };
            console.log("new alias "+ retryData.alias);
            console.log("showcase data "+retryData.showcase);
            const retryResponse = await axiosInstance.post(`${apiUrl}/submit_showcase`, retryData);
            if (retryResponse.data.success) {
                setResultMessage(`${t('showcases.rowSuccess')} - ${t('showcases.successfullySubmittedForAlias', { alias: rowAlias })}`);
                ev.target.disabled=true;
                selectedMessageSuccess[idx]=true;
                updatedMembers.push(retryResponse.data.member)

            } else {
                setResultMessage(`${t('showcases.errorRetryingForAlias', { alias: rowAlias })}: ${retryResponse.data.message}`);
                selectedMessageSuccess[idx]=false;
            }
            selectedMessage[idx]=t(retryResponse.data.message);
        } catch (error) {
            console.error('Error during retry:', error);
            setResultMessage(`${t('showcases.errorRetryingForAlias', { alias: rowAlias })}: ${error.message}`);
            selectedMessage[idx]="error";
            selectedMessageSuccess[idx]=false;
        }
    };

    const handleRetryMember = async (memberAlias, idx,ev) => {
        try {
            const retryData = {
                eventId,
                alias:  selectedMembers[idx],
                showcase: selectedData[idx]
            };
            const retryResponse = await axiosInstance.post(`${apiUrl}/submit_showcase`, retryData);
         
            if (retryResponse.data.success) {
                setResultMessage(`${t('showcases.rowSuccess')} - ${t('showcases.successfullySubmittedForAlias', { alias: selectedMembers[idx] })}`);
                ev.target.disabled=true;
                selectedMessageSuccess[idx]=true;
                updatedMembers.push(retryResponse.data.member)

            } else {
                setResultMessage(`${t('showcases.errorRetryingForAlias', { alias: selectedMembers[idx] })}: ${retryResponse.data.message}`);
                selectedMessageSuccess[idx]=false;
            }
            selectedMessage[idx]=t(retryResponse.data.message);
        } catch (error) {
            console.error('Error during retry:', error);
            setResultMessage(`${t('showcases.errorRetryingForAlias', { alias: selectedMembers[idx] })}: ${error.message}`);
            selectedMessage[idx]="error";
            selectedMessageSuccess[idx]=false;
        }
    };
    
 
    return (
         
        <Fragment>
            {resultMessage && (
                <div className={`alert ${resultSuccess ? 'alert-success' : 'alert-danger'}`} role="alert">
                    <pre>{resultMessage}</pre>
                </div>
            )}
            <div className="container">
                <h1>{t('showcases.showcaseTitle')}</h1>

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="eventId">{t('showcases.eventIdLabel')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="eventId"
                            value={eventId}
                            onChange={(e) => setEventId(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="showcases">{t('showcases.showcaseInputLabel')}</label>
                        <textarea
                            className="form-control"
                            id="showcases"
                            rows="5"
                            value={showcases}
                            onChange={(e) => setShowcases(e.target.value)}
                            required
                        ></textarea>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        {isLoading  ? t('showcases.loading') : t('showcases.submit')}
                    </button>
                </form>

                {/* If there are ambiguous members, display them */}
                {ambiguousMembers?.length > 0 && (
                    <div className="mt-4">
                        <h2>{t('showcases.ambiguousMembersTitle')}</h2>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{t('showcases.current_alias')}</th>
                                    <th>{t('showcases.new_alias')}</th>
                                    <th>{t('showcases.action')}</th>
                                    <th>{t('showcases.retry_result')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ambiguousMembers.map((member, idx) => (
                                    <tr key={idx}>
                                        <td>{member.alias}</td>
                                        <td>
                                            {member.found === 0 ? (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editableAliases[idx]}
                                                    onChange={(e) => setEditableAliases(prev => ({ ...prev, [idx]: e.target.value }))}
                                                />
                                            ) : (
                                                <select
                                                    className="form-control"
                                                    value={selectedMembers[idx] || ''}
                                                    onChange={(e) => setSelectedMembers(prev => ({ ...prev, [idx]: e.target.value }))}
                                                >
                                                    <option value="">{t('showcases.selectMember')}</option>
                                                    {member.members.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {`${option.id}: ${option.alias} (${option.firstname} ${option.lastname})`}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </td>
                                        <td>
                                            {member.found === 0 ? (
                                                <button
                                                    className="btn btn-warning"
                                                    onClick={(ev) => handleRetryAlias(member.alias, idx,ev)}
                                                >
                                                    {t('showcases.retry')}
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-warning"
                                                    onClick={(ev) => handleRetryMember(member.alias, idx,ev)}
                                                    disabled={!selectedMembers[idx]}
                                                >
                                                    {t('showcases.retry')}
                                                </button>
                                            )}
                                        </td>
                                        <td className={"result_"+selectedMessageSuccess[idx]}>
                                           {selectedMessage[idx]}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                 {/* If there are ambiguous members, display them */}
                 {updatedMembers?.length > 0 && (
                    <div className="mt-4">
                        <h2>{t('showcases.updatedMembersTitle')}</h2>
                        <table className="table table-bordered " style={{width:'100%',display:"block"}}>
                            <thead>
                                <tr>
                                    <th>{t('showcases.member_id')}</th>
                                    <th>{t('showcases.member_alias')}</th>
                                    <th>{t('showcases.num_socio')}</th>
                                    <th>{t('showcases.member_card')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {updatedMembers.map((item,idx) => (
                                    <tr key={idx}>
                                        <td>{item.member.id}</td>
                                        <td>{item.member.alias}</td>
                                        <td>{item.member.numSocio}</td>
                                        <td>
                                           <Button href={`https://pianomeetups.com/members/member.html?mid=mpm${item.member.numSocio}`} 
                                           target='_blank' rel='noreferrer'> <FaIdCard /></Button>
                                           <Button
                                                variant={ whatsappStatus[item.member.id] === 'failure'?'danger':'success'}   
                                                onClick={() => sendWhatsAppMessage(item.member.id)}
                                                disabled={whatsappStatus[item.member.id] === 'loading'|| whatsappStatus[item.member.id] === 'success' }
                                            >
                                                {whatsappStatus[item.member.id] === 'success' && <FaCheck />}
                                                {whatsappStatus[item.member.id] === 'failure' && <FaTimes />}
                                                {whatsappStatus[item.member.id] !== 'success' &&
                                                    whatsappStatus[item.member.id] !== 'failure' && <FaWhatsapp />}
                                            </Button>
                                        </td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ShowcasesForm;
