import React, { useState, useEffect } from 'react';
import { Table, Alert, Card, Row, Col, Badge, Form, Button, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faSearch, faSortUp, faSortDown, faSort, faMoneyBillWave, faFilter, faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { handleAxiosError } from '../../utilities/error_handler';
import { PaymentUtils } from '../../components/payments/PaymentUtils';
import TransactionDetail from '../../components/payments/TransactionDetail';
import TransactionEdit from '../../components/payments/TransactionEdit';


const TransactionsManager = () => {
  const { eventId: urlEventId,transactionId:urlTransactionId } = useParams(); // Get eventId from URL params
  const {formatDate, getTransactionStatusBadge} = PaymentUtils();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [summary, setSummary] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventId, setEventId] = useState('');
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const [filters, setFilters] = useState({
    type: '',
    reason: '',
    status: '',
    startDate: null,
    endDate: null
  });
  const [showFilters, setShowFilters] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const { t } = useTranslation();
  const paymentsAPI = process.env.REACT_APP_PAYMENTS_API;
  const transactionsAPI = process.env.REACT_APP_TRANSACTIONS_API;
  const eventsAPI = process.env.REACT_APP_EVENTS_API;

  // Fetch events for the dropdown
  useEffect(() => {
    fetchEvents();
  }, [eventsAPI]);

  // Set event ID from URL parameter if available
  useEffect(() => {
    if (urlEventId && events.length > 0) {
      setEventId(urlEventId);
      fetchTransactions(urlEventId);
    }
  }, [urlEventId, events]);

  // Add effect to handle transaction ID from URL
    useEffect(() => {
      if (urlTransactionId) {
        fetchTransactionById(urlTransactionId);
      }
    }, [urlTransactionId]);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${eventsAPI}/events?ordered=true`);
      setEvents(response.data.events);
    } catch (err) {
      handleAxiosError(err, setError, 'events', 'load');
    } finally {
      setLoading(false);
    }
  };

  // Add function to fetch transaction by ID
  const fetchTransactionById = async (id) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${transactionsAPI}${id}`);
      if (response.data) {
        setSelectedTransaction(response.data);
        setShowViewModal(true);
        
        // If transaction has an event ID, set it as the current filter
        if (response.data.eventId) {
          setEventId(response.data.eventId);
          fetchTransactions(response.data.eventId);
        }
      }
    } catch (err) {
      handleAxiosError(err, setError, 'transaction', 'retrieve');
    } finally {
      setLoading(false);
    }
  };

  // Fetch transactions
  const fetchTransactions = async (selectedEventId = '') => {
    setLoading(true);
    try {
      let url = `${transactionsAPI}/transactions`;

      // Build query parameters
      const params = new URLSearchParams();

      if (selectedEventId) {
        params.append('eventId', selectedEventId);
      }

      if (filters.type) {
        params.append('type', filters.type);
      }

      if (filters.reason) {
        params.append('reason', filters.reason);
      }

      if (filters.status) {
        params.append('status', filters.status);
      }

      if (filters.startDate) {
        params.append('startDate', filters.startDate.toISOString().split('T')[0]);
      }

      if (filters.endDate) {
        params.append('endDate', filters.endDate.toISOString().split('T')[0]);
      }

      // Add params to URL if any exist
      if (params.toString()) {
        url += `?${params.toString()}`;
      }

      const response = await axiosInstance.get(url);
      setTransactions(response.data.transactions);
      setFilteredTransactions(response.data.transactions);
      setSummary(response.data.summary);
      setError(null);
    } catch (err) {
      handleAxiosError(err, setError, 'transactions', 'retrieve');
      setTransactions([]);
      setFilteredTransactions([]);
      setSummary({});
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchTransactions();
  }, [transactionsAPI]);

  // Handle event selection
  const handleEventChange = (e) => {
    const selectedEventId = e.target.value;
    setEventId(selectedEventId);
    fetchTransactions(selectedEventId);
  };

  // Get the selected event name for the title
  const getSelectedEventName = () => {
    if (!eventId) return null;
    const selectedEvent = events.find(event => event.id.toString() === eventId.toString());
    return selectedEvent ? selectedEvent.name : null;
  };

  // Handle filter changes
  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Apply filters
  const applyFilters = () => {
    fetchTransactions(eventId);
  };


  // Save edited transaction
  const saveTransaction = async () => {
    try {
      setLoading(true);
      await axiosInstance.put(`${transactionsAPI}/${selectedTransaction.id}`, selectedTransaction);

      // Refresh the transactions list
      fetchTransactions(eventId);
      setShowEditModal(false);
      setSelectedTransaction(null);
    } catch (err) {
      handleAxiosError(err, setError, 'transaction', 'update');
    } finally {
      setLoading(false);
    }
  };

  // Reset filters
  const resetFilters = () => {
    setFilters({
      type: '',
      reason: '',
      status: '',
      startDate: null,
      endDate: null
    });
    setEventId('');
    fetchTransactions();
  };

  // Handle search
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.trim() === '') {
      setFilteredTransactions(transactions);
    } else {
      const filtered = transactions.filter(transaction =>
        transaction.description?.toLowerCase().includes(term) ||
        transaction.contact?.toLowerCase().includes(term) ||
        transaction.order_id?.toLowerCase().includes(term) ||
        transaction.id?.toString().includes(term)
      );
      setFilteredTransactions(filtered);
    }
  };

  // Handle sorting
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Apply sorting
  useEffect(() => {
    let sortableTransactions = [...filteredTransactions];
    if (sortConfig.key) {
      sortableTransactions.sort((a, b) => {
        // Handle date values
        if (sortConfig.key === 'created_at' || sortConfig.key === 'updated_at') {
          const aDate = new Date(a[sortConfig.key]);
          const bDate = new Date(b[sortConfig.key]);
          return sortConfig.direction === 'asc'
            ? aDate - bDate
            : bDate - aDate;
        }

        // Handle numeric values
        if (typeof a[sortConfig.key] === 'number' ||
          !isNaN(parseFloat(a[sortConfig.key]))) {
          const aValue = parseFloat(a[sortConfig.key]) || 0;
          const bValue = parseFloat(b[sortConfig.key]) || 0;
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }

        // Handle string values
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    setFilteredTransactions(sortableTransactions);
  }, [sortConfig, transactions, searchTerm]);

  // Get sort icon for column
  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <FontAwesomeIcon icon={faSort} className="ms-1 text-muted" />;
    }
    return sortConfig.direction === 'asc'
      ? <FontAwesomeIcon icon={faSortUp} className="ms-1" />
      : <FontAwesomeIcon icon={faSortDown} className="ms-1" />;
  };

  // Handle view transaction
  const handleViewTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setShowViewModal(true);
  };

  // Handle edit transaction
  const handleEditTransaction = (transaction) => {
    setSelectedTransaction({ ...transaction });
    setShowEditModal(true);
  };

  // Handle transaction field change
  const handleTransactionChange = (field, value) => {
    setSelectedTransaction(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Handle delete transaction
  const handleDeleteTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setShowDeleteModal(true);
  };

  // Confirm delete transaction
  const confirmDeleteTransaction = async () => {
    try {
      setLoading(true);
      await axiosInstance.delete(`${transactionsAPI}/${selectedTransaction.id}`);

      // Refresh the transactions list
      fetchTransactions(eventId);
      setShowDeleteModal(false);
      setSelectedTransaction(null);
    } catch (err) {
      handleAxiosError(err, setError, 'transaction', 'delete');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="transactions-manager">
      <h1>
        {eventId && getSelectedEventName()
          ? `${t('transactions.title')} - ${getSelectedEventName()}`
          : t('transactions.title')}
      </h1>

      <Card className="mb-4">
        <Card.Body>
          <Row className="align-items-end mb-3 mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>{t('transactions.filter_by_event')}</Form.Label>
                <Form.Select
                  value={eventId}
                  onChange={handleEventChange}
                  disabled={loading}
                >
                  <option value="">{t('transactions.all_events')}</option>
                  {events?.map(evt => (
                    <option key={evt.id} value={evt.id}>
                      {evt.name} - {new Date(evt.date).toLocaleDateString()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <Button
                variant="outline-primary"
                className="me-2"
                onClick={() => setShowFilters(!showFilters)}
              >
                <FontAwesomeIcon icon={faFilter} className="me-1" />
                {showFilters ? t('transactions.hide_filters') : t('transactions.show_filters')}
              </Button>
              <Button
                variant="outline-secondary"
                onClick={resetFilters}
                disabled={loading}
              >
                {t('reset')}
              </Button>
              {loading && (
                <span className="ms-3">
                  <FontAwesomeIcon icon={faSync} spin className="me-2" />
                  {t('loading')}
                </span>
              )}
            </Col>
          </Row>

          {showFilters && (
            <div className="advanced-filters mt-3 p-3 border rounded">
              <h5>{t('transactions.advanced_filters')}</h5>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('transactions.type')}</Form.Label>
                    <Form.Select
                      value={filters.type}
                      onChange={(e) => handleFilterChange('type', e.target.value)}
                    >
                      <option value="">{t('transactions.all_types')}</option>
                      <option value="paygold">Paygold</option>
                      <option value="bizum">Bizum</option>
                      <option value="transfer">Transfer</option>
                      <option value="paypal">PayPal</option>
                      <option value="cash">Cash</option>
                      <option value="other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('transactions.reason')}</Form.Label>
                    <Form.Select
                      value={filters.reason}
                      onChange={(e) => handleFilterChange('reason', e.target.value)}
                    >
                      <option value="">{t('transactions.all_reasons')}</option>
                      <option value="showcase">Showcase</option>
                      <option value="entrada">Entrada</option>
                      <option value="invitado">Invitado</option>
                      <option value="otro">Otro</option>
                      <option value="showcase-socio">Showcase Socio</option>
                      <option value="entrada-junior">Entrada Junior</option>
                      <option value="entrada-puerta">Entrada Puerta</option>
                      <option value="showcase-junior">Showcase Junior</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('transactions.status')}</Form.Label>
                    <Form.Select
                      value={filters.status}
                      onChange={(e) => handleFilterChange('status', e.target.value)}
                    >
                      <option value="">{t('transactions.all_statuses')}</option>
                      <option value="PENDING">{t('transactions.statuses.pending')}</option>
                      <option value="COMPLETED">{t('transactions.statuses.completed')}</option>
                      <option value="FAILED">{t('transactions.statuses.failed')}</option>
                      <option value="CANCELLED">{t('transactions.statuses.cancelled')}</option>
                      <option value="RETURN">{t('transactions.statuses.return')}</option>
                      <option value="BIZUM">{t('transactions.statuses.bizum')}</option>
                      <option value="TRANSFER">{t('transactions.statuses.transfer')}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('transactions.date_range')}</Form.Label>
                    <div className="d-flex">
                      <Form.Control
                        type="datetime-local"
                        value={filters.startDate instanceof Date ?
                          filters.startDate.toISOString().slice(0, 16) :
                          ''}
                        onChange={(e) => handleFilterChange('startDate', new Date(e.target.value))}
                        className="me-2"
                        placeholder={t('transactions.start_date')}
                      />
                      <Form.Control
                        type="datetime-local"
                        value={filters.endDate instanceof Date ?
                          filters.endDate.toISOString().slice(0, 16) :
                          ''}
                        onChange={(e) => handleFilterChange('endDate', new Date(e.target.value))}
                        className="form-control"
                        placeholder={t('transactions.end_date')}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  onClick={applyFilters}
                  disabled={loading}
                >
                  {t('transactions.apply_filters')}
                </Button>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      {/* Display error message */}
      {error && <Alert variant="danger" dismissible={true}>{error}</Alert>}

      {/* Display summary */}
      {!loading && summary && (
        <Card className="mb-4">
          <Card.Body>
            <Row className="mt-4">
              <Col md={2}>
                <div className="text-center">
                  <h5>{t('transactions.completed_amount')}</h5>
                  <h2>
                    <Badge bg="success">€{summary.completed_amount || '0.00'}</Badge>
                  </h2>
                </div>
              </Col>
              <Col md={2}>
                <div className="text-center">
                  <h5>{t('transactions.pending_amount')}</h5>
                  <h2>
                    <Badge bg="warning">€{summary.pending_amount || '0.00'}</Badge>
                  </h2>
                </div>
              </Col>
              <Col md={2}>
                <div className="text-center">
                  <h5>{t('transactions.total_transactions')}</h5>
                  <h2>
                    <Badge bg="primary">{summary.total_count || 0}</Badge>
                  </h2>
                </div>
              </Col>
              <Col md={2}>
                <div className="text-center">
                  <h5>{t('transactions.completed_count')}</h5>
                  <h2>
                    <Badge bg="info">{summary.completed_count || 0}</Badge>
                  </h2>
                </div>
              </Col>
              <Col md={2}>
                <div className="text-center">
                  <h5>{t('transactions.failed_count')}</h5>
                  <h2>
                    <Badge bg="danger">{summary.failed_count || 0}</Badge>
                  </h2>
                </div>
              </Col>
              <Col md={2}>
                <div className="text-center">
                  <h5>{t('transactions.pending_count')}</h5>
                  <h2>
                    <Badge bg="warning">{summary.pending_count || 0}</Badge>
                  </h2>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      {/* Search bar */}
      {!loading && transactions.length > 0 && (
        <Card className="mb-2 mt-2">
          <Card.Body className="mb-2 mt-2">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                placeholder={t('transactions.search_placeholder')}
                value={searchTerm}
                onChange={handleSearch}
              />
            </InputGroup>
            <div className="mt-2 text-muted small">
              {t('transactions.showing')} {filteredTransactions.length} {t('transactions.of')} {transactions.length} {t('transactions.transactions')}
            </div>
          </Card.Body>
        </Card>
      )}

      {/* Display table of transactions */}
      {!loading && filteredTransactions.length === 0 ? (
        <Alert variant="info" dismissible={true}>{t('transactions.no_transactions_found')}</Alert>
      ) : (
        <Card>
          <Card.Body>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => requestSort('issued_at')} className="cursor-pointer">
                    {t('transactions.date')} {getSortIcon('issued_at')}
                  </th>
                  <th onClick={() => requestSort('description')} className="cursor-pointer">
                    {t('transactions.description')} {getSortIcon('description')}
                  </th>
                  <th onClick={() => requestSort('eventId')} className="cursor-pointer">
                    {t('transactions.event')} {getSortIcon('eventId')}
                  </th>
                  <th onClick={() => requestSort('type')} className="cursor-pointer">
                    {t('transactions.type')} {getSortIcon('type')}
                  </th>
                  <th onClick={() => requestSort('reason')} className="cursor-pointer">
                    {t('transactions.reason')} {getSortIcon('reason')}
                  </th>
                  <th onClick={() => requestSort('amount')} className="cursor-pointer">
                    {t('transactions.amount')} {getSortIcon('amount')}
                  </th>
                  <th onClick={() => requestSort('transaction_status')} className="cursor-pointer">
                    {t('transactions.status')} {getSortIcon('transaction_status')}
                  </th>
                  <th onClick={() => requestSort('contact')} className="cursor-pointer">
                    {t('transactions.contact')} {getSortIcon('contact')}
                  </th>
                  <th onClick={() => requestSort('order_id')} className="cursor-pointer">
                    {t('transactions.order_id')} {getSortIcon('order_id')}
                  </th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td className="text-muted small">{formatDate(transaction.issued_at)}</td>
                    <td className="text-muted small">{transaction.description}</td>
                    <td>
                      {transaction.eventId ? (
                        <Link to={`/dashboard/event_payments/${transaction.eventId}`} className="text-primary">
                          {transaction.event_name || transaction.eventId}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      <Badge bg="secondary">{transaction.type}</Badge>
                    </td>
                    <td>
                      <Badge bg="info">{transaction.reason}</Badge>
                    </td>
                    <td className="fw-bold">€{parseFloat(transaction.amount).toFixed(2)}</td>
                    <td>
                      {getTransactionStatusBadge(transaction.transaction_status)}
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {transaction.attendee_name ||transaction.member_alias || transaction?.member_name || ''} {transaction.member_lastname || transaction?.contact || '-' }
                          </Tooltip>
                        }
                      >
                        {transaction.memberId ? (
                          <Link to={`/dashboard/members/${transaction.memberId}/payments`} className="text-primary">
                            {transaction.member_name || transaction.member_alias || transaction.contact}
                          </Link>
                        ) : (
                          <Link to={`/dashboard/members/${transaction.contact}/payments`} className="text-primary">
                          <span>{transaction.attendee_name || transaction.member_alias || transaction.contact || '-'}</span>
                          </Link>
                        )}
                      </OverlayTrigger>
                    </td>
                    <td>
                      <small className="text-muted">{transaction.order_id}</small>
                    </td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-1"
                        onClick={() => handleViewTransaction(transaction)}
                        title={t('view')}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        className="me-1"
                        onClick={() => handleEditTransaction(transaction)}
                        title={t('edit')}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDeleteTransaction(transaction)}
                        title={t('delete')}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('transactions.delete_transaction')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('transactions.delete_confirmation',[`#${selectedTransaction?.id}`])}
          <div className="mt-3">
            <strong>{t('transactions.description')}:</strong> {selectedTransaction?.description}<br />
            <strong>{t('transactions.amount')}:</strong> €{selectedTransaction?.amount}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="danger" onClick={confirmDeleteTransaction} disabled={loading}>
            {loading ? <FontAwesomeIcon icon={faSync} spin className="me-1" /> : null}
            {t('transactions.delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Confirmation Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('transactions.edit_transaction')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('transactions.edit_confirmation')}
          <div className="mt-3">
            <strong>{t('transactions.id')}:</strong> {selectedTransaction?.id}<br />
            <strong>{t('transactions.description')}:</strong> {selectedTransaction?.description}<br />
            <strong>{t('transactions.amount')}:</strong> €{selectedTransaction?.amount}
          </div>
          <div className="mt-3">
            {t('transactions.edit_redirect_message')}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            {t('cancel')}
          </Button>
          <Link
            to={`/dashboard/edit_transaction/${selectedTransaction?.id}`}
            className="btn btn-primary"
          >
            {t('edit')}
          </Link>
        </Modal.Footer>
      </Modal>
      {/* Edit Transaction Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('transactions.edit_transaction')} #{selectedTransaction?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTransaction && (
            <TransactionEdit transaction={selectedTransaction} handleTransactionChange={handleTransactionChange} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={saveTransaction}
            disabled={loading}
          >
            {loading ? <FontAwesomeIcon icon={faSync} spin className="me-1" /> : null}
            {t('transactions.save')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* View Transaction Modal */}
      <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('transactions.transaction_details')} #{selectedTransaction?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransactionDetail transaction={selectedTransaction} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowViewModal(false)}>
            {t('close')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowViewModal(false);
              handleEditTransaction(selectedTransaction);
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="me-1" />
            {t('edit')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransactionsManager;