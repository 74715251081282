import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, ListGroup } from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';

const AddEventPaymentModal = ({ show, onHide, eventId, onSuccess }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        memberSearchText: '',
        memberId: '',
        meetupId: '',
        attendeeName: '',
        guestCount: 0
    });
    
    const [error, setError] = useState(null);
    const [memberSearchResults, setMemberSearchResults] = useState([]);
    const [showMemberResults, setShowMemberResults] = useState(false);
    
    // Reset form when modal opens/closes
    useEffect(() => {
        if (show) {
            setFormData({
                memberSearchText: '',
                memberId: '',
                meetupId: '',
                attendeeName: '',
                guestCount: 0
            });
            setError(null);
        }
    }, [show]);
    
    const handleMemberChange = async (e) => {
        const { value } = e.target;
        setFormData(prev => ({ ...prev, memberSearchText: value }));
        
        if (value.length >= 2) {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_MEMBERS_API}/search`, {
                    params: { query: value, limit: 5 }
                });
                setMemberSearchResults(response.data);
                setShowMemberResults(true);
            } catch (error) {
                console.error('Error searching members:', error);
                setShowMemberResults(false);
            }
        } else {
            setMemberSearchResults([]);
            setShowMemberResults(false);
        }
    };
    
    const handleMemberSelect = (member) => {
        setFormData(prev => ({
            ...prev,
            memberId: member.id,
            meetupId: member.meetupID || '',
            attendeeName: `${member.firstname} ${member.lastname}`,
            memberSearchText: `${member.firstname} ${member.lastname}`
        }));
        setShowMemberResults(false);
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handleSubmit = async () => {
        setError(null);
        
        // Validation
        if (!formData.memberId && !formData.meetupId && !formData.attendeeName) {
            setError(t('event_payments.error_attendee_required'));
            return;
        }
        
        // If no member ID or meetup ID, validate that attendee name is an email or phone number
        if (!formData.memberId && !formData.meetupId) {
            const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.attendeeName);
            const isPhone = /^[0-9+\s()-]{7,15}$/.test(formData.attendeeName.replace(/\s/g, ''));
            
            if (!isEmail && !isPhone) {
                setError(t('event_payments.error_attendee_contact_required'));
                return;
            }
        }
        
        try {
            const payload = {
                event_id: eventId,
                member_id: formData.memberId || null,
                meetup_id: formData.meetupId || null,
                attendee_name: formData.attendeeName,
                guest_count: parseInt(formData.guestCount) || 0
            };
            
            const response = await axiosInstance.post(`${process.env.REACT_APP_PAYMENTS_API}/event-payment`, payload);
            
            if (response.data) {
                onSuccess(response.data);
                onHide();
            }
        } catch (err) {
            console.error('Error creating payment:', err);
            setError(err.response?.data?.error || t('event_payments.error_creating_payment'));
        }
    };
    
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('event_payments.add_new_payment')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger" dismissible>{error}</Alert>}
                
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('event_payments.event')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={`ID: ${eventId}`}
                            disabled
                        />
                    </Form.Group>
                    
                    <Form.Group className="mb-3 position-relative">
                        <Form.Label>{t('event_payments.search_member')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="memberSearchText"
                            value={formData.memberSearchText}
                            onChange={handleMemberChange}
                            placeholder={t('event_payments.search_member_placeholder')}
                            autoComplete="off"
                        />
                        {showMemberResults && memberSearchResults.length > 0 && (
                            <ListGroup 
                                className="position-absolute w-100 z-1000" 
                                style={{ maxHeight: '200px', overflowY: 'auto' }}
                            >
                                {memberSearchResults.map(member => (
                                    <ListGroup.Item 
                                        key={member.id}
                                        action
                                        onClick={() => handleMemberSelect(member)}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <span>{member.firstname} {member.lastname}</span>
                                        <small className="text-muted">
                                            {member.alias ? `@${member.alias}` : ''}
                                        </small>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                    </Form.Group>
                    
                    <div className="row">
                        <Form.Group className="mb-3 col-md-6">
                            <Form.Label>{t('event_payments.member_id')}</Form.Label>
                            <Form.Control
                                type="text"
                                name="memberId"
                                value={formData.memberId}
                                onChange={handleInputChange}
                                placeholder={t('event_payments.member_id_placeholder')}
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3 col-md-6">
                            <Form.Label>{t('event_payments.meetup_id')}</Form.Label>
                            <Form.Control
                                type="text"
                                name="meetupId"
                                value={formData.meetupId}
                                onChange={handleInputChange}
                                placeholder={t('event_payments.meetup_id_placeholder')}
                            />
                        </Form.Group>
                    </div>
                    
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {t('event_payments.attendee_name')} 
                            {!formData.memberId && !formData.meetupId && <span className="text-danger">*</span>}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="attendeeName"
                            value={formData.attendeeName}
                            onChange={handleInputChange}
                            placeholder={t('event_payments.attendee_name_placeholder')}
                            required={!formData.memberId && !formData.meetupId}
                        />
                    </Form.Group>
                    
                    <Form.Group className="mb-3">
                        <Form.Label>{t('event_payments.guest_count')}</Form.Label>
                        <Form.Control
                            type="number"
                            name="guestCount"
                            value={formData.guestCount}
                            onChange={handleInputChange}
                            min="0"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t('common.cancel')}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {t('common.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddEventPaymentModal;