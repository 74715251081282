import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Container, Button } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle, FaSpinner, FaDownload, FaPrint } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PaymentResult = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // Get Redsys parameters
    const merchantParams = queryParams.get('Ds_MerchantParameters');
    const signature = queryParams.get('Ds_Signature');
    const signatureVersion = queryParams.get('Ds_SignatureVersion');
    const paymentsAPI = process.env.REACT_APP_PAYMENTS_API;

    useEffect(() => {
        const fetchOrderDetails = async (orderId) => {
            try {
                const response = await fetch(`${paymentsAPI}/order/${orderId}`);
                const data = await response.json();
                return data;
            } catch (error) {
                console.error('Error fetching order details:', error);
                return null;
            }
        };

        const decodePaymentDetails = async () => {
            if (merchantParams) {
                const decodedParams = JSON.parse(atob(merchantParams));
                setPaymentDetails(decodedParams);
                const isSuccess = decodedParams.Ds_Response === '0000';
                
                const formattedDate = decodeURIComponent(decodedParams.Ds_Date);
                const formattedHour = decodeURIComponent(decodedParams.Ds_Hour);
                
                // Fetch additional order details
                const [orderData] = await fetchOrderDetails(decodedParams.Ds_Order);
                setOrderDetails({
                    orderId: decodedParams.Ds_Order,
                    amount: (decodedParams.Ds_Amount / 100).toFixed(2),
                    date: `${formattedDate} ${formattedHour}`,
                    status: isSuccess ? 'COMPLETED' : 'FAILED',
                    authCode: decodedParams.Ds_AuthorisationCode,
                    cardNumber: decodedParams.Ds_Card_Number,
                    responseDescription: decodedParams.Ds_Response_Description,
                    description: orderData?.description || 'No description available'
                });
            }
            setLoading(false);
        };

        decodePaymentDetails();
    }, [merchantParams]);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
                <FaSpinner className="fa-spin" style={{ fontSize: '3rem' }} />
            </Container>
        );
    }

    const isSuccess = paymentDetails?.Ds_Response === '0000';

    const generatePDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        
        // Add logo
        const logoImg = new Image();
        logoImg.src = '/images/logo.png';
        doc.addImage(logoImg, 'PNG', 150, 15, 53, 12);
        
        // Add title
        doc.setFontSize(20);
        doc.text(t('payments.result.title'), 105, 40, { align: 'center' });
        
        // Add payment status
        doc.setFontSize(16);
        doc.setTextColor(isSuccess ? '#2e7d32' : '#d32f2f');
        doc.text(isSuccess ? t('payments.result.success') : t('payments.result.failed'), 105, 48, { align: 'center' });
        
        // Reset text color and font size
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        
        // Add transaction details manually
        if (orderDetails) {
            let yPos = 60;
            const lineHeight = 8;
            
            doc.text(`${t('payments.result.order_id')}: ${orderDetails.orderId}`, 20, yPos); yPos += lineHeight;
            doc.text(`${t('payments.result.amount')}: €${orderDetails.amount}`, 20, yPos); yPos += lineHeight;
            doc.text(`Date: ${orderDetails.date}`, 20, yPos); yPos += lineHeight;
            doc.text(`Description: ${orderDetails.description}`, 20, yPos); yPos += lineHeight;
            doc.text(`Status: ${orderDetails.status}`, 20, yPos); yPos += lineHeight;
            doc.text(`Authorization Code: ${orderDetails.authCode}`, 20, yPos); yPos += lineHeight;
            doc.text(`Card: ${orderDetails.cardNumber}`, 20, yPos); yPos += lineHeight;
            doc.text(`Response: ${orderDetails.responseDescription}`, 20, yPos);
        }

        return doc;
    };

    const handleDownload = () => {
        const doc = generatePDF();
        doc.save(`payment-receipt-${orderDetails.orderId}.pdf`);
    };

    const handlePrint = () => {
        const doc = generatePDF();
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
    };

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
            <Card style={{ maxWidth: '600px', width: '100%', padding: '2rem', textAlign: 'center' }}>
                <div className="text-center mb-4">
                    <img 
                        src="/images/logo.png" 
                        alt="PianoMeetups Logo" 
                        style={{ 
                            width: '200px',
                            marginBottom: '1rem'
                        }} 
                    />
                </div>

                <h2 className="mb-3">
                    {isSuccess ? (
                        <FaCheckCircle style={{ fontSize: '3rem', color: '#2e7d32', marginBottom: '16px' }} />
                    ) : (
                        <FaTimesCircle style={{ fontSize: '3rem', color: '#d32f2f', marginBottom: '16px' }} />
                    )}
                    {isSuccess ? t('payments.result.success') : t('payments.result.failed')}
                </h2>

                {orderDetails && (
                    <>
                        <div className="mb-4 d-flex justify-content-center gap-3">
                            <Button variant="primary" onClick={handleDownload}>
                                <FaDownload className="me-2" /> {t('payments.result.download_pdf')}
                            </Button>
                            <Button variant="secondary" onClick={handlePrint}>
                                <FaPrint className="me-2" /> {t('payments.result.print')}
                            </Button>
                        </div>

                        <div className="mt-4 text-start">
                            <h4 className="mb-3">{t('payments.result.transaction_details')}</h4>
                            <p>{t('payments.result.order_id')}: {orderDetails.orderId}</p>
                            <p>{t('payments.result.amount')}: €{orderDetails.amount}</p>
                            <p>{t('payments.result.date')}: {orderDetails.date}</p>
                            <p>{t('payments.result.description')}: {orderDetails.description}</p>
                            <p>{t('payments.result.status')}: {orderDetails.status}</p>
                            <p>{t('payments.result.auth_code')}: {orderDetails.authCode}</p>
                            <p>{t('payments.result.card')}: {orderDetails.cardNumber}</p>
                            <p>{t('payments.result.response')}: {orderDetails.responseDescription}</p>
                        </div>
                    </>
                )}

                {!isSuccess && (
                    <p className="text-danger mt-3">
                        {t('payments.result.error_message')}
                    </p>
                )}
            </Card>
        </Container>
    );
};

export default PaymentResult;