// src/hooks/useAuth.js
import { useSelector } from "react-redux";

export function useUser() {
  return useSelector((state) => state.auth.user);
}

export function useTokens() {
  return useSelector((state) => state.tokens);
}

export function useLoading() {
  return useSelector((state) => state.loading);
}

export function useAuthenticated() {
  return useSelector((state) => state.auth.authenticated);
}
