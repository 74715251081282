import React, { memo, useEffect } from "react";
import './assets/css/main.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import './assets/vendor/bootstrap-icons/bootstrap-icons.min.css';

// Redux Selector / Action
import { Link, Outlet } from "react-router-dom";


//Components
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import { SubheaderProvider } from "./components/SubheaderContext.js";




const Default = memo(({  onLoginRegister, getUser, ...props }) => {

  console.log(process.env.REACT_APP_APP_NAME);
    
    useEffect(() => {
       
            
    
        /**
         * Scroll top button
         */
        let scrollTop = document.querySelector('.scroll-top');
    
        const toggleScrollTop = () => {
          if (scrollTop) {
            window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
          }
        }
    
        scrollTop.addEventListener('click', (e) => {
          e.preventDefault();
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        });
    
        window.addEventListener('load', toggleScrollTop);
        document.addEventListener('scroll', toggleScrollTop);
    
        /**
         * Correct scrolling position upon page load for URLs containing hash links.
         */
        window.addEventListener('load', function (e) {
          if (window.location.hash) {
            if (document.querySelector(window.location.hash)) {
              setTimeout(() => {
                let section = document.querySelector(window.location.hash);
                let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
                window.scrollTo({
                  top: section.offsetTop - parseInt(scrollMarginTop),
                  behavior: 'smooth'
                });
              }, 100);
            }
          }
        });
    
       
    
      }, []);
    AOS.init({
        duration: 600,
        easing: 'ease-in-out',
        once: true,
        mirror: false
      });



    return (

      <SubheaderProvider>
        <Header  onLoginRegister={onLoginRegister} getUser={getUser}
          showSiteName={false} showLogo={true} showTopBar={false}  />
        <main className="main">

          <Outlet />
        </main>

        <Footer />

        {/* <!--  Scroll Top-->*/}
        <Link href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>


        {/* <script src="assets/vendor/imagesloaded/imagesloaded.pkgd.min.js"></script> */}
      </SubheaderProvider>
  );
});

Default.displayName = "Default";
export default Default;
