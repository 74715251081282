import i18next from 'i18next';

/**
 * Standardized error handler for Axios requests with i18n support
 * @param {Error} err - The error object from Axios
 * @param {Function} setError - State setter function for error message
 * @param {string} resourceType - Type of resource being accessed (e.g., 'event', 'transaction')
 * @param {string} actionType - Type of action being performed (e.g., 'load', 'update', 'delete')
 */
export const handleAxiosError = (err, setError, resourceType = 'resource', actionType = 'load') => {
  const t = i18next.t;
  
  if (!err.response) {
    // Network error (no connectivity)
    setError(t('errors.network'));
  } else if (err.response.status === 404) {
    // 404 error
    setError(t('errors.not_found', { resource: t(`resources.${resourceType}`) }));
  } else if (err.response.status === 403) {
    // Forbidden error
    setError(t('errors.forbidden', { 
      action: t(`actions.${actionType}`), 
      resource: t(`resources.${resourceType}`) 
    }));
  } else if (err.response.status === 401) {
    // Unauthorized error
    setError(t('errors.unauthorized', { 
      action: t(`actions.${actionType}`), 
      resource: t(`resources.${resourceType}`) 
    }));
  } else {
    // Other API errors
    setError(t('errors.generic', { 
      action: t(`actions.${actionType}`), 
      resource: t(`resources.${resourceType}`),
      message: err.response?.data?.error || err.message
    }));
  }
  
  console.error(`Error ${actionType}ing ${resourceType}:`, err);
};